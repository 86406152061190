import { Avatar, Col, Row, Skeleton } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

type IViewArchivedServiceDepartmentsModalProps = {
  isOpen: any;
  onClose: any;
  doFetchArchivedServiceDepartments: typeof dispatch.servicesStore.doFetchArchivedServiceDepartments;
  archivedServiceDepartmentList: typeof state.servicesStore.archivedServiceDepartmentList;
};

type IViewArchivedServiceDepartmentsModalState = {
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
};

class ViewArchivedServiceDepartmentsModal extends Component<
  IViewArchivedServiceDepartmentsModalProps,
  IViewArchivedServiceDepartmentsModalState
> {
  state = {
    isLoading: false,
    isLoadingInfiniteScrolling: false,
    page: 1,
    pageSize: 10,
    pageTimestamp: new Date(),
  };

  private _fetchMoreServiceDepartments = () => {
    const { doFetchArchivedServiceDepartments } = this.props;
    this.setState({ isLoadingInfiniteScrolling: true, page: this.state.page + 1 }, async () => {
      await doFetchArchivedServiceDepartments({
        isArchived: true,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
      this.setState({ isLoadingInfiniteScrolling: false });
    });
  };

  componentDidUpdate = async (
    prevProps: Readonly<IViewArchivedServiceDepartmentsModalProps>,
    prevState: Readonly<IViewArchivedServiceDepartmentsModalState>,
    snapshot?: any,
  ) => {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isLoading: true });
      await this.props.doFetchArchivedServiceDepartments({
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { isOpen, archivedServiceDepartmentList } = this.props;

    return (
      <ActionModal title='Archived service departments' isOpen={isOpen} onClose={this.props.onClose} width='x-large'>
        <div className='mv-medium anim-slide-left'>
          <div>
            <Paragraph>View all service department that your company archived.</Paragraph>
          </div>

          <div className='bordered-bottom pb-medium'>
            <Row className='mt-small'>
              <Col span={10}>
                <b>Service department name</b>
              </Col>
              <Col span={4}>
                <b>Archive date</b>
              </Col>
              <Col span={10}>
                <b>Archived by</b>
              </Col>
            </Row>
          </div>
          <div
            id='overflow-archived'
            style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '400px' }}
            className='mb-large'
          >
            {this.state.isLoading ? (
              <SpinningLoader size={100} message='Fetching archived service departments...' />
            ) : archivedServiceDepartmentList && archivedServiceDepartmentList.length > 0 ? (
              <InfiniteScrollLoading
                hasMore={archivedServiceDepartmentList.length >= this.state.page * this.state.pageSize}
                loadingElementId='overflow-archived'
                loadMore={this._fetchMoreServiceDepartments}
                loaderColSpan={7}
                loadingOffSet={60}
              >
                {_.map(archivedServiceDepartmentList, (department, key) => {
                  return (
                    <Row key={key} className='bordered-top bordered-bottom pv-small' type='flex' align='middle'>
                      <Col
                        span={10}
                        title={department.serviceDepartmentName}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {department.serviceDepartmentName}
                      </Col>
                      <Col span={4}>{moment(department.archivedOn).format('DD/MM/YYYY')}</Col>
                      <Col span={10}>
                        <Avatar shape='square' src={department.attachmentUrl} className='mr-small' />
                        {department.firstName} {department.lastName}
                      </Col>
                    </Row>
                  );
                })}
                {this.state.isLoadingInfiniteScrolling && (
                  <Skeleton paragraph={{ rows: 1, width: '50%' }} active={true} className='anim-slide-left' />
                )}
              </InfiniteScrollLoading>
            ) : (
              <Row className='bordered-top bordered-bottom pv-small'>
                <Col span={24}>No archived service departments.</Col>
              </Row>
            )}
          </div>

          <ActionModalFooter>
            <PrimaryButton size='large' onClick={this.props.onClose}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  archivedServiceDepartmentList: state.servicesStore.archivedServiceDepartmentList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchArchivedServiceDepartments: dispatch.servicesStore.doFetchArchivedServiceDepartments,
});

export default connect(mapState, mapDispatch)(ViewArchivedServiceDepartmentsModal);
