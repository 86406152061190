import { Avatar, Checkbox, Col, Icon, Input, Skeleton, Spin, notification } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text, Title } from 'common-components/typography';
import { IBillingAccessMember } from 'interfaces/subscription-interfaces';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { ADD_BILLING_ACCESS_FORM_ELEMENT } from 'views/account-management/subscription-management/utils/constants';
import { Link } from 'react-router-dom';

interface TeamMemberRowProps {
  selected: boolean;
  teamMember: IBillingAccessMember;
  onSelect(userId: string): void;
}

const TeamMemberRow = React.memo((props: TeamMemberRowProps) => {
  const { selected, teamMember, onSelect } = props;

  return (
    <div className="flex align-center mv-small">
      <Checkbox checked={selected} onClick={(e) => onSelect(teamMember.userId)} />
      <Avatar src={teamMember.avatarUrl} shape="circle" className="mh-medium" style={{ width: 40, height: 40 }} />
      <div className="flex flex-column">
        <Text lineHeight={100} className="mb-x-small">{`${teamMember.firstName} ${teamMember.lastName}`}</Text>
        <Text lineHeight={100} size="x-small" color="secondary">
          {teamMember.email}
        </Text>
      </div>
    </div>
  );
});

TeamMemberRow.displayName = 'TeamMemberRow';

interface Props extends FormComponentProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

const AddBillingAccessModal = (props: Props) => {
  const { isOpen, form, onClose, onSuccess } = props;

  const dispatch = useDispatch<IRootDispatch>();
  const { noAccessBillingContactTeamMembers } = useSelector((state: IRootState) => state.subscriptionStore);

  const [filterState, setFilterState] = useState<{ page: number; pageSize: number; search: string }>({
    page: 1,
    pageSize: 10,
    search: '',
  });
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);

  const { getFieldDecorator, getFieldError, getFieldValue, setFieldsValue } = form;
  const selectedIds: string[] = getFieldValue(ADD_BILLING_ACCESS_FORM_ELEMENT.USER_IDS) || [];
  const isInvalid = !!getFieldError(ADD_BILLING_ACCESS_FORM_ELEMENT.USER_IDS);

  const _handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setIsAddingMember(true);
        const response = await dispatch.subscriptionStore.doAddBillingAccess(
          values[ADD_BILLING_ACCESS_FORM_ELEMENT.USER_IDS],
        );
        if (response) {
          onSuccess();
          onClose();
        } else {
          notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
        }
        setIsAddingMember(false);
      }
    });
  };

  const _loadMoreMembers = () => {
    setFilterState((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const _searchText = (txt: string) => {
    setFieldsValue({ [ADD_BILLING_ACCESS_FORM_ELEMENT.USER_IDS]: [] });
    setFilterState((prev) => ({ ...prev, page: 1, search: txt }));
  };

  const _debounceSearch = _.debounce(_searchText, 500);

  const _handleSearchMember = (e: React.ChangeEvent<HTMLInputElement>) => {
    _debounceSearch(e.target.value);
  };

  const _handleSelectMember = (userId: string) => {
    const newSelectedIds = selectedIds.slice();
    const index = newSelectedIds.findIndex((id) => id === userId);
    if (index > -1) {
      newSelectedIds.splice(index, 1);
    } else {
      newSelectedIds.push(userId);
    }
    setFieldsValue({ [ADD_BILLING_ACCESS_FORM_ELEMENT.USER_IDS]: newSelectedIds });
  };

  const _fetchTeamMembers = useCallback(async () => {
    setIsLoadingMembers(true);
    try {
      await dispatch.subscriptionStore.doFetchNoAccessBillingContactTeamMember(filterState);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
    }
    setIsLoadingMembers(false);
  }, [filterState, dispatch]);

  useEffect(() => {
    _fetchTeamMembers();
  }, [_fetchTeamMembers]);

  return (
    <ActionModal
      width="medium"
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Title level={4} className="pl-small mv-none">
          Grant billing access
        </Title>
      }
      className="pl-x-large pr-12 pt-small pb-12"
    >
      <Form onSubmit={_handleSubmitForm} className="pl-small pr-12">
        <Paragraph>Grant a team member access to manage your GoodHuman for business subscription.</Paragraph>
        <Form.Item>
          <Input
            placeholder="Search for an existing team member"
            prefix={<Icon type="search" className="text-color-tertiary" style={{ fontSize: 17 }} />}
            onChange={_handleSearchMember}
          />
          <Spin spinning={isLoadingMembers}>
            <div
              id="overflow-list"
              className={`rounded-big bordered pv-small ph-medium mb-small overflow-auto ${
                isInvalid ? 'border-red-dark' : ''
              }`}
              style={{ maxHeight: 226 }}
            >
              {getFieldDecorator(ADD_BILLING_ACCESS_FORM_ELEMENT.USER_IDS, {
                rules: [{ required: true, message: 'Please select a team member to continue' }],
              })(
                <InfiniteScrollLoading
                  loadingElementId="overflow-list"
                  hasMore={noAccessBillingContactTeamMembers.length >= filterState.page * filterState.pageSize}
                  loadMore={_loadMoreMembers}
                  customLoader={
                    <Col>
                      <Skeleton title={false} active={true} className="anim-slide-left" />
                    </Col>
                  }
                >
                  {noAccessBillingContactTeamMembers.map((member) => (
                    <TeamMemberRow
                      key={member.userId}
                      teamMember={member}
                      selected={selectedIds.includes(member.userId)}
                      onSelect={_handleSelectMember}
                    />
                  ))}
                  {!isLoadingMembers && !noAccessBillingContactTeamMembers.length && <div>No Team Members Found</div>}
                </InfiniteScrollLoading>,
              )}
            </div>
          </Spin>
        </Form.Item>
        <Link to="/account/team-management">
          <HyperlinkButton className="block">Add a new team member</HyperlinkButton>
        </Link>
      </Form>
      <ActionModalFooter>
        <GhostButton size="large" onClick={onClose}>
          Cancel
        </GhostButton>
        <PrimaryButton loading={isAddingMember} className="ml-medium mr-12" size="large" onClick={_handleSubmitForm}>
          Add
        </PrimaryButton>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default Form.create<Props>()(AddBillingAccessModal);
