import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SilServiceHeader } from './components/sil-service-header/sil-service-header';
import CenteredLayout from 'layouts/CenteredLayout';
import { SilServiceBreadcrumbs } from './components/sil-service-breadcrumbs/sil-service-breadcrumbs';
import { SilServiceTabs } from './components/sil-service-tabs/sil-service-tabs';
import { SilServiceProvider } from './components/sil-service-provider/sil-service-provider';

export type SilServiceViewProps = RouteComponentProps<{ serviceId: string }>;

export const SilServiceView = (props: SilServiceViewProps) => {
  const { match } = props;
  const { serviceId } = match.params;

  return (
    <CenteredLayout backgroundColor='white' maxWidth={1536} className='pt-8 pb-8 px-8'>
      <SilServiceProvider serviceId={serviceId}>
        <>
          <div className='flex pb-4'>
            <SilServiceBreadcrumbs />
          </div>
          <SilServiceHeader />
          <div className='py-4 '>
            <SilServiceTabs />
          </div>
        </>
      </SilServiceProvider>
    </CenteredLayout>
  );
};
