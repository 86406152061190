/* eslint-disable */
import React, { Component } from 'react';
import _ from 'lodash';
import ServiceListingsPanel from './listing/ServiceListingsPanel';
import CreateNewServiceModal from 'views/services/listing/components/CreateNewServiceModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { ServiceType } from 'utilities/enum-utils';
import { RouteComponentProps } from 'react-router-dom';

interface IServiceLandingViewProps extends RouteComponentProps {
  filteredServiceList: typeof state.servicesStore.filteredServices;
  setFilteredServices: typeof dispatch.servicesStore.setFilteredServices;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
}

interface IServiceLandingViewState {
  selectedServiceId: string;
  showCreateServiceModal: boolean;
}

class ServiceLandingView extends Component<IServiceLandingViewProps, IServiceLandingViewState> {
  state = {
    selectedServiceId: null,
    showCreateServiceModal: false,
  };

  private _openServiceModal = () => this.setState({ showCreateServiceModal: true });
  private _closeServiceModal = (serviceData = null) => {
    if (!_.isEmpty(serviceData)) {
      const newServiceListData = {
        ...serviceData,
        minBookingMinutes: null,
        gapMinutes: null,
        maxBookingMinutes: null,
        createdBy: null,
        likeCount: 0,
        dislikeCount: 0,
        createdOn: new Date(),
        UpdatedOn: new Date(),
      };
      let newFilteredServiceList = this.props.filteredServiceList;
      newFilteredServiceList.unshift(newServiceListData);
      this.props.setFilteredServices(newFilteredServiceList);
      this.setState({ showCreateServiceModal: false });
      this.props.history.push(
        serviceData.serviceType === ServiceType.GROUP
          ? `/group-service/details/${serviceData.serviceId}`
          : `/service/details/${serviceData.serviceId}`,
      );
    } else {
      this.setState({ showCreateServiceModal: false });
    }
  };

  setSelectedServiceId = (selectedServiceId, serviceType) => {
    if (selectedServiceId) {
      this.setState({ selectedServiceId });
    }

    let route = `/service/details/${selectedServiceId}`;
    switch (serviceType) {
      case ServiceType.GROUP:
        route = `/group-service/details/${selectedServiceId}`;
        break;
      case ServiceType.INDEPENDENT_LIVING:
        route = `/service/sil/${selectedServiceId}/schedule`;
        break;
    }

    this.props.history.push(route);
  };

  componentDidMount() {
    this.props.setSelectedSideNavMenuKeys(['/services']);
  }

  render() {
    return (
      <div style={{ height: 'calc(100vh - 234px)' }}>
        <div className='height-full'>
          <ServiceListingsPanel
            setSelectedServiceId={this.setSelectedServiceId}
            showServiceModal={this._openServiceModal}
          />
        </div>
        <CreateNewServiceModal
          isOpen={this.state.showCreateServiceModal}
          history={history}
          closeCreateServiceModal={this._closeServiceModal}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  filteredServiceList: state.servicesStore.filteredServices,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setFilteredServices: dispatch.servicesStore.setFilteredServices,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
});

export default connect(mapState, mapDispatch)(ServiceLandingView);
