import React, { PureComponent } from 'react';

interface CenteredLayoutProps {
  children?: any;
}

/**
 * @deprecated remove this -- no longer has any effect
 */
class CenteredLayout extends PureComponent<CenteredLayoutProps> {
  render() {
    const { children } = this.props;

    return <div>{children}</div>;
  }
}

export default CenteredLayout;
