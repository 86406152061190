import clsx from 'clsx';
import { Text } from 'common-components/typography';
import * as H from 'history';
import { isEqual, capitalize } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import SubscriptionStatusCard from './components/SubscriptionStatusCard';
import { Link } from 'react-router-dom';
import { Flag } from 'common-components/utils/launchdarkly/flag';

type AccountNavItemProps = {
  title: string;
  isComingSoon?: boolean;
  description: string;
  targetUrl: string;
};

function AccountNavItem({ title, isComingSoon, description, targetUrl }: AccountNavItemProps) {
  return (
    <Link to={targetUrl} disabled={isComingSoon} style={{ flexBasis: '250px' }} className='hover:no-underline'>
      <div className={clsx('mb-large', !isComingSoon && 'cursor-pointer card-item-hover', 'select-none')}>
        <div className='bordered rounded p-large' style={{ height: '220px' }}>
          <div className='mb-large'>
            <Text
              size='x2-large'
              color={isComingSoon ? 'black' : 'blue-action'}
              className={!isComingSoon && 'hover-text-underline'}
            >
              {capitalize(title)}
            </Text>
          </div>

          {isComingSoon && (
            <div className='mb-small'>
              <Text size='large'>COMING SOON</Text>
            </div>
          )}
          <Text size='large'>{description}</Text>
        </div>
      </div>
    </Link>
  );
}

type IAccountLandingViewProps = {
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  doGetSubscriptionStatus: typeof dispatch.subscriptionStore.doGetSubscriptionStatus;
  portalUser: typeof state.authStore.portalUser;
  subscriptionStatus: typeof state.subscriptionStore.subscriptionStatus;
  companyDataLite: typeof state.companyStore.companyDataLite;
  history: H.History;
};

type IAccountLandingViewState = {
  loadingSubscriptionStatus: boolean;
};

class AccountLandingView extends React.Component<IAccountLandingViewProps, IAccountLandingViewState> {
  state = {
    loadingSubscriptionStatus: false,
  };

  getSubscriptionStatus = async () => {
    const { companyDataLite, doGetSubscriptionStatus } = this.props;

    if (companyDataLite?.hasSubscription) {
      this.setState({ loadingSubscriptionStatus: true });
      await doGetSubscriptionStatus();
      this.setState({ loadingSubscriptionStatus: false });
    }
  };

  componentDidMount() {
    this.getSubscriptionStatus();
    this.props.setSelectedSideNavMenuKeys(['/account/landing']);
  }

  componentDidUpdate(prevProps: IAccountLandingViewProps): void {
    const { companyDataLite } = this.props;

    if (companyDataLite && !isEqual(prevProps.companyDataLite, companyDataLite)) {
      this.getSubscriptionStatus();
    }
  }

  render() {
    const { portalUser, history, subscriptionStatus, companyDataLite } = this.props;
    const { loadingSubscriptionStatus } = this.state;

    const hasSubscriptionPermission = PermissionUtils.validatePermission(
      'ViewSubscriptionBilling',
      portalUser?.permissions?.permissionRoles,
    );

    // Temporary disable new Branding for service provider that have custom SA template.
    // TODO: Update the custom template to have them use the new branding.
    const excludedServiceProviderForBranding = [
      '1c978487-1c1d-47d6-874a-50523caab398', // Possability
      'dbf783d4-a01b-473c-96f7-2e93b7de2d1d', // TS Collective
    ];

    return (
      <div>
        <SubscriptionStatusCard
          subscriptionStatusInfo={subscriptionStatus}
          className='mb-x-large'
          currentScreen='Account'
          hasPermission={hasSubscriptionPermission}
          loading={loadingSubscriptionStatus || !companyDataLite}
        />
        {/* Items container */}
        <div className='flex-row flex-wrap' style={{ gap: '24px', justifyContent: 'center' }}>
          {PermissionUtils.validatePermission('ViewCompanyDetail', portalUser.permissions.permissionRoles) && (
            <AccountNavItem
              title='Company Details'
              description='Essential company details including the Primary Owner and trading information.'
              targetUrl='/company'
            />
          )}

          {PermissionUtils.validatePermission('ViewBrandingPage', portalUser.permissions.permissionRoles) && (
            <AccountNavItem
              title='Branding'
              description='Branding assets used in the portal and in generated communications.'
              isComingSoon={Boolean(
                excludedServiceProviderForBranding.find(
                  (serviceProviderId: string) => serviceProviderId === portalUser.serviceProviderId,
                ),
              )}
              targetUrl='/account/branding'
            />
          )}

          <AccountNavItem
            title='Funding and Payment'
            description='Funding sources and customer payment options.'
            targetUrl='/account/funding-and-payment'
          />

          <AccountNavItem
            title='Feature configuration'
            description='GoodHuman functionality and options.'
            targetUrl='/account/features'
          />
          {PermissionUtils.validatePermission('ViewTeamManagement', portalUser.permissions.permissionRoles) && (
            <AccountNavItem
              title='Team Management'
              description='Add, deactivate and assign permissions to people in your business.'
              targetUrl='/account/team-management'
            />
          )}
          {PermissionUtils.validatePermission(
            'ViewServiceDepartmentListing',
            portalUser.permissions.permissionRoles,
          ) && (
            <AccountNavItem
              title='Service Departments'
              description='Create and configure your businesses service departments.'
              targetUrl='/account/service-department'
            />
          )}
          {PermissionUtils.validatePermission('ExportPlatformData', portalUser.permissions.permissionRoles) && (
            <AccountNavItem
              title='Export Data'
              description='Access your company data on your terms.'
              targetUrl='/account/export-data'
            />
          )}
          {PermissionUtils.validatePermission('ImportPlatformData', portalUser.permissions.permissionRoles) && (
            <Flag
              flag='selfServiceCsvImports'
              on={
                <AccountNavItem
                  title='Import Data'
                  description='Import your company data.'
                  targetUrl='/account/imports'
                />
              }
            />
          )}
          {PermissionUtils.validatePermission('ViewDebtorManagement', portalUser.permissions.permissionRoles) && (
            <AccountNavItem
              title='Debtor Management'
              description='Manage customer debtors'
              targetUrl='/account/debtor-management'
            />
          )}
          {PermissionUtils.validatePermission('AccessToFormBuilder', portalUser.permissions.permissionRoles) && (
            <AccountNavItem
              title='Forms'
              description="View and configure your company's forms to be deployed your workspace."
              targetUrl='/account/forms'
            />
          )}

          {PermissionUtils.validatePermission('ViewAddEditWorkflow', portalUser.permissions.permissionRoles) && (
            <AccountNavItem
              title='Workflow templates'
              description='Manage your businesses workflows.'
              targetUrl='/account/workflows'
            />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  subscriptionStatus: state.subscriptionStore.subscriptionStatus,
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  doGetSubscriptionStatus: dispatch.subscriptionStore.doGetSubscriptionStatus,
});

export default connect(mapState, mapDispatch)(AccountLandingView);
