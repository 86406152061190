import { Col, Icon } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { GridRow } from 'common-components/grids';
import { Text } from 'common-components/typography';
import * as H from 'history';
import { IBilling } from 'interfaces/subscription-interfaces';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import CommonUtils from 'utilities/common-utils';
import { InvoiceHistoryStatus } from 'utilities/enum-utils';

interface IInvoiceDetailItem {
  timeZone: string;
  invoiceDetail: IBilling;
  history?: H.History;
  previewInvoicePdf: () => void;
  onDownloadInvoice: () => void;
  openLinkPaymentIssue: () => void;
}

const InvoiceDetailItem = (props: IInvoiceDetailItem) => {
  const { invoiceDetail, timeZone, previewInvoicePdf, onDownloadInvoice, openLinkPaymentIssue } = props;

  const textOnBlur = _.includes([InvoiceHistoryStatus.REFUNDED, InvoiceHistoryStatus.WAIVED], invoiceDetail.status);

  const isPaymentIssueStatus = invoiceDetail.status === InvoiceHistoryStatus.PAYMENT_ISSUE;

  const _renderPaidStatusColumn = () => {
    switch (invoiceDetail.status) {
      case InvoiceHistoryStatus.WAIVED: {
        return (
          <Text size="regular" color="gray-dark">
            Waived
          </Text>
        );
      }
      case InvoiceHistoryStatus.REFUNDED: {
        return (
          <Text size="regular" color="gray-dark">
            Refunded
          </Text>
        );
      }
      case InvoiceHistoryStatus.PAID: {
        return (
          <Icon type="check-circle" className="text-color-green-light" theme="filled" style={{ fontSize: '20px' }} />
        );
      }
      case InvoiceHistoryStatus.PAYMENT_ISSUE: {
        if (!invoiceDetail.paymentLink) {
          return (
            <Text size="regular" style={{ color: '#c30000' }}>
              Payment Issue
            </Text>
          );
        }
        return (
          <HyperlinkButton
            className="bordered-bottom border-red-dark"
            style={{ maxWidth: 101 }}
            onClick={openLinkPaymentIssue}
            hoverUnderline={false}
          >
            <Text size="regular" style={{ color: '#c30000' }}>
              Payment Issue
            </Text>
          </HyperlinkButton>
        );
      }
    }
  };

  return (
    <GridRow containerClassName="pv-large text-align-center hover-bg-blue-lightest">
      <Col span={4}>
        <Text size="regular" color={textOnBlur ? 'gray-dark' : ''}>
          {moment.tz(invoiceDetail.issueDate, timeZone).format('D MMM YYYY')}
        </Text>
      </Col>
      <Col span={6}>
        <HyperlinkButton
          color="blue-action"
          hoverUnderline={false}
          className={textOnBlur ? 'dimmer' : ''}
          fontSize="regular"
          onClick={previewInvoicePdf}
        >
          {invoiceDetail?.number}
        </HyperlinkButton>
      </Col>
      <Col span={5} className="flex justify-center align-center">
        <div className={isPaymentIssueStatus ? 'bordered-bottom border-red-dark' : ''} style={{ maxWidth: '101px' }}>
          <Text
            size="regular"
            color={textOnBlur ? 'gray-dark' : ''}
            style={{ color: isPaymentIssueStatus ? '#c30000' : undefined }}
          >
            {CommonUtils.formatPrice(invoiceDetail.amount)}
          </Text>
        </div>
      </Col>
      <Col span={6} className="flex justify-center align-center">
        {_renderPaidStatusColumn()}
      </Col>
      <Col span={3}>
        <HyperlinkButton color="secondary" fontSize="x2-large" onClick={onDownloadInvoice}>
          <Icon type="download" className="mr-x-small" />
        </HyperlinkButton>
      </Col>
    </GridRow>
  );
};

export default InvoiceDetailItem;
