import React, { Component } from 'react';
import _ from 'lodash';
import { Col, Form, Input, notification, Row, Select } from 'antd';
import { FieldLabel, FieldValueText, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton, HyperlinkButton } from 'common-components/buttons';
import { Icon } from 'antd/es';
import { FormComponentProps } from 'antd/es/form';
import CommonUtils from 'utilities/common-utils';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import Map from 'common-components/map/Map';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FormCol from 'common-components/forms/FormCol';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ArchiveDebtorModal from './ArchiveDebtorModal';
import { ArchiveDebtorFrom } from 'utilities/enum-utils';
import UnarchiveDebtorModal from './UnarchiveDebtorModal';
import * as H from 'history';
import { Switch, Tooltip } from '@good/ui/core';
import { Debtor } from 'views/account-management/DebtorManagementView';
import { type NewRelicBrowser, WithNewRelicBrowser } from 'integrations/new-relic-browser';
import { validateEmailFormat } from 'utilities/validator-utils';

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

interface IDebtorGeneralPanelProps extends FormComponentProps {
  debtor: Debtor;
  history: H.History;
  doEditDebtorGeneralInfo: typeof dispatch.accountStore.doEditDebtorGeneralInfo;
  doArchiveDebtor: typeof dispatch.accountStore.doArchiveDebtor;
  setDebtors: typeof dispatch.accountStore.setDebtors;
  debtors: Debtor[];
  setDebtor: typeof dispatch.accountStore.setDebtor;
  newRelicBrowser: NewRelicBrowser;
}

type IDebtorGeneralPanelState = {
  lattitude: any;
  longitude: any;
  selectedAddressIndex: number;
  isEdit: boolean;
  selectedTransport: string;
  isopen: boolean;
  isSaving: boolean;
  address: string;
  selectedLattitude: any;
  selectedLongitude: any;
  selectedAddress: any;
  isOpenArchiveModal: boolean;
  isOpenUnarchiveModal: boolean;
  debtorName: string;
  debtorOrganisationName: string;
  debtorNumber: string;
  debtorContactNumber: string;
  debtorEmail: string;
  debtorAddress: string;
  emailError: boolean;
  debtorNumberError: boolean;
  inactive: boolean;
};

class DebtorGeneralPanel extends Component<IDebtorGeneralPanelProps, IDebtorGeneralPanelState> {
  state = {
    lattitude: 0,
    longitude: 0,
    selectedAddressIndex: -1,
    isEdit: false,
    selectedTransport: '',
    isopen: false,
    isSaving: false,
    address: '',
    selectedLattitude: 0,
    selectedLongitude: 0,
    selectedAddress: null,
    isOpenArchiveModal: false,
    isOpenUnarchiveModal: false,
    debtorName: '',
    debtorOrganisationName: '',
    debtorNumber: '',
    debtorContactNumber: '',
    debtorEmail: '',
    debtorAddress: '',
    emailError: false,
    debtorNumberError: false,
    inactive: false,
  };

  private _resetSubmissionErrors = () => {
    this.setState({ emailError: false, debtorNumberError: false });
  };

  private _handleChange = (address) => {
    this.setState({ address });
  };

  private _handleSelect = (address) => {
    this.setState({ address: address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          selectedAddress: CommonUtils.createAddressFromGoogleMap(results[0].address_components),
        });
      })
      .catch((error) => console.error('Error', error));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({ selectedLattitude: lat, selectedLongitude: lng }))
      .catch((error) => console.error('Error', error));
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';

    try {
      if (!_.isEmpty(value)) {
        const phoneNumber = parsePhoneNumberFromString(value, region);
        if (phoneNumber === undefined || !phoneNumber || !phoneNumber.isValid()) {
          throw new Error('Invalid Phone number');
        }
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };
  private _onSave = async () => {
    const { form, doEditDebtorGeneralInfo, debtor } = this.props;
    const { address, selectedAddress, selectedLattitude, selectedLongitude } = this.state;
    let isFormValid = true;
    this._resetSubmissionErrors();
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      let debtorAdress = {};
      if (address && selectedAddress) {
        debtorAdress = {
          ...selectedAddress,
          geoLat: selectedLattitude,
          geoLng: selectedLongitude,
          fullAddress: address,
        };
      }

      const payload = {
        debtorId: debtor.debtorId,
        debtorName: form.getFieldValue('debtorName'),
        organisationName: form.getFieldValue('organisationName'),
        email: form.getFieldValue('debtorEmail'),
        debtorNumber: form.getFieldValue('debtorNumber'),
        contactNumber: form.getFieldValue('debtorContactNumber'),
        contactNumberCountryCode: form.getFieldValue('debtorContactNumber') ? 'AU' : null,
        address: debtorAdress,
        inactive: this.state.inactive,
      };

      _.forOwn(payload, (value, key) => {
        if (
          value === null ||
          value === undefined ||
          (_.isObject(value) && _.isEmpty(value)) ||
          (_.isString(value) && _.isEmpty(value))
        ) {
          delete payload[key];
        }
      });

      try {
        await doEditDebtorGeneralInfo(payload);
        this.setState({
          isEdit: false,
          debtorName: form.getFieldValue('debtorName'),
          debtorOrganisationName: form.getFieldValue('organisationName'),
          debtorEmail: form.getFieldValue('debtorEmail'),
          debtorNumber: form.getFieldValue('debtorNumber'),
          debtorContactNumber: form.getFieldValue('debtorContactNumber'),
          debtorAddress: address,
          lattitude: selectedLattitude,
          longitude: selectedLongitude,
          inactive: this.state.inactive,
        });

        this.props.setDebtors(
          this.props.debtors.map((debtor) => {
            if (debtor.debtorId === payload.debtorId) {
              return {
                ...debtor,
                debtorName: payload.debtorName,
                organisationName: payload.organisationName,
                debtorEmail: payload.email,
                debtorNumber: payload.debtorNumber,
                debtorContactNumber: payload.contactNumber,
                address: payload.address,
                inactive: payload.inactive,
              };
            }
            return debtor;
          }),
        );

        if (this.props.debtor.debtorId === payload.debtorId) {
          this.props.setDebtor({
            ...this.props.debtor,
            debtorName: payload.debtorName,
            organisationName: payload.organisationName,
            debtorEmail: payload.email,
            debtorNumber: payload.debtorNumber,
            debtorContactNumber: payload.contactNumber,
            address: payload.address,
            inactive: payload.inactive,
          });
        }
      } catch (e) {
        let capturedKnownError = false;

        if ('meta' in e && 'message' in e.meta) {
          if (e.meta.message.includes('The provided debtor email already exists')) {
            capturedKnownError = true;
            this.setState({ emailError: true });
          }

          if (e.meta.message.includes('The provided debtor number already exists')) {
            capturedKnownError = true;
            this.setState({ debtorNumberError: true });
          }
        }

        if (!capturedKnownError) {
          notification.error({ message: 'Oops, an error has occurred, please try again.' });
          this.props.newRelicBrowser?.noticeError(e);
        }
      }
    }
  };

  componentDidMount = async () => {
    const { debtor } = this.props;
    if (debtor) {
      this.setState({
        debtorName: debtor.debtorName,
        debtorOrganisationName: debtor.organisationName,
        debtorNumber: debtor.debtorNumber ? debtor.debtorNumber : '',
        debtorContactNumber: debtor.debtorContactNumber ? debtor.debtorContactNumber : '',
        debtorEmail: debtor.debtorEmail,
        debtorAddress: debtor.address && debtor.address.fullAddress ? debtor.address.fullAddress : '',
        lattitude: debtor.address && debtor.address.fullAddress ? debtor.address.geoLat : 0,
        longitude: debtor.address && debtor.address.fullAddress ? debtor.address.geoLng : 0,
        inactive: debtor.inactive,
      });
    }
  };

  render() {
    const { debtor, form, history } = this.props;
    const {
      isEdit,
      isSaving,
      address,
      isOpenArchiveModal,
      isOpenUnarchiveModal,
      debtorName,
      debtorOrganisationName,
      debtorNumber,
      debtorContactNumber,
      debtorEmail,
      debtorAddress,
      lattitude,
      longitude,
      emailError,
      debtorNumberError,
    } = this.state;
    const { getFieldDecorator } = form;
    if (!isEdit) {
      return (
        <>
          <UnarchiveDebtorModal
            debtor={debtor}
            unarchiveDebtorFrom={ArchiveDebtorFrom.DETAILS_PAGE}
            isOpen={isOpenUnarchiveModal}
            history={history}
            closeUnarchiveModal={() => this.setState({ isOpenUnarchiveModal: false })}
          />
          <div className='x2-large'>
            <Row type='flex' justify='space-between' align='bottom'>
              <Col>
                <Title level={2} className='m-none'>
                  General Information
                </Title>
                <Text type='secondary'>General information about this debtor</Text>
              </Col>
              {debtor && debtor.isArchived ? (
                <Col className='align-center'>
                  <div className='flex-row-reverse align-center'>
                    <PrimaryButton onClick={() => this.setState({ isOpenUnarchiveModal: true })}>
                      Unarchive debtor
                    </PrimaryButton>
                  </div>
                </Col>
              ) : (
                <Col className='align-center'>
                  <div className='flex-row-reverse align-center'>
                    <PrimaryButton icon='edit' onClick={() => this.setState({ isEdit: true })}>
                      Edit Information
                    </PrimaryButton>
                  </div>
                </Col>
              )}
            </Row>
            <div className='mt-x-large'>
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text='Name' />
                  <FieldValueText text={debtorName} />
                </Col>
                <Col span={12}>
                  <FieldLabel text='Debtor ID' />
                  <FieldValueText text={debtorNumber ? debtorNumber : 'Not set'} />
                </Col>
              </Row>
            </div>
            <div className='mt-x-large'>
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text='Contact Number' />
                  <FieldValueText text={debtorContactNumber ? debtorContactNumber : 'Not set'} />
                </Col>
                <Col span={12}>
                  <FieldLabel text='Email' />
                  <FieldValueText text={debtorEmail} />
                </Col>
              </Row>
            </div>
            <div className='mt-x-large'>
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text='Organisation Name' />
                  <FieldValueText text={debtorOrganisationName ? debtorOrganisationName : 'Not set'} />
                </Col>
              </Row>
            </div>
            <div className='mt-x-large mb-small'>
              <FieldLabel text='Address' />
              <FieldValueText text={debtorAddress ? debtorAddress : 'Not set'} />
            </div>
            {debtorAddress && (
              <div style={{ width: '700px' }}>
                <Map lattitude={lattitude} longitude={longitude} />
              </div>
            )}
          </div>
        </>
      );
    }
    return (
      <div>
        <ArchiveDebtorModal
          debtor={debtor}
          archiveDebtorFrom={ArchiveDebtorFrom.DETAILS_PAGE}
          isOpen={isOpenArchiveModal}
          closeArchiveModal={() => this.setState({ isOpenArchiveModal: false })}
          closeEditMode={() => this.setState({ isEdit: false })}
        />
        <Row>
          <Title level={2} className='m-none'>
            General Information
          </Title>
          <Text type='secondary'>General information about this debtor</Text>
        </Row>
        <div className='mt-large'>
          <Row>
            <FormCol label='Name' leftSpan={5} textAlign='left' />
            <Col span={19}>
              <Form.Item>
                {getFieldDecorator('debtorName', {
                  initialValue: debtorName,
                  rules: [{ required: true, message: 'Please enter a name' }],
                })(<Input size='large' placeholder='Enter name' style={{ maxWidth: '300px' }} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <FormCol label='Organisation' leftSpan={5} textAlign='left' />
            <Col span={19}>
              <Form.Item>
                {getFieldDecorator('organisationName', {
                  initialValue: debtorOrganisationName,
                  rules: [{ required: false }],
                })(<Input size='large' placeholder='Enter organisation name' style={{ maxWidth: '300px' }} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <FormCol label='Debtor ID' leftSpan={5} textAlign='left' />
            <Col span={19} className='mb-6'>
              <Form.Item className='m-0'>
                {getFieldDecorator('debtorNumber', {
                  initialValue: debtorNumber,
                  rules: [{ required: false, message: 'Please enter a debtor ID' }],
                })(
                  <Input
                    size='large'
                    placeholder='Enter ID'
                    style={{ maxWidth: '300px' }}
                    className={debtorNumberError ? 'border-red' : undefined}
                    onChange={this._resetSubmissionErrors}
                  />,
                )}
                {debtorNumberError && (
                  <div>
                    <Text color='red'>This debtor ID already exists. Enter a unique ID.</Text>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <FormCol label='Email' leftSpan={5} textAlign='left' />
            <Col span={19} className='mb-6'>
              <Form.Item className='m-0'>
                {getFieldDecorator('debtorEmail', {
                  initialValue: debtorEmail,
                  rules: [
                    { required: true, message: 'Please enter an email address' },
                    {
                      validator: (_rule, email: string) => validateEmailFormat(email),
                      message: 'Please enter a valid email address',
                    },
                  ],
                })(
                  <Input
                    size='large'
                    placeholder='Enter email'
                    style={{ maxWidth: '300px' }}
                    className={emailError ? 'border-red' : undefined}
                  />,
                )}
                {emailError && (
                  <div>
                    <Text color='red'>This debtor already exists. Please enter a different email address.</Text>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <FormCol label='Contact Number' leftSpan={5} textAlign='left' />
            <Col span={19}>
              <div className='flex-row mt-x2-small'>
                <Select disabled size='large' className='mr-small' style={{ width: '110px' }} defaultValue='AU'>
                  <Select.Option value='AU'>+61 (AU)</Select.Option>
                </Select>
                <Form.Item>
                  {getFieldDecorator('debtorContactNumber', {
                    initialValue: debtorContactNumber,
                    rules: [{ validator: this._validateMobileNumber }],
                  })(<Input size='large' placeholder='Enter number' style={{ maxWidth: '300px' }} />)}
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <FormCol label='Address' leftSpan={5} textAlign='left' />
            <Col span={19}>
              <PlacesAutocomplete
                value={address}
                onSelect={this._handleSelect}
                onChange={this._handleChange}
                shouldFetchSuggestions={this.state.address.length > 2}
                searchOptions={searchAutocompleteOptions}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <Input
                      value={address}
                      size='large'
                      style={{ maxWidth: '500px' }}
                      {...getInputProps({
                        placeholder: 'Search for an address ...',
                        className: 'location-search-input',
                      })}
                    />
                    <div className='autocomplete-dropdown-container'>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                        const style = suggestion.active
                          ? {
                              backgroundColor: '#fafafa',
                              cursor: 'pointer',
                              borderTop: '1px ridge grey',
                              borderLeft: '1px ridge grey',
                              borderRight: '1px ridge grey',
                              padding: '10px',
                            }
                          : {
                              backgroundColor: '#ffffff',
                              cursor: 'pointer',
                              borderTop: '1px ridge grey',
                              borderLeft: '1px ridge grey',
                              borderRight: '1px ridge grey',
                              padding: '10px',
                            };
                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>
                              <Icon type='environment' /> {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
          </Row>

          <Row type='flex' justify='space-between' align='middle' className='mt-large'>
            <Col>
              <HyperlinkButton color='red' onClick={() => this.setState({ isOpenArchiveModal: true })}>
                Archive debtor
              </HyperlinkButton>
            </Col>
            <Col className='flex'>
              <Tooltip
                label='When inactive this plan manager will no longer be avaliable to add to customers'
                position='bottom'
                refProp='rootRef'
              >
                <Switch
                  checked={!this.state.inactive}
                  label={this.state.inactive ? 'Inactive' : 'Active'}
                  onChange={() => this.setState({ inactive: !this.state.inactive })}
                />
              </Tooltip>
            </Col>
            <Col className='flex'>
              <GhostButton onClick={() => this.setState({ isEdit: false })}>Discard Changes</GhostButton>

              <PrimaryButton disabled={isSaving} onClick={this._onSave} icon='save'>
                Save
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
  debtors: state.accountStore.debtors,
  debtor: state.accountStore.debtor,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditDebtorGeneralInfo: dispatch.accountStore.doEditDebtorGeneralInfo,
  doArchiveDebtor: dispatch.accountStore.doArchiveDebtor,
  setDebtors: dispatch.accountStore.setDebtors,
  setDebtor: dispatch.accountStore.setDebtor,
});

export default WithNewRelicBrowser(
  connect(mapState, mapDispatch)(Form.create<IDebtorGeneralPanelProps>()(DebtorGeneralPanel)),
);
