import React, { Component } from 'react';
import { Form, Row, Col, Select, Icon, Checkbox, Empty, Radio } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Text, Paragraph, SubTitle } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import ndisHelper from 'variables/data/ndis-helper';
import _ from 'lodash';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import Search from 'antd/es/input/Search';
import { FilterItemMenu } from 'common-components/filter';
import { FilterType, NDISLineItemUnit } from 'utilities/enum-utils';
import { Popover } from '@blueprintjs/core';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import NumberInput from 'common-components/inputs/NumberInput';
import Utils from 'utilities/Utils';
import AddNDISGroupLineItemsModal from 'views/services/listing/components/AddNDISGroupLineItemsModal';
import { IRootState, state } from 'src/stores/rematch/root-store';

interface IAddNDISLineItemsModalProps extends FormComponentProps {
  closeLineItemModal: () => void;
  isOpen: boolean;
  saveLineItem: (lineItems: any) => void;
  serviceBillingItems: any;
  selectedService: typeof state.servicesStore.selectedService;
  showPrice?: boolean;
}

interface IAddNDISLineItemsModalState {
  isFilterOpen: boolean;
  isLoadingList: boolean;
  isActionModalOpen: boolean;
  isWarningModalOpen: boolean;
  warningModalType: string;
  transferLineItem: any;
  lineItems: Array<any>;
  selectedSupportCategorySupportItems: Array<any>;
  isProceedOpen: boolean;
  SupportCategory: any;
  billingLineItemList: Array<any>;
  page: number;
  pageSize: number;
  categoriesFilter: any;
  searchFilter: string;
  isGroupModalOpen: boolean;
  selectedLineItem: any;
  groupLineItems: any;
}

function LineItemEmptyState({ type }) {
  return (
    <div className='flex-1 bg-white mt-x2-large align-center flex-column'>
      <div className=''>
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className='mv-none' />
      </div>
      <Text size='x2-large' color='secondary' weight='bold'>
        No line Item {type === 'search' ? 'found' : 'selected'}.
      </Text>
      <Text color='secondary'>
        {type === 'search' ? 'Try to change the filters.' : 'Select one or more from the left.'}
      </Text>
    </div>
  );
}

class AddNDISLineItemsModal extends Component<IAddNDISLineItemsModalProps, IAddNDISLineItemsModalState> {
  state = {
    isFilterOpen: false,
    isLoadingList: true,
    isActionModalOpen: false,
    lineItems: [],
    selectedSupportCategorySupportItems: [],
    isProceedOpen: false,
    SupportCategory: 1,
    billingLineItemList: [],
    page: 1,
    pageSize: 10,
    categoriesFilter: [],
    searchFilter: null,
    isGroupModalOpen: false,
    selectedLineItem: null,
    groupLineItems: null,
    isWarningModalOpen: false,
    warningModalType: null,
    transferLineItem: null,
  };

  private _saveLineItems = async () => {
    const { form } = this.props;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      this.setState({
        searchFilter: null,
      });
      this.props.saveLineItem(this.state.selectedSupportCategorySupportItems);
      this._closeLineItemCheckModal();
    }
  };

  private _closeLineItemCheckModal = () => {
    const { lineItems } = this.state;

    if (lineItems.length > 0) {
      this.setState({ isProceedOpen: true, transferLineItem: null, searchFilter: null });
    } else {
      this._closeLineItemModal();
    }
  };

  private _closeWarningModal = () => {
    this.setState({ isWarningModalOpen: false, transferLineItem: null, searchFilter: null });
  };

  private _closeLineItemModal = () => {
    this.setState({
      isFilterOpen: false,
      isLoadingList: true,
      isActionModalOpen: false,
      lineItems: [],
      selectedSupportCategorySupportItems: [],
      isProceedOpen: false,
      SupportCategory: 1,
      billingLineItemList: [],
      page: 1,
      pageSize: 10,
      categoriesFilter: [],
      searchFilter: null,
    });
    this.props.closeLineItemModal();
  };

  private _onChangePrice = (lineItemNumber, event) => {
    const newSelectedLineItems = _.map(this.state.selectedSupportCategorySupportItems, (lineItem) => {
      if (lineItem.SupportItemNumber === lineItemNumber) {
        return { ...lineItem, NationalNonRemote: event };
      } else {
        return { ...lineItem };
      }
    });
    this.setState({ selectedSupportCategorySupportItems: newSelectedLineItems });
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  private _saveNewFilterValue = async (filterType, newFilterValue, selectionLabel) => {
    this.setState({
      categoriesFilter: newFilterValue,
      page: 1,
      billingLineItemList: await ndisHelper.getLineItems(1, 10, this.state.searchFilter, newFilterValue),
      isLoadingList: false,
    });
  };

  private _fetchMoreLineItem = () => {
    this.setState({ isLoadingList: true, page: this.state.page + 1 }, () => {
      const newBillingLineItemList = this.state.billingLineItemList.concat(
        ndisHelper.getLineItems(
          this.state.page,
          this.state.pageSize,
          this.state.searchFilter,
          this.state.categoriesFilter,
        ),
      );
      this.setState({
        billingLineItemList: newBillingLineItemList,
        isLoadingList: false,
      });
    });
  };

  private _setFilterOpen = (nextOpenState) => {
    this.setState({ isFilterOpen: nextOpenState });
  };

  private _onEnterSearchText = async (e) => {
    const txt = e.target.value;
    this.setState({ isLoadingList: true, searchFilter: txt }, () => this._debounceSearch(txt));
  };

  private _searchText = async (txt) => {
    this.setState({
      billingLineItemList: await ndisHelper.getLineItems(1, 10, txt, this.state.categoriesFilter),
      isLoadingList: false,
      page: 1,
      pageSize: 10,
    });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _isTravelEnabled = (item) => {
    const { isChargeNdisTransportBeforeBooking, isChargeNdisTransportDuringBooking } = _.get(
      this.props.selectedService,
      'serviceClaimConfig',
      {},
    );
    if (
      (item.DateType === 'PTNLC' && !isChargeNdisTransportBeforeBooking) ||
      (item.DateType === 'ABT' && !isChargeNdisTransportDuringBooking)
    ) {
      return false;
    } else {
      return true;
    }
  };

  private _addLineItemSelection = () => {
    this._addSelection([this.state.transferLineItem]);
    this._closeWarningModal();
  };

  private _checkForGroup = (item) => {
    const isChecked = !_.find(
      this.state.selectedSupportCategorySupportItems,
      (lineItem) => lineItem.SupportItemNumber === item.SupportItemNumber,
    );
    if (isChecked && item.GroupCode && !Utils.isEmpty(item.GroupCode)) {
      const groupLineItems = _.filter(
        ndisHelper.getByGroupCode(item.GroupCode),
        (lineItem) => lineItem.SupportItemNumber !== item.SupportItemNumber,
      );
      if (!_.isEmpty(groupLineItems)) {
        this.setState({ isGroupModalOpen: true, selectedLineItem: item, groupLineItems });
      } else {
        this._addSelection([item]);
      }
    } else if (item.DateType === 'PTNLC' || item.DateType === 'ABT') {
      if (!this._isTravelEnabled(item)) {
        this.setState({ warningModalType: item.DateType, isWarningModalOpen: true, transferLineItem: item });
      } else {
        this._addSelection([item]);
      }
    } else {
      this._addSelection([item]);
    }
  };

  private _addSelection = (items) => {
    const { selectedSupportCategorySupportItems } = this.state;
    let newSelections = selectedSupportCategorySupportItems ? selectedSupportCategorySupportItems : [];
    _.forEach(items, (item) => {
      const isChecked = !_.find(
        this.state.selectedSupportCategorySupportItems,
        (lineItem) => lineItem.SupportItemNumber === item.SupportItemNumber,
      );
      if (isChecked) {
        newSelections.push(item);
      } else {
        newSelections = _.filter(
          selectedSupportCategorySupportItems,
          (lineItem) => lineItem.SupportItemNumber !== item.SupportItemNumber,
        );
      }
    });
    this.setState({ selectedSupportCategorySupportItems: [...newSelections] });
  };

  private _closeGroupModal = () => {
    this.setState({ isGroupModalOpen: false, selectedLineItem: null, groupLineItems: null });
  };

  componentDidMount = async () => {
    this.setState({
      billingLineItemList: await ndisHelper.getLineItems(this.state.page, this.state.pageSize, null, null),
      isLoadingList: false,
    });
  };

  componentDidUpdate = async (prevProps: Readonly<IAddNDISLineItemsModalProps>) => {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        billingLineItemList: await ndisHelper.getLineItems(this.state.page, this.state.pageSize, null, null),
        isLoadingList: false,
      });
    }
  };

  render() {
    const {
      isFilterOpen,
      searchFilter,
      isLoadingList,
      categoriesFilter,
      billingLineItemList,
      selectedSupportCategorySupportItems,
      page,
      pageSize,
    } = this.state;
    const { form, serviceBillingItems, showPrice } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div>
        <AddNDISGroupLineItemsModal
          isOpen={this.state.isGroupModalOpen}
          closeModal={this._closeGroupModal}
          groupLineItems={this.state.groupLineItems}
          saveLineItem={this._addSelection}
          selectedLineItem={this.state.selectedLineItem}
          alreadyAddedLineItems={this.state.selectedSupportCategorySupportItems}
          alreadySavedLineItems={serviceBillingItems}
        />
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className='mr-medium' size='large' onClick={this._closeProceedModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size='large' onClick={this._closeLineItemModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          title={
            <>
              Add <b>NDIS Line Items</b>
            </>
          }
          onClose={this._closeLineItemCheckModal}
          width={'x3-large'}
        >
          <div className='anim-slide-left'>
            <Paragraph>Please select the NDIS line items you wish to charge for this service.</Paragraph>

            <Row gutter={24}>
              <Col span={12}>
                <Row type={'flex'} align={'middle'}>
                  <Search
                    onChange={this._onEnterSearchText}
                    loading={isLoadingList}
                    placeholder='Search for a line item...'
                    allowClear
                    style={{ width: '300px' }}
                    value={searchFilter}
                  />
                  <Popover
                    content={
                      <FilterItemMenu
                        filter={{ filter: FilterType.NDIS_CATEGORIES, values: categoriesFilter, selectionLabel: '' }}
                        saveNewFilterValue={this._saveNewFilterValue}
                        removeFilter={() => false}
                        canRemove={false}
                        displayTimezone={null}
                      />
                    }
                    popoverClassName={'mb-medium'}
                    position={'bottom-left'}
                    usePortal={false}
                    isOpen={isFilterOpen}
                    interactionKind='click'
                    onInteraction={(nextOpenState) => this._setFilterOpen(nextOpenState)}
                  >
                    <div
                      className={`${
                        !isFilterOpen
                          ? 'bg-blue-action-lightest text-color-blue-action'
                          : 'bg-blue-action text-color-white border-blue-action'
                      } mh-medium ph-small pv-x-small inline-block cursor-pointer rounded select-none`}
                    >
                      <b>Categories</b>
                      <Icon type={'down'} className={'text-size-small ml-small'} />
                    </div>
                  </Popover>
                </Row>
              </Col>
              <Col span={12}>
                <div>
                  <Text weight={'bold'}>Selected line items</Text>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Row
                  style={{ height: '350px', overflow: 'auto' }}
                  className={'bordered mt-small'}
                  id={'list-item-scroll'}
                >
                  {isLoadingList ? (
                    <SpinningLoader size={50} message={'Retrieving line items..'} />
                  ) : (
                    <InfiniteScrollLoading
                      hasMore={billingLineItemList.length >= page * pageSize}
                      loadingElementId={'list-item-scroll'}
                      loadMore={this._fetchMoreLineItem}
                      loaderColSpan={7}
                      loadingOffSet={60}
                    >
                      {billingLineItemList && billingLineItemList.length > 0 ? (
                        _.map(billingLineItemList, (lineItem, key) => {
                          const isDisabled = _.find(
                            serviceBillingItems,
                            (existingLineItem) => existingLineItem.supportItemNumber === lineItem.SupportItemNumber,
                          );
                          const isChecked = !!_.find(
                            selectedSupportCategorySupportItems,
                            (selection) => selection.SupportItemNumber === lineItem.SupportItemNumber,
                          );
                          return (
                            <Row
                              className={`pv-small bordered-bottom ${
                                isDisabled ? 'bg-secondary' : 'hover-bg-tertiary cursor-pointer'
                              } ${isChecked && 'bg-blue-lightest'}`}
                              key={key}
                              onClick={() => (!isDisabled ? this._checkForGroup(lineItem) : false)}
                            >
                              <Col span={2} className={'text-align-center'}>
                                {!isDisabled && <Checkbox checked={isChecked} value={lineItem.SupportItemNumber} />}
                              </Col>
                              <Col span={22}>
                                {lineItem.SupportItemNumber}{' '}
                                {isDisabled && <Text weight={'bold'}>(Already added to service)</Text>}
                                <br />
                                <Text size={'regular'}>{lineItem.SupportItem}</Text>
                              </Col>
                            </Row>
                          );
                        })
                      ) : (
                        <LineItemEmptyState type={'search'} />
                      )}
                    </InfiniteScrollLoading>
                  )}
                </Row>
              </Col>
              <Col span={12}>
                <div style={{ height: '350px', overflow: 'auto' }} className={'bordered mt-small'}>
                  <Row className={'pv-small bordered-bottom border-quaternary'}>
                    <Col span={showPrice ? 13 : 19} className={'ph-small'}>
                      <SubTitle>Line item</SubTitle>
                    </Col>
                    {showPrice && (
                      <Col span={6}>
                        <SubTitle>Price</SubTitle>
                      </Col>
                    )}
                    <Col span={3}>
                      <SubTitle>Unit</SubTitle>
                    </Col>
                    <Col span={2} />
                  </Row>
                  {selectedSupportCategorySupportItems && selectedSupportCategorySupportItems.length > 0 ? (
                    _.map(selectedSupportCategorySupportItems, (lineItem, key) => {
                      return (
                        <Row className={'bordered-bottom pv-small'} type={'flex'} align={'top'} key={key}>
                          <Col span={showPrice ? 13 : 19} className={'ph-small'}>
                            <Text size={'regular'}>
                              {lineItem.SupportItemNumber}
                              <br />
                              {lineItem.SupportItem}
                            </Text>
                          </Col>
                          {showPrice && (
                            <Col span={6}>
                              <Form.Item className='m-none'>
                                {getFieldDecorator('price_' + key, {
                                  initialValue:
                                    lineItem.PriceControl === 'Y' ? Number(lineItem.NationalNonRemote) : null,
                                  rules: [{ required: true, message: 'Invalid price.' }],
                                })(
                                  <NumberInput
                                    precision={2}
                                    addonBefore={'$'}
                                    step={0.01}
                                    min={0}
                                    max={9999.99}
                                    style={{ width: '120px' }}
                                    onChange={(event) => this._onChangePrice(lineItem.SupportItemNumber, event)}
                                  />,
                                )}
                              </Form.Item>
                            </Col>
                          )}
                          <Col span={3} className={'mt-x-small'}>
                            {NDISLineItemUnit[lineItem.UnitOfMeasure]}
                          </Col>
                          <Col span={2} className={'text-align-center mt-x-small'} title={'Remove line item'}>
                            <HyperlinkButton onClick={() => this._addSelection([lineItem])}>
                              <Icon type={'close'} />
                            </HyperlinkButton>
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <LineItemEmptyState type={'selection'} />
                  )}
                </div>
              </Col>
            </Row>

            <div className={'mb-small mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <Col>
                  <GhostButton size={'large'} onClick={this._closeLineItemCheckModal}>
                    Close
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size={'large'} onClick={this._saveLineItems}>
                    Add Line Items
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
        <ActionModal
          isOpen={this.state.isWarningModalOpen}
          title={<b>{this.state.warningModalType} items</b>}
          onClose={this._closeWarningModal}
          width={'small'}
        >
          <Text className='mb-medium'>
            You are attempting to add a{' '}
            {this.state.warningModalType === 'PTNLC'
              ? "'Provider travel - non-labour costs'"
              : "'Activity based transport'"}
            <b> ({this.state.warningModalType === 'PTNLC' ? 'PTNLC' : 'ABT'}) </b> support item to this service.
          </Text>
          <br />
          <br />
          <Text className='mt-large'>
            This support item can be added to the service but won't be used for automatic calculation of line items.
          </Text>
          <br />
          <br />
          <Text className='mt-large'>
            If you wish to charge for{' '}
            {this.state.warningModalType === 'PTNLC'
              ? "'Provider travel - non-labour costs' "
              : "'Activity based transport' "}
            please enable '
            {this.state.warningModalType === 'PTNLC' ? 'Travel to/from booking' : 'Travel during booking'}' in the NDIS
            claims section of payments.
          </Text>
          <ActionModalFooter className='mt-large'>
            <SecondaryButton className='mr-medium' size='large' onClick={this._closeWarningModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size='large' onClick={this._addLineItemSelection}>
              Add line item
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}
const mapState = (state: IRootState) => ({
  selectedService: state.servicesStore.selectedService,
});

export default connect(mapState, null)(Form.create<IAddNDISLineItemsModalProps>()(AddNDISLineItemsModal));
