import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SubTitle, Title, Text } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Col, Row, Skeleton, Input, Empty } from 'antd';
import { ProgressBar } from '@blueprintjs/core';
import { FilterSection } from 'common-components/filter';
import { FilterType, WorkflowTemplateTriggerType } from 'utilities/enum-utils';
import WorkflowStatusTag from 'common-components/tags/WorkflowStatusTag';
import { WorkflowStatus } from 'utilities/enum-utils';
import _ from 'lodash';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { HyperlinkButton } from 'common-components/buttons';
import * as H from 'history';

const { Search } = Input;

const availableFilters = [FilterType.WORKFLOW_STATUS];
//todo: move to workflow store or constants
export const STATUS = [
  {
    filter: FilterType.WORKFLOW_STATUS,
    values: [WorkflowStatus.ACTIVE, WorkflowStatus.INACTIVE],
    selectionLabel: ' All',
  },
];

const ListEmptyState = () => (
  <div className="flex-1 align-center flex-column mt-x-large">
    <div className="mb-small">
      <Empty className="m-none" description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x3-large" color="secondary" weight="bold">
      No workflow found.
    </Text>{' '}
    <Text color="secondary">All workflows under this filter will appear here.</Text>
  </div>
);

interface IWorkflowsUsagePanelProps {
  formId: string;
  portalUser: typeof state.authStore.portalUser;
  doFetchWorkflowsFormUsage: typeof dispatch.formBuilderStore.doFetchWorkflowsFormUsage;
  history: H.History;
}

function WorkflowsUsagePanel({ formId, portalUser, doFetchWorkflowsFormUsage, history }: IWorkflowsUsagePanelProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [pageTimestamp, setPageTimestamp] = useState<Date>(new Date());
  const [isLoadingInfiniteScrolling, setIsLoadingInfiniteScrolling] = useState<boolean>(false);
  const [workflows, setWorkflows] = useState<any>([]); //todo: update interface later
  const [searchText, setSearchText] = useState<string>('');
  const [workflowFilters, setWorkflowFilters] = useState<any>(STATUS);

  const _fetchFormUsage = async (txt?: string) => {
    setPage(1);
    const workflows = await doFetchWorkflowsFormUsage({
      formId,
      search: txt || '',
      status: [],
      page,
      pageSize,
      pageTimestamp,
    });
    setSearchText(txt || '');
    setWorkflows(workflows);
    setIsLoading(false);
  };

  const _doSearch = useCallback(_.debounce(_fetchFormUsage, 500), []);

  const _fetchMoreWorkflows = async () => {
    setIsLoadingInfiniteScrolling(true);
    setPage(page + 1);

    await _fetchFormUsage();
    setIsLoadingInfiniteScrolling(false);
  };

  const _onEnterSearchText = (e) => {
    setIsLoading(true);
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      _doSearch(e.target.value);
    }
  };

  const _onChangeFilter = async (filters: any) => {
    setPage(1);
    const values = filters.find((prop) => prop.filter === 'workflowStatus').values;
    const selectionLabel = filters.find((prop) => prop.filter === 'workflowStatus').selectionLabel;
    const filterWorkflows = [
      {
        filter: FilterType.WORKFLOW_STATUS,
        values,
        selectionLabel,
      },
    ];
    setWorkflowFilters(filterWorkflows);

    setIsLoading(true);
    const newWorkflows = await doFetchWorkflowsFormUsage({
      formId,
      search: searchText,
      status: values,
      page,
      pageSize,
      pageTimestamp,
    });

    setWorkflows(newWorkflows);
    setIsLoading(false);
  };

  const _goToWorkflowTemplatePage = (workflowTemplateId) => {
    history.push(`/account/workflows/${workflowTemplateId}`);
  };

  useEffect(() => {
    _fetchFormUsage();
  }, [formId]);

  return (
    <div className="pv-x-large">
      <Title level={4}>Workflows</Title>
      <div className="">
        <div className="flex-row pb-medium">
          <Search
            placeholder="Search for a workflow"
            loading={isLoading}
            style={{ width: '240px' }}
            allowClear={true}
            className={'mr-large'}
            onChange={_onEnterSearchText}
          />
          <FilterSection
            availableFilters={availableFilters}
            filters={workflowFilters}
            onChangeFilter={_onChangeFilter}
            displayTimezone={portalUser.timezone}
            containerClassName="flex-row justify-between"
            displayMoreFilter={false}
          />
        </div>
        {isLoading ? (
          <div className="">
            <div className="pv-large">
              <ProgressBar />
            </div>
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
          </div>
        ) : !_.isEmpty(workflows) ? (
          <InfiniteScrollLoading
            hasMore={workflows.length >= page * pageSize}
            loadingElementId={'content-container'}
            loadMore={_fetchMoreWorkflows}
            loaderColSpan={7}
            loadingOffSet={60}
          >
            <div className=" bg-white">
              <Row className="ph-medium pv-large">
                <Col span={6}>
                  <SubTitle>WORKFLOW NAME</SubTitle>
                </Col>
                <Col span={6}>
                  <SubTitle>TYPE</SubTitle>
                </Col>
                <Col span={6}>
                  <SubTitle>CURRENT VERSION</SubTitle>
                </Col>
                <Col span={6}>
                  <SubTitle>STATUS</SubTitle>
                </Col>
              </Row>
              {_.map(workflows, (wf) => (
                <Row className="ph-medium pv-large bordered-top" key={wf.workflowTemplateId}>
                  <Col span={6}>
                    <HyperlinkButton onClick={() => _goToWorkflowTemplatePage(wf.workflowTemplateId)}>
                      {wf.name}
                    </HyperlinkButton>
                  </Col>
                  <Col span={6}>
                    <Text>{wf.triggerType === WorkflowTemplateTriggerType.INCIDENT ? 'Incident' : 'Manual'}</Text>
                  </Col>
                  <Col span={6}>
                    <Text>{wf.version}</Text>
                  </Col>
                  <Col span={6}>
                    <WorkflowStatusTag status={wf.status} />
                  </Col>
                </Row>
              ))}
            </div>
          </InfiniteScrollLoading>
        ) : (
          <ListEmptyState />
        )}
        {isLoadingInfiniteScrolling && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doFetchWorkflowsFormUsage: dispatch.formBuilderStore.doFetchWorkflowsFormUsage,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowsUsagePanel);
