import { Avatar } from 'antd';
import { Text, Title } from 'common-components/typography';
import React from 'react';
import { IPrimaryOwnerInfo } from 'src/interfaces/subscription-interfaces';

interface Props {
  primaryOwnerInfo: IPrimaryOwnerInfo;
}

const PrimaryOwnerCard = (props: Props) => {
  const { primaryOwnerInfo } = props;

  return (
    <div className="flex justify-around align-center bordered rounded select-none" style={{ minHeight: 128 }}>
      <Title level={4} style={{ flexShrink: 0 }} className="mv-none" weight="bolder">
        Primary owner
      </Title>
      <div className="flex flex-column mh-small" style={{ wordBreak: 'break-all' }}>
        <Text weight="bolder">{`${primaryOwnerInfo.firstName} ${primaryOwnerInfo.lastName}`}</Text>
        <Text>{primaryOwnerInfo.email}</Text>
      </div>
      <Avatar
        icon={'user'}
        shape={'circle'}
        style={{ width: 52, height: 52, flexShrink: 0 }}
        src={primaryOwnerInfo.avatarUrl}
      />
    </div>
  );
};

export default PrimaryOwnerCard;
