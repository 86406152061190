import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { Skeleton } from 'antd';
import { InfiniteScrollArea } from './infinite-scroll-area/infinite-scroll-area';
import { InfiniteScrollLoadingSkeleton } from './infinite-scroll-area/infinite-scroll-loading-skeleton';
import { InfiniteScrollEnd } from './infinite-scroll-area/infinite-scroll-end';

type IInfiniteScrollLoadingProps = {
  hasMore: boolean;
  loadingElementId?: string;
  loadingOffSet?: number;
  loadMore: () => Promise<void>;
  loaderColSpan?: number;
  customLoader?: React.ReactNode;
  children?: React.ReactNode;
  asInfiniteScrollLoadingContainer?: boolean;
};

const InfiniteScrollLoadingContainer: React.FC<IInfiniteScrollLoadingProps> = ({
  hasMore,
  loadingElementId,
  loadingOffSet = 0,
  loadMore,
  loaderColSpan = 1,
  customLoader,
  children,
}) => {
  if (!loadingElementId) {
    throw new Error('loadingElementId is required when asInfiniteScrollLoadingContainer is true');
  }

  const [isLoading, setIsLoading] = useState(false);

  const listenScrollEvent = debounce(async () => {
    if (!hasMore || isLoading) return;
    const loadingElement = document.querySelector(`#${loadingElementId}`);
    if (
      loadingElement &&
      loadingElement.scrollTop + loadingElement.clientHeight + loadingOffSet >= loadingElement.scrollHeight
    ) {
      await load();
    }
  }, 50);

  const load = async () => {
    setIsLoading(true);
    await loadMore();
    setIsLoading(false);
  };

  useEffect(() => {
    const element = document.querySelector(`#${loadingElementId}`);
    if (element) {
      element.addEventListener('scroll', listenScrollEvent);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', listenScrollEvent);
      }
    };
  }, [loadingElementId, listenScrollEvent]);

  return (
    <>
      {children}
      {isLoading &&
        (!customLoader ? (
          <table>
            <tbody>
              <tr style={{ borderBottom: '0px solid !important' }}>
                <td colSpan={loaderColSpan}>
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          customLoader
        ))}
    </>
  );
};

const InfiniteScrollLoading = ({
  asInfiniteScrollLoadingContainer,
  customLoader,
  ...props
}: IInfiniteScrollLoadingProps) => {
  if (asInfiniteScrollLoadingContainer) {
    return <InfiniteScrollLoadingContainer {...props} customLoader={customLoader} />;
  }

  return (
    <InfiniteScrollArea
      {...props}
      loader={(loading) => customLoader ?? <InfiniteScrollLoadingSkeleton animate={loading} />}
      end={<InfiniteScrollEnd />}
    />
  );
};

export default InfiniteScrollLoading;
