import { Col, notification, Row, Skeleton } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import * as H from 'history';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { SubscriptionStatus } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import CancelSubscriptionModal from './cancel-subscription/CancelSubscriptionModal';
import ThanksForFeedbackModal from './cancel-subscription/ThanksForFeedbackModal';
import BillingAccessSection from './sections/BillingAccessSection';
import PaymentMethodsSection from './sections/PaymentMethodsSection';

interface IPaymentMethodPanelProps {
  history: H.History;
}

const PaymentMethodPanel: React.FC<IPaymentMethodPanelProps> = ({ history }) => {
  const dispatch = useDispatch<IRootDispatch>();
  const { portalUser, paymentMethods, subscriptionOverview } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    paymentMethods: state.subscriptionStore.paymentMethods,
    subscriptionOverview: state.subscriptionStore.subscriptionOverview,
  }));

  const [loading, setLoading] = useState(false);
  const [isOpenCancelSubscriptionModal, setIsOpenCancelSubscriptionModal] = useState(false);
  const [visibleThanksForFeedbackModal, setVisibleThanksForFeedbackModal] = useState(false);

  const subscriptionStatus = subscriptionOverview?.subscriptionInfo?.subscriptionStatus;

  const visibleButtonCancelSubscription =
    PermissionUtils.validatePermission('CancelSubscriptionAccess', portalUser.permissions.permissionRoles) &&
    subscriptionStatus !== SubscriptionStatus.PAUSED;

  const _fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        dispatch.subscriptionStore.doGetPaymentMethods(),
        dispatch.subscriptionStore.doCreateSetupIntent(),
        dispatch.subscriptionStore.doGetBillingAccessList(),
      ]);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
    }
    setLoading(false);
  };

  const openCancelSubscriptionModal = async () => {
    try {
      await dispatch.subscriptionStore.doFetchConfirmCancelInfo();
      setIsOpenCancelSubscriptionModal(true);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
    }
  };

  useEffect(() => {
    _fetchData();
  }, []);

  if (loading) {
    return (
      <>
        <div className="flex">
          <Skeleton className="anim-slide-left mr-medium" active title />
          <Skeleton className="anim-slide-left" active title />
        </div>
        <Skeleton className="anim-slide-left" active title />
      </>
    );
  }

  return (
    <>
      <Row gutter={[24, 24]} className="mt-medium">
        <Col span={12}>
          <PaymentMethodsSection paymentMethods={paymentMethods} portalUser={portalUser} />
        </Col>
        <Col span={12}>
          <BillingAccessSection history={history} />
        </Col>
      </Row>

      {visibleButtonCancelSubscription && (
        <Row className="mt-x-large flex justify-end" style={{ margin: 0 }}>
          {subscriptionStatus === SubscriptionStatus.PAUSED ? (
            <div className="bordered-bottom border-secondary dimmer" style={{ maxWidth: 150 }}>
              <Text size="regular">Cancel Subscription</Text>
            </div>
          ) : (
            <HyperlinkButton
              className="bordered-bottom border-red-dark"
              onClick={openCancelSubscriptionModal}
              style={{ maxWidth: 150 }}
              hoverUnderline={false}
            >
              <Text size="x-large" className="text-color-red">
                Cancel subscription
              </Text>
            </HyperlinkButton>
          )}
        </Row>
      )}

      <CancelSubscriptionModal
        isOpen={isOpenCancelSubscriptionModal}
        onClose={() => setIsOpenCancelSubscriptionModal(false)}
        openThanksForFeedbackModal={() => setVisibleThanksForFeedbackModal(true)}
      />

      <ThanksForFeedbackModal
        visible={visibleThanksForFeedbackModal}
        onClose={() => setVisibleThanksForFeedbackModal(false)}
      />
    </>
  );
};

export default PaymentMethodPanel;
