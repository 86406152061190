import { Tooltip2 } from '@blueprintjs/popover2';
import { Empty, Input, Skeleton, Tabs } from 'antd';
import { IconButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { FilterType, PaymentSources } from 'utilities/enum-utils';
import { ndisHelper } from 'variables/data-helpers';
import RejectedItemRow from 'views/billings/payments/components/details/RejectedItemRow';
import EditLineItemActionModal from 'views/billings/payments/components/EditLineItemActionModal';
import ReApproveLineItemActionModel from 'views/billings/payments/components/ReApproveLineItemActionModal';
import ViewRejectReasonActionModal from 'views/billings/payments/components/ViewRejectReasonActionModal';
import WaiveLineItemActionModel from 'views/billings/payments/components/WaiveLineItemActionModel';
import { SubTitle, Text, Title } from '../../../../common-components/typography';

const { Search } = Input;

interface ListPanelProps {
  rejectedList?: typeof state.billingsStore.rejectedList;
  rejectedFilter?: typeof state.billingsStore.rejectedFilter;
  currentFilterConfig?: any;
  history?: any;
  isServiceProviderVCPEnabled: boolean;
  doFetchRejected: typeof dispatch.billingsStore.doFetchRejected;
  setRejected?: typeof dispatch.billingsStore.setRejected;
  setRejectedFilter?: typeof dispatch.billingsStore.setRejectedFilter;
  doFetchFundedCategories?: typeof dispatch.billingsStore.doFetchFundedCategories;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  portalUser: typeof state.authStore.portalUser;
}

interface ListPanelState {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  action: string;
  openAction: boolean;
  filters: any;
  mmmGroup: boolean;
  locationType: string;
  paymentSourceType: string;
  availableFilters: Array<any>;
}

const ProcessedEmptyState = () => (
  <div className='flex-1 bg-white  align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Invoices found.
    </Text>{' '}
    <br /> <br />
    <Text color='secondary'>All invoices under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PayListRejectedSection extends PureComponent<ListPanelProps, ListPanelState> {
  // topHeight is used to control sticky
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    filters: [],
    locationType: null,
    mmmGroup: null,
    paymentSourceType: PaymentSources.NDIS,
    availableFilters: [
      FilterType.DATE_RANGE,
      FilterType.CUSTOMER,
      FilterType.PAYMENT_METHODS,
      FilterType.SERVICE,
      FilterType.SERVICE_TYPE,
      FilterType.NDIS_CATEGORIES,
      FilterType.USER_LOCATION_BY_STATE,
    ],
  };

  _headerElement = null;

  openActionModal = ({ action, additionalData }) => {
    const { doFetchFundedCategories, setSelectedBillingLineItem } = this.props;
    const { paymentSourceType } = this.state;
    if (action === 'EDIT') {
      doFetchFundedCategories({ bookingId: additionalData.attendanceId });

      // TODO: duplicate with BookingBillingPanel
      const newBillingLineItem = _.cloneDeep(additionalData);
      const ndisSupportItem =
        paymentSourceType === PaymentSources.NDIS &&
        ndisHelper.getBySupportItemNumber(newBillingLineItem.supportItemNumber);
      newBillingLineItem.ruleType = paymentSourceType === PaymentSources.NDIS && ndisSupportItem.DateType;
      newBillingLineItem.isEditing = true;
      newBillingLineItem.supportItemNumberArray = [newBillingLineItem.supportItemNumber];
      newBillingLineItem.claimType = newBillingLineItem.claimType === '' ? 'STD' : newBillingLineItem.claimType;

      setSelectedBillingLineItem(newBillingLineItem);
    } else if (action === 'RE_APPROVE' || action === 'WAIVE' || action === 'VIEW') {
      setSelectedBillingLineItem(additionalData);
    }
    this.setState({ action, openAction: true });
  };

  getActionModal = () => {
    if (this.state.action === 'EDIT') {
      return EditLineItemActionModal;
    } else if (this.state.action === 'RE_APPROVE') {
      return ReApproveLineItemActionModel;
    } else if (this.state.action === 'WAIVE') {
      return WaiveLineItemActionModel;
    } else if (this.state.action === 'VIEW') {
      return ViewRejectReasonActionModal;
    } else {
      return () => <></>;
    }
  };

  closeActionModal = () => this.setState({ openAction: false });

  _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _formatFilterQuery = (appliedFilters = this.props.rejectedFilter) => {
    const requestFilter: any = {};
    _.forEach(appliedFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.issueDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'paymentMethods':
            requestFilter.paymentMethods = filter.values;
            break;
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'search':
            requestFilter.search = filter.values;
            break;
          case 'serviceIds':
            requestFilter.serviceIds = filter.values;
            break;
          case 'serviceType':
            requestFilter.serviceTypes = filter.values;
            break;
          case 'ndisCategories':
            requestFilter.supportCategoryNumbers = filter.values;
            break;
          case 'userLocationByState':
            requestFilter.bookingLocationStates = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  _applySearchFilter = (searchStr) => {
    const { rejectedFilter, setRejectedFilter } = this.props;
    const newPlanPaymentsFilter = _.clone(rejectedFilter);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'search');
    if (existingSearchIndex > -1) {
      if (searchStr === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
    }
    setRejectedFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  _searchText = (txt) => {
    this._applySearchFilter(txt);
    this.setState({ isSearching: false, searchString: txt });
  };

  _debounceSearch = _.debounce(this._searchText, 500);

  _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  _refreshListings = async () => {
    const { doFetchRejected } = this.props;
    this.setState({ isLoading: true, checkAllIndicator: false, indeterminateCheck: false, showActionSheet: false });
    this.setState({ isLoading: false, pageTimestamp: new Date(), page: 1 });

    await doFetchRejected({
      ...this._formatFilterQuery(),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
    });
    this.setState({ isLoading: false });
  };

  private _fetchMoreRejected = () => {
    const { doFetchRejected } = this.props;
    this.setState({ isLoading: true, page: this.state.page + 1 }, async () => {
      await doFetchRejected({
        ...this._formatFilterQuery(),
        paymentSourceType: this.state.paymentSourceType,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
      this.setState({ isLoading: false });
    });
  };

  private _changeTab = (e) => {
    this.props.setRejectedFilter([]);
    this.setState(
      {
        paymentSourceType: e,
        availableFilters:
          e === PaymentSources.NDIS
            ? [
                FilterType.DATE_RANGE,
                FilterType.CUSTOMER,
                FilterType.PAYMENT_METHODS,
                FilterType.SERVICE,
                FilterType.SERVICE_TYPE,
                FilterType.NDIS_CATEGORIES,
                FilterType.USER_LOCATION_BY_STATE,
              ]
            : [FilterType.DATE_RANGE, FilterType.CUSTOMER],
      },
      () => this._refreshListings(),
    );
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setRejectedFilter(filters);
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();
    const { doFetchRejected, currentFilterConfig, rejectedFilter } = this.props;

    const appliedFilters = _.isEmpty(rejectedFilter) ? currentFilterConfig.filters : rejectedFilter;

    this.setState({ isLoading: true });

    await doFetchRejected({
      ...this._formatFilterQuery(appliedFilters),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
    });
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps) => {
    const { currentFilterConfig, doFetchRejected } = this.props;

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        searchString: '',
        showFilters: false,
      });
    }

    if (prevProps.rejectedFilter !== this.props.rejectedFilter) {
      this.setState({ isLoading: true, page: 1 });
      await doFetchRejected({
        ...this._formatFilterQuery(),
        paymentSourceType: this.state.paymentSourceType,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = () => {
    const { setRejectedFilter } = this.props;
    setRejectedFilter([]);
  };

  render() {
    const { rejectedList, rejectedFilter, portalUser, history } = this.props;

    const TargetActionModal: any = this.getActionModal();

    const { currentFilterConfig } = this.props;

    return (
      <div id='scroll' className='bg-white flex-1 width-full flex-column' style={{ position: 'relative' }}>
        <TargetActionModal isOpen={this.state.openAction} onClose={this.closeActionModal} type='rejected' />
        <div>
          <div className='booking-header' ref={(com) => (this._headerElement = com)}>
            <div className='flex-row justify-between align-center'>
              <div>
                <Title level={3} className='mv-none' lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color='secondary'>{currentFilterConfig.description}</Text>
              </div>
              <div className='flex-column align-center'>
                <div className='pr-x-small'>
                  <Tooltip2 content='Refresh this view'>
                    <IconButton
                      color='white'
                      onClick={this._refreshListings}
                      iconColor='black'
                      size='large'
                      icon='reload'
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <Tabs defaultActiveKey='ALL' animated={true} onChange={this._changeTab} className='mt-large'>
              <Tabs.TabPane tab={PaymentSources.NDIS} key={PaymentSources.NDIS} />
              {this.props.isServiceProviderVCPEnabled && (
                <Tabs.TabPane tab={PaymentSources.VCP} key={PaymentSources.VCP} />
              )}
            </Tabs>

            <div className='flex-row pb-medium align-center justify-between'>
              <div className='mr-x2-large' style={{ minWidth: '300px' }}>
                <Search
                  onChange={this._onEnterSearchText}
                  allowClear={true}
                  loading={this.state.isSearching}
                  placeholder='Name or Invoice number'
                />
              </div>
              <FilterSection
                availableFilters={this.state.availableFilters}
                filters={rejectedFilter ? rejectedFilter : []}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={portalUser.timezone}
                containerClassName='mv-small'
              />
            </div>
          </div>

          <table className='payment-listing'>
            <thead>
              <tr>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Date</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Invoice (items)</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Customer</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap' />
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap text-align-right'>
                  <SubTitle containerClassName='text-align-right'>Total</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, width: '80px' }} />
              </tr>
            </thead>

            <tbody>
              {!this.state.isLoading && _.isEmpty(rejectedList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <ProcessedEmptyState />
                  </td>
                </tr>
              )}

              <InfiniteScrollLoading
                hasMore={rejectedList.length >= this.state.page * this.state.pageSize}
                loadingElementId='scroll'
                loadMore={this._fetchMoreRejected}
                loaderColSpan={7}
                loadingOffSet={60}
              >
                {_.map(rejectedList, (rejectedItem) => (
                  <RejectedItemRow
                    rejectedItem={rejectedItem}
                    history={history}
                    key={rejectedItem.invoiceId}
                    openActionModal={this.openActionModal}
                  />
                ))}
              </InfiniteScrollLoading>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className='flex-1 bg-white'>&nbsp;</div>

        {!this.state.isLoading && !this.state.showActionSheet && rejectedList.length > 0 && (
          <ItemCountSheet itemCount={rejectedList.length} itemLabel='Invoice' />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedRejected: state.billingsStore.selectedRejected,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedRejected: dispatch.billingsStore.setSelectedRejected,
  doFetchFundedCategories: dispatch.billingsStore.doFetchFundedCategories,
  setSelectedBillingLineItem: dispatch.billingsStore.setSelectedBillingLineItem,
});

export default connect(mapState, mapDispatch)(PayListRejectedSection);
