import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import { ICrumb } from 'interfaces/common-interface';
import { IRootState } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';

import { Button } from '@goodhuman-me/components';
import { Icon } from 'antd';
import { ReactComponent as CustomTypeIcon } from 'assets/Icons/form-builder-icons/custom-type-icon.svg';
import { ReactComponent as IncidentTypeIcon } from 'assets/Icons/form-builder-icons/incident-type-icon.svg';
import { ReactComponent as IntakeTypeIcon } from 'assets/Icons/form-builder-icons/intake-type-icon.svg';
import TextField from 'common-components/text-field/TextField';
import { Text, Title } from 'common-components/typography';
import CenteredLayout from 'layouts/CenteredLayout';
import { RiSearchLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DisplayFormType, FormType } from 'utilities/enum-utils';
import CreateFormNameModal from './components/CreateFormNameModal';

interface IFormItem {
  type: FormType;
  description: string;
  icon: React.ComponentType;
  url: string;
}

interface IFormCardItemProps {
  item: IFormItem;
  onClick: (item: IFormItem) => void;
}

const FormCardItem = (props: IFormCardItemProps): JSX.Element => {
  const { item, onClick } = props;
  const { type, icon, description } = item;

  return (
    <FormItemContainer onClick={() => onClick(item)}>
      <div className='flex align-center mb-medium'>
        <Icon component={icon} className='text-size-x5-large mr-medium' />
        <Text weight='bold' style={{ color: '#000000', fontSize: 18 }}>
          {DisplayFormType[type]}
        </Text>
      </div>
      <Text size='regular' style={{ color: '#000000', fontWeight: 500 }}>
        {description}
      </Text>
    </FormItemContainer>
  );
};

const crumbs: ICrumb[] = [
  {
    title: 'Account Management',
    target: '/account/landing',
  },
  {
    title: 'Forms',
    target: '/account/forms',
  },
  {
    title: 'Choose type',
  },
];

const FORM_TYPES = [
  {
    type: FormType.INCIDENT,
    icon: IncidentTypeIcon,
    description: 'Record accurate incident reports using forms that team members can complete in the app or workspace.',
    url: 'templates/incidents',
  },

  {
    type: FormType.INTAKE,
    icon: IntakeTypeIcon,
    description: 'Gather and sync information directly to a customer profile during the client intake process.',
    url: 'templates/intake',
  },
  {
    type: FormType.CUSTOM,
    icon: CustomTypeIcon,
    description: 'Create custom forms, saved in the Forms section of a customer profile.',
    url: 'create',
  },
];

const SelectFormTypeView = (): JSX.Element => {
  const history = useHistory();
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const [openFormModal, setOpenFormModal] = useState<boolean>(false);
  const [formTypes, setFormTypes] = useState<IFormItem[]>(FORM_TYPES);

  const handleCloseFormModal = () => {
    setOpenFormModal(false);
  };

  const handleOpenFormModal = () => {
    setOpenFormModal(true);
  };

  const goToFormListing = () => {
    history.push('/account/forms');
  };

  const handleClickCardForm = (item: IFormItem) => {
    if (item.type === FormType.CUSTOM) {
      handleOpenFormModal();
    } else {
      history.push(item.url);
    }
  };

  const handleClickCreateForm = async (formName: string) => {
    history.push('create', { formTitle: formName, formType: FormType.CUSTOM });
  };

  const handleSearchFormType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value) {
      setFormTypes(
        FORM_TYPES.filter((formType) =>
          DisplayFormType[formType.type].toLowerCase().includes(value.trim().toLowerCase()),
        ),
      );
    } else {
      setFormTypes(FORM_TYPES);
    }
  };

  useEffect(() => {
    if (!PermissionUtils.validatePermission('AccessToFormBuilder', portalUser.permissions.permissionRoles)) {
      history.push('/access-denied');
    }
  }, [history, portalUser.permissions.permissionRoles]);

  return (
    <div>
      <CreateFormNameModal
        isOpen={openFormModal}
        onClose={handleCloseFormModal}
        onClickCreate={handleClickCreateForm}
      />
      <BreadcrumbNav history={history} icon='home' theme='filled' crumbs={crumbs} isBordered={false} />

      <>
        <div className='flex-row justify-between align-center'>
          <div>
            <Title level={3} className='mb-x-small' style={{ color: '#000000' }}>
              Create a new form
            </Title>
            <Text size='regular' style={{ color: '#706F6D', fontWeight: 500 }}>
              Start your form by choosing a form type.
            </Text>
          </div>
          <Button kind='accent' emphasis='outlined' size='large' onClick={goToFormListing}>
            View forms
          </Button>
        </div>

        <TextField
          onChange={handleSearchFormType}
          placeholder='Search'
          style={{ width: 260 }}
          icon={<RiSearchLine size={16} />}
          containerStyle={{ marginTop: 42, marginBottom: 58 }}
        />

        <div className='flex-row'>
          {formTypes.map((formType) => (
            <FormCardItem key={formType.type} item={formType} onClick={handleClickCardForm} />
          ))}
        </div>
        {!formTypes.length && (
          <div className='flex justify-center'>
            <Text size='x2-large' color='secondary' weight='bold'>
              No Form Type Found.
            </Text>
          </div>
        )}
      </>
    </div>
  );
};

export default SelectFormTypeView;

const FormItemContainer = styled.div({
  width: '320px',
  borderRadius: '8px',
  border: '2px solid #F5F4F4',
  marginRight: '32px',
  padding: '32px',
  paddingBottom: '38px',
  cursor: 'pointer',
  ':last-child': {
    marginRight: 0,
  },
  ':hover': {
    borderColor: '#206E8C',
    backgroundColor: '#F4F8F9',
  },
});
