import { Avatar, Checkbox, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { ICustomerListItem } from 'interfaces/customer-interfaces';
import { IUserInvolved } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { TeamStatus } from 'utilities/enum-utils';

interface Props {
  userType?: 'CUSTOMER' | 'TEAM_MEMBER' | 'APPROVER';
  isOpen: boolean;
  users: IUserInvolved[];
  customers: typeof state.customersStore.customers;
  accountTeamList: typeof state.teamStore.accountTeamList;
  workerListLite: typeof state.teamStore.workerListLite;
  onClose(): void;
  onConfirm(users: IUserInvolved[]): void;
  doFetchActiveAccountTeamList: typeof dispatch.teamStore.doFetchActiveAccountTeamList;
  doFetchWorkerListLite: typeof dispatch.teamStore.doFetchWorkerListLite;
  doGetCustomers: typeof dispatch.customersStore.doGetCustomers;
}

interface State {
  isSearched: boolean;
  isSearching: boolean;
  filteredUsersList: IUserInvolved[] | ICustomerListItem[];
  selectedUsers: any[];
}

const titleAndDescriptionModal = {
  CUSTOMER: {
    title: 'Add customers',
    description: 'Please select the customers you want to add as involved in this workflow.',
  },
  TEAM_MEMBER: {
    title: 'Add team members',
    description: 'Please select the team members you want to add as involved in this workflow.',
  },
  APPROVER: {
    title: 'Add approvers',
    description: 'Please select the team members you want to add to this workflow step as an approver',
  },
};

class AddUsersModal extends PureComponent<Props, State> {
  state = {
    isSearched: false,
    isSearching: false,
    filteredUsersList: [],
    selectedUsers: [],
  };

  private params = {
    page: 1,
    pageSize: 10,
    pageTimestamp: new Date(),
    search: '',
  };

  private _searchText = async () => {
    const { userType, doGetCustomers, doFetchActiveAccountTeamList, doFetchWorkerListLite } = this.props;

    this.setState({ isSearching: true });

    if (userType === 'CUSTOMER') {
      await doGetCustomers(this.params);
    } else if (userType === 'TEAM_MEMBER') {
      await doFetchActiveAccountTeamList(this.params);
    } else if (userType === 'APPROVER') {
      await doFetchWorkerListLite({
        ...this.params,
        supportWorkerStatus: [TeamStatus.ENABLED],
        hasWorkflowPermission: true,
        hasAppAccess: ['PORTAL'],
      });
    }
    this.setState({ isSearched: true, isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 300);

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.params = { ...this.params, page: 1, search: e.target.value };
      this._debounceSearch();
    }
  };

  private _onClose = () => {
    this.setState({ isSearched: false, selectedUsers: [] });
    this.props.onClose();
  };

  private _fetchMoreUsers = () => {
    this.params.page++;
    this._searchText();
  };

  private _getSearchedUsers = () => {
    const { accountTeamList, customers, workerListLite, userType } = this.props;

    switch (userType) {
      case 'CUSTOMER':
        return customers.filter((customer) => customer.customerStatus !== 'ARCHIVED');
      case 'TEAM_MEMBER':
        return accountTeamList;
      case 'APPROVER':
        return workerListLite;
      default:
        break;
    }
  };

  private _onSelectUser = (user, selectedIndex, disabled) => {
    const newSelectedUsers = [...this.state.selectedUsers];

    if (disabled) {
      return;
    }

    if (selectedIndex === -1) {
      newSelectedUsers.push({
        displayName: `${user.firstName} ${user.lastName}`,
        avatar: user.attachmentUrl || user.avatar,
        userId: user.userId,
        type: this.props.userType === 'CUSTOMER' ? 'customer' : 'worker',
      });
    } else {
      newSelectedUsers.splice(selectedIndex, 1);
    }

    this.setState({ selectedUsers: [...newSelectedUsers] });
  };

  private _onAddUsers = () => {
    const { selectedUsers } = this.state;
    const { userType, onConfirm } = this.props;

    if (userType === 'APPROVER' && !selectedUsers.length) {
      this._onClose();
      return;
    }

    this.setState({ isSearched: false, selectedUsers: [] });
    onConfirm(selectedUsers);
  };

  render() {
    const { userType, isOpen, users, customers } = this.props;
    const { isSearched, isSearching, selectedUsers } = this.state;
    const isAddCustomersModal = userType === 'CUSTOMER';
    const userLabel = isAddCustomersModal ? 'customer' : 'team member';
    const existedSelectUserIds = _.map(users, 'userId');
    const selectedUsersIds = _.map(selectedUsers, 'userId');
    const searchedUsers = this._getSearchedUsers();
    const hasMoreUsers =
      (isAddCustomersModal ? customers : searchedUsers).length >= this.params.page * this.params.pageSize;

    return (
      <ActionModal
        isOpen={isOpen}
        width="large"
        onClose={this._onClose}
        verticalAlignment="high"
        title={userType && titleAndDescriptionModal[userType].title}
      >
        <div className="flex-column anim-slide-left mb-medium">
          <Text>{userType && titleAndDescriptionModal[userType].description}</Text>

          <Text color="secondary" size="regular" className="mv-medium">
            <Text color="secondary" weight="bold" size="regular">
              {selectedUsers.length ? selectedUsers.length : 'No'}
            </Text>
            &nbsp;{userLabel}
            {selectedUsers.length > 1 ? 's' : ''} selected
          </Text>

          <Search
            placeholder={`Search for ${userLabel}s`}
            size="large"
            onChange={this._onEnterSearchText}
            loading={isSearching}
            style={{ width: '100%' }}
            className="mb-x2-small"
            allowClear
          />

          {isSearched && (
            <>
              {searchedUsers.length > 0 ? (
                <div
                  className="overflow-y-scroll bordered shadow-container"
                  id="scroll-modal"
                  style={{ maxHeight: '250px', overflowY: 'auto', overflowX: 'hidden' }}
                >
                  <InfiniteScrollLoading
                    hasMore={hasMoreUsers}
                    loadingElementId={'scroll-modal'}
                    loadMore={this._fetchMoreUsers}
                    loaderColSpan={7}
                    loadingOffSet={60}
                  >
                    {_.map(searchedUsers, (item) => {
                      const selectedUserIndex = _.indexOf(selectedUsersIds, item.userId);
                      const isAddedUser = _.indexOf(existedSelectUserIds, item.userId) !== -1;

                      return (
                        <Row
                          key={item.userId}
                          className={`${
                            selectedUserIndex !== -1 && !isAddedUser ? 'bg-blue-lightest' : ''
                          } pv-small ph-medium pl-12 hover-bg-tertiary cursor-pointer flex-row align-center`}
                          onClick={() => this._onSelectUser(item, selectedUserIndex, isAddedUser)}
                        >
                          <Checkbox checked={selectedUserIndex !== -1 || isAddedUser} disabled={isAddedUser} />
                          <Avatar className="mh-small" icon="user" shape="circle" src={item.attachmentUrl} />
                          <span
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            <Text>{item.firstName + ' ' + item.lastName}</Text>
                            {isAddedUser && <Text color="secondary">&nbsp;(Already selected)</Text>}
                          </span>
                        </Row>
                      );
                    })}
                  </InfiniteScrollLoading>
                </div>
              ) : (
                !isSearching && (
                  <Text className="bordered shadow-container text-align-center pv-12">No {userLabel}s found</Text>
                )
              )}
            </>
          )}
        </div>

        <ActionModalFooter>
          <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onAddUsers}>
            Add
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  customers: state.customersStore.customers,
  accountTeamList: state.teamStore.accountTeamList,
  workerListLite: state.teamStore.workerListLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomers: dispatch.customersStore.doGetCustomers,
  doFetchActiveAccountTeamList: dispatch.teamStore.doFetchActiveAccountTeamList,
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite,
});

export default connect(mapState, mapDispatch)(AddUsersModal);
