import { ProgressBar } from '@blueprintjs/core';
import { Avatar, Row, Skeleton, Steps } from 'antd';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { Text } from 'common-components/typography';
import { IWorkflow } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import ListingEmptyState from '../../ListingEmptyState';

interface IWorkflowActivityLogPanelProps {
  selectedWorkflow: IWorkflow;
  activityLogs: typeof state.workflowStore.activityLogs;
  doGetWorkflowActivityLog: typeof dispatch.workflowStore.doGetWorkflowActivityLog;
}

interface IWorkflowActivityLogPanelState {
  isLoading: boolean;
  page: number;
  pageSize: number;
}

const { Step } = Steps;

class WorkflowActivityLogPanel extends PureComponent<IWorkflowActivityLogPanelProps, IWorkflowActivityLogPanelState> {
  state = {
    isLoading: false,
    page: 1,
    pageSize: 10,
  };

  private _fetchMoreActivityLog = () => {
    const { doGetWorkflowActivityLog, selectedWorkflow } = this.props;
    if (doGetWorkflowActivityLog) {
      this.setState({ page: this.state.page + 1 }, async () => {
        await doGetWorkflowActivityLog({
          workflowId: selectedWorkflow.workflowId,
          page: this.state.page,
          pageSize: this.state.pageSize,
        });
      });
    }
  };

  async componentDidMount() {
    const { doGetWorkflowActivityLog, selectedWorkflow } = this.props;
    this.setState({ isLoading: true });
    await doGetWorkflowActivityLog({
      workflowId: selectedWorkflow.workflowId,
      page: 1,
      pageSize: 10,
    });
    this.setState({ isLoading: false });
  }

  render() {
    const { activityLogs } = this.props;
    const { isLoading } = this.state;
    return (
      <div className='bg-white p-x-large'>
        <Row type='flex' align='middle' justify='space-between'>
          <div className='flex-column flex-1'>
            <Text size='x3-large' weight='bolder'>
              Activity log
            </Text>
            <Text>See a full history of activity related to this workflow.</Text>
          </div>
        </Row>

        <div className='mt-large'>
          {isLoading ? (
            <div>
              <div className='pv-large'>
                <ProgressBar />
              </div>
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
            </div>
          ) : !_.isEmpty(activityLogs) ? (
            <InfiniteScrollLoading
              hasMore={activityLogs.length >= this.state.page * this.state.pageSize}
              loadingElementId={'content-container'}
              loadMore={this._fetchMoreActivityLog}
              loaderColSpan={7}
              loadingOffSet={60}
            >
              {activityLogs.map((activity, index) => (
                <Steps direction='vertical' key={index}>
                  <Step
                    className='pt-x2-small'
                    key={index}
                    icon={<Avatar size='small' shape='square' icon='user' className='mr-small bg-blue-lighter' />}
                    title={
                      <Text className='text-color-secondary' size='small'>
                        {moment(activity.createdOn.toLocaleUpperCase()).format('h:mm A Do MMMM YYYY').toUpperCase()}
                      </Text>
                    }
                    description={
                      <div className='flex-column'>
                        <Text weight='bold'>{activity.actionType}</Text>
                        <Text>{`${activity.displayName} ${activity.action}`}</Text>
                      </div>
                    }
                  />
                </Steps>
              ))}
            </InfiniteScrollLoading>
          ) : (
            <ListingEmptyState title='No activity log found.' description='All activity log will appear here' />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  activityLogs: state.workflowStore.activityLogs,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetWorkflowActivityLog: dispatch.workflowStore.doGetWorkflowActivityLog,
});

export default connect(mapState, mapDispatch)(WorkflowActivityLogPanel);
