import React, { useState, useEffect, useCallback } from 'react';

import { Box } from '@good/ui/core';
import { useInViewport } from '@good/ui/hooks';

type InfiniteScrollAreaProps = {
  hasMore: boolean;
  loadMore: () => Promise<void>;
  children?: React.ReactNode;
  loader: (loading: boolean) => React.ReactNode;
  end: React.ReactNode;
};

export const InfiniteScrollArea = ({ hasMore, loadMore, children, loader, end }: InfiniteScrollAreaProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { ref, inViewport } = useInViewport();

  const load = useCallback(async () => {
    try {
      setIsLoading(true);
      await loadMore();
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const loadMore = inViewport && hasMore && !isLoading;
    if (loadMore) {
      void load();
    }
  }, [inViewport, isLoading, hasMore, load]);

  return (
    <>
      {children}
      {hasMore ? loader(isLoading) : end}
      <Box ref={ref} h={1} w='100%' />
    </>
  );
};
