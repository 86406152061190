import { Col, notification, Row, Skeleton } from 'antd';
import * as H from 'history';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { SubscriptionStatus } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import SubscriptionStatusCard from 'views/account-management/components/SubscriptionStatusCard';
import { CANCEL_SUBSCRIPTION_LINKS, MAIL_SUBJECT_HEADERS } from '../../../utils/constants';
import ActivityReportsCard from './components/ActivityReportsCard';
import CancelledSubscriptionBanner from './components/CancelledSubscriptionBanner';
import NoAccessTeamManagementModal from './components/NoAccessTeamManagementModal';
import PrimaryOwnerCard from './components/PrimaryOwnerCard';
import SupportCard from './components/SupportCard';

interface IOverviewPanelProps {
  history: H.History;
}

const OverviewPanel: React.FC<IOverviewPanelProps> = ({ history }) => {
  const dispatch = useDispatch<IRootDispatch>();

  const { portalUser, subscriptionOverview } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    subscriptionOverview: state.subscriptionStore.subscriptionOverview,
  }));

  const [loading, setLoading] = useState<boolean>(false);
  const [visibleNoAccessTeamManagement, setVisibleNoAccessTeamManagement] = useState(false);

  const getSubscriptionOverview = async () => {
    setLoading(true);
    try {
      await dispatch.subscriptionStore.doGetSubscriptionOverview();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
    }
    setLoading(false);
  };

  const onClickHyperLink = (status: SubscriptionStatus) => {
    if (status === SubscriptionStatus.CANCELED) {
      window.open(`mailto:${CANCEL_SUBSCRIPTION_LINKS.SALES_MAIL_LINK}?subject=${MAIL_SUBJECT_HEADERS.REACTIVATE}`);
      return;
    }

    const hasPermission = PermissionUtils.validatePermission(
      'ViewTeamManagement',
      portalUser.permissions.permissionRoles,
    );

    if (hasPermission) {
      history.push('/account/team-management');
    } else {
      setVisibleNoAccessTeamManagement(true);
    }
  };

  useEffect(() => {
    getSubscriptionOverview();
  }, []);

  return loading || !subscriptionOverview ? (
    <div>
      <Skeleton active title />
      <Skeleton active title />
    </div>
  ) : (
    <>
      {subscriptionOverview.alertBannerWarning?.isAlertBannerWarning && (
        <CancelledSubscriptionBanner
          alertBannerWarning={subscriptionOverview.alertBannerWarning}
          portalUser={portalUser}
        />
      )}

      <Row gutter={[24, 24]} className="mt-medium">
        <Col span={12}>
          <SubscriptionStatusCard
            subscriptionStatusInfo={subscriptionOverview.subscriptionInfo}
            currentScreen="Subscription"
            onClickHyperLink={onClickHyperLink}
          />
        </Col>
        <Col span={12}>
          <ActivityReportsCard subscriptionOverview={subscriptionOverview} />
        </Col>
      </Row>
      <Row gutter={[24, 24]} className="mt-medium">
        <Col span={12}>
          <PrimaryOwnerCard primaryOwnerInfo={subscriptionOverview.primaryOwnerInfo} />
        </Col>
        <Col span={12}>
          <SupportCard />
        </Col>
      </Row>

      <NoAccessTeamManagementModal
        visible={visibleNoAccessTeamManagement}
        onClose={() => setVisibleNoAccessTeamManagement(false)}
      />
    </>
  );
};

export default OverviewPanel;
