import { Spinner } from '@blueprintjs/core';
import { Skeleton } from 'antd';
import clsx from 'clsx';
import { HyperlinkButton } from 'common-components/buttons';
import CommonTag from 'common-components/tags/CommonTag';
import { Text } from 'common-components/typography';
import { ISubscriptionStatusInfo } from 'interfaces/subscription-interfaces';
import { endsWith, capitalize } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { SubscriptionStatus } from 'utilities/enum-utils';
import { StatusTagExtensions } from 'views/account-management/subscription-management/utils/constants';
import DescriptionSection from './DescriptionSection';

type ISubscriptionStatusCardProps = {
  className?: string;
  loading?: boolean;
  currentScreen?: 'Subscription' | 'Account';
  subscriptionStatusInfo: ISubscriptionStatusInfo;
  onClickCard?: () => void;
  onClickHyperLink?: (status: SubscriptionStatus) => void;
  hasPermission?: boolean;
};

const SubscriptionStatusCard: React.FC<ISubscriptionStatusCardProps> = ({
  className,
  loading,
  subscriptionStatusInfo,
  currentScreen = 'Subscription',
  onClickCard,
  onClickHyperLink,
  hasPermission,
}) => {
  const { companyDataLite, portalUser } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    companyDataLite: state.companyStore.companyDataLite,
  }));

  const subscriptionStatus = subscriptionStatusInfo?.subscriptionStatus;
  const statusExtensions = StatusTagExtensions[subscriptionStatus ?? SubscriptionStatus.NON_PAYG];
  const isSubscriptionScreen = currentScreen === 'Subscription';
  const daysLeftLabel = `${subscriptionStatusInfo?.numberOfDaysLeftTrial} day${
    subscriptionStatusInfo?.numberOfDaysLeftTrial === 1 ? '' : 's'
  }`;
  const clickable = companyDataLite?.hasSubscription && hasPermission;
  const subscriptionTitle = `${portalUser?.serviceProviderName}'${
    endsWith(portalUser?.serviceProviderName, 's') ? '' : 's'
  } GoodHuman subscription`;

  const onClickLink = () => {
    onClickHyperLink(subscriptionStatus);
  };

  return (
    <div
      className={clsx(
        'p-x-large bordered rounded select-none',
        !isSubscriptionScreen && clickable && 'cursor-pointer card-item-hover',
        className,
      )}
      {...(clickable && { onClick: onClickCard })}
      style={{
        minHeight: isSubscriptionScreen ? '206px' : 'auto',
      }}
    >
      <div
        className={`${isSubscriptionScreen ? 'pb-large' : 'pb-medium'}
        flex justify-between align-center
        `}
      >
        {loading ? (
          <>
            <Skeleton title={false} paragraph={{ rows: 1 }} active />
            <Spinner size={25} />
          </>
        ) : (
          <>
            <Text
              size={isSubscriptionScreen ? 'x2-large' : 'x3-large'}
              color={!isSubscriptionScreen && (!companyDataLite?.hasSubscription || hasPermission) && 'info-blue'}
              weight={isSubscriptionScreen ? 'bolder' : ''}
              className={clickable && 'hover-text-underline'}
            >
              {capitalize(
                isSubscriptionScreen
                  ? `Subscription: ${statusExtensions.statusLabel}`
                  : subscriptionStatus || companyDataLite?.hasSubscription
                  ? 'Your subscription'
                  : subscriptionTitle,
              )}
            </Text>
            <div className="flex align-center">
              <CommonTag
                content={isSubscriptionScreen ? statusExtensions.labelDetail : statusExtensions.label}
                color={statusExtensions.color}
                borderColor={statusExtensions.borderColor}
                bgColor={statusExtensions.bgColor}
              />

              {subscriptionStatus === SubscriptionStatus.TRIALING && currentScreen === 'Account' && (
                <CommonTag content={daysLeftLabel} bgColor="orange" color="white" containerClassName="ml-small" />
              )}
            </div>
          </>
        )}
      </div>
      {loading ? (
        <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} active />
      ) : (
        <div className="mb-large">
          <DescriptionSection
            daysLeftLabel={daysLeftLabel}
            hasPermission={hasPermission}
            timezone={portalUser?.timeZone}
            hasSubscription={companyDataLite?.hasSubscription}
            isSubscriptionScreen={isSubscriptionScreen}
            subscriptionStatusInfo={subscriptionStatusInfo}
          />
        </div>
      )}

      {isSubscriptionScreen && subscriptionStatus !== SubscriptionStatus.PAUSED && (
        <HyperlinkButton onClick={onClickLink}>
          {subscriptionStatus === SubscriptionStatus.CANCELED ? 'Reactivate subscription' : 'Manage team access'}
        </HyperlinkButton>
      )}
    </div>
  );
};

export default SubscriptionStatusCard;
