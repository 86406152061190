import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Select } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { INotification } from 'interfaces/notification-interfaces';
import moment from 'moment';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import NotificationUtils from 'utilities/notification-utils';
import themeVariables from 'theme/theme-variables';
import '../bookings/new-listings/css/new-booking-listing.css';
import { RouteComponentProps } from 'react-router-dom';

const { bodyHeight } = themeVariables;

type INotificationsLandingViewProps = {
  notificationItems: INotification[];
  totalNotificationCount: number;
  doFetchNotificationItems: typeof dispatch.notificationsStore.doFetchNotificationItems;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
} & RouteComponentProps;

type INotificationsLandingViewState = {
  topHeight: number;
  page: number;
  pageSize: number;
  notificationStateList: any;
  actionType: string;
  isLoading: boolean;
  error: boolean;
  hasMore: boolean;
};

class NotificationsLandingView extends Component<INotificationsLandingViewProps, INotificationsLandingViewState> {
  constructor(props) {
    super(props);
    // Sets up our initial state
    this.state = {
      error: false,
      hasMore: true,
      isLoading: false,
      notificationStateList: [],
      page: 1,
      pageSize: 20,
      actionType: 'ALL',
      topHeight: 0,
    };
  }

  _headerElement = null;

  _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  async componentDidMount() {
    this._handleHeaderHeight();
    this.props.setSelectedSideNavMenuKeys(['/notifications']);
    await this.getNotificationLists();
  }

  getNotificationLists = async () => {
    const { page, pageSize, actionType } = this.state;
    const { doFetchNotificationItems } = this.props;
    this.setState({ isLoading: true });
    const notificationStateList = await doFetchNotificationItems({
      page: page,
      pageSize: pageSize,
      actionType: actionType,
    });

    if (!notificationStateList) {
      throw new Error('Failed to fetch notification list');
    }

    this.setState({
      // this will work as well because you decided this value before concat the array
      hasMore: pageSize <= (notificationStateList ?? []).length,
      isLoading: false,
      page: page + 1,
      pageSize: pageSize,
      notificationStateList: this.state.notificationStateList.concat(notificationStateList),
    });
  };

  private _filterByType = (actionType) => {
    this.setState({ page: 1, actionType: actionType, notificationStateList: [] }, async () => {
      this.getNotificationLists();
    });
  };

  render() {
    const { notificationStateList } = this.state;
    const { history } = this.props;

    return (
      <div style={{ height: bodyHeight }}>
        <div className='height-full flex-row'>
          {/*<div className="bg-tertiary" style={{ width: '300px', overflowY: 'auto' }} />*/}
          <div
            id='notification-scrolling'
            style={{ overflowY: 'auto', position: 'relative' }}
            className='width-full flex-column flex-1 bg-white'
          >
            <div>
              <div className='booking-header' ref={(com) => (this._headerElement = com)}>
                <div
                  className='book-listing-full-row align-center pb-medium flex-row justify-between  bg-white'
                  // style={{ position: 'sticky', top: '80px', zIndex: 10 }}
                >
                  <div>
                    <Select
                      defaultValue='ALL'
                      className='mr-small'
                      style={{ minWidth: 160 }}
                      onChange={this._filterByType}
                    >
                      <Select.Option value='ALL'>All Notifications</Select.Option>
                      <Select.Option value='ACTION'>Action</Select.Option>
                      <Select.Option value='AWARENESS'>Awareness</Select.Option>
                    </Select>
                  </div>
                </div>
              </div>

              <table className='bordered-bottom mt-medium pv-small booking-listing'>
                <thead>
                  <tr>
                    <th className='nowrap' style={{ top: `${this.state.topHeight}px` }}>
                      <Text>Time</Text>
                    </th>
                    <th className='nowrap' style={{ top: `${this.state.topHeight}px` }}>
                      <Text>Type</Text>
                    </th>
                    <th className='nowrap' style={{ top: `${this.state.topHeight}px` }}>
                      <Text>Author</Text>
                    </th>
                    <th className='nowrap' style={{ top: `${this.state.topHeight}px` }}>
                      <Text>Content</Text>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <InfiniteScrollLoading
                    hasMore={this.state.hasMore}
                    loadingElementId={'notification-scrolling'}
                    loadMore={this.getNotificationLists}
                    loadingOffSet={100}
                    loaderColSpan={4}
                  >
                    {notificationStateList.map((notificationItem, index) => (
                      <tr
                        className='anim-fade-in hover-bg-tertiary bg-white'
                        key={index}
                        onClick={() => {
                          NotificationUtils.Navigate(history, notificationItem, false);
                        }}
                      >
                        <td className='fluid-cell'>
                          <Text size='large' color='secondary' style={{ whiteSpace: 'nowrap' }}>
                            {moment(notificationItem.createdOn).format('DD/MM/YYYY hh:mm a')}
                          </Text>
                        </td>
                        <td className='fluid-cell'>
                          <Text>
                            <div className='ph-small inline-block rounded bg-orange'>
                              <Text lineHeight={100} size='small' weight='bold' color='white'>
                                {notificationItem.actionType}
                              </Text>
                            </div>
                          </Text>
                        </td>
                        <td className='fluid-cell'>
                          <Text>
                            <HyperlinkButton
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                history.push(
                                  `/${notificationItem.requestUserType === 'WORKER' ? 'team' : 'customer'}/details/${
                                    notificationItem.requestUserId
                                  }`,
                                );
                              }}
                            >
                              {notificationItem.requestUserType !== 'SYSTEM'
                                ? notificationItem.firstName + ' ' + notificationItem.lastName
                                : 'SYSTEM'}
                            </HyperlinkButton>
                          </Text>
                        </td>
                        <td className='ellipsis'>
                          <Text>
                            <b>{notificationItem.title}</b>
                            <br />
                            {notificationItem.body}
                          </Text>
                        </td>
                      </tr>
                    ))}
                  </InfiniteScrollLoading>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  notificationItems: state.notificationsStore.notificationItems,
  totalNotificationCount: state.notificationsStore.totalNotificationCount,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchNotificationItems: dispatch.notificationsStore.doFetchNotificationItems,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
});

export default connect(mapState, mapDispatch)(NotificationsLandingView);
