import React, { Component } from 'react';
import { Title, Text } from 'common-components/typography';
import { Empty, Input, Skeleton, Row } from 'antd';
import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import { ArchiveDebtorFrom, FilterType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import CommonUtils from 'utilities/common-utils';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import AddDebtorModal from './components/DebtorManagementComponents/AddDebtorModal';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import ArchiveDebtorModal from './components/DebtorManagementComponents/ArchiveDebtorModal';
import copy from 'copy-to-clipboard';
import UnarchiveDebtorModal from './components/DebtorManagementComponents/UnarchiveDebtorModal';
import { ActionIcon, Pill, Popover, Table } from '@good/ui/core';
import { MoreHoriz } from '@good/icons';

const { Search } = Input;

interface IDebtorManagementViewProps extends RouteComponentProps {
  debtors: typeof state.accountStore.debtors;
  doFetchDebtorList: typeof dispatch.accountStore.doFetchDebtorList;
}

interface IDebtorManagementViewState {
  isLoading: boolean;
  isSearching: boolean;
  isOpenAddDebtorModal: boolean;
  debtorFilters: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoadingInfiniteScrolling: boolean;
  isOpenArchiveModal: boolean;
  isOpenUnarchiveModal: boolean;
  selectedDebtor: any;
  visiblePopoverContent: {
    [key: string | number]: boolean;
  };
}

export type Debtor = {
  debtorId: number;
  debtorName: string;
  debtorNumber?: string;
  debtorEmail?: string;
  debtorContactNumber?: string;
  numberOfCustomers?: number;
  isArchived?: boolean;
  inactive?: boolean;
};

const availableFilters = [FilterType.CUSTOMER];

const ListEmptyState = () => (
  <div className='flex-1 bg-white  align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Debtors found.
    </Text>
    <br /> <br />
    <Text color='secondary'>All Debtors under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter, or refreshing the page.</Text>
  </div>
);

const defaultFilters = [
  {
    filter: FilterType.CUSTOMER,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
  },
];

class DebtorManagementView extends Component<IDebtorManagementViewProps, IDebtorManagementViewState> {
  state = {
    isLoading: false,
    isSearching: false,
    isOpenAddDebtorModal: false,
    isOpenUnarchiveModal: false,
    debtorFilters: [],
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    isLoadingInfiniteScrolling: false,
    isOpenArchiveModal: false,
    selectedDebtor: null,
    visiblePopoverContent: {},
  };

  async componentDidMount() {
    const { doFetchDebtorList } = this.props;
    const { debtorFilters, page, pageSize, pageTimestamp } = this.state;
    this.setState({ isLoading: true, debtorFilters: defaultFilters });
    await doFetchDebtorList({
      filters: debtorFilters,
      page: page,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp,
      sortByAlphabet: true,
    });
    this.setState({ isLoading: false });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { doFetchDebtorList } = this.props;
    const { debtorFilters } = this.state;

    if (!_.isEqual(prevState.debtorFilters, this.state.debtorFilters)) {
      this.setState({ isLoading: true });
      const pageTimestamp = new Date();
      this.setState({ isLoading: true, page: 1, pageTimestamp });

      await doFetchDebtorList({
        filters: debtorFilters,
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp,
        sortByAlphabet: true,
      });

      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount() {
    this.setState({ debtorFilters: defaultFilters });
  }

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _searchText = async (txt) => {
    const { doFetchDebtorList } = this.props;
    const { debtorFilters } = this.state;
    this.setState({ page: 1 });
    await doFetchDebtorList({
      page: 1,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      filters: debtorFilters,
      search: txt,
      sortByAlphabet: true,
    });

    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onChangeFilter = (filters: Array<any>) => {
    this.setState({ debtorFilters: filters });
  };

  private _onCloseAddDebtorModal = () => {
    this.setState({ isOpenAddDebtorModal: false });
  };

  private _fetchMoreDebtors = async () => {
    const { doFetchDebtorList } = this.props;
    const { debtorFilters, page, pageSize, pageTimestamp } = this.state;
    const nextPage = page + 1;
    this.setState({ isLoadingInfiniteScrolling: true, page: nextPage });

    await doFetchDebtorList({
      filters: debtorFilters,
      page: nextPage,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp,
      sortByAlphabet: true,
    });

    this.setState({ isLoadingInfiniteScrolling: false });
  };

  render() {
    const { isOpenAddDebtorModal, debtorFilters, isOpenArchiveModal, selectedDebtor, isOpenUnarchiveModal } =
      this.state;
    const { history, debtors } = this.props;

    const statusTag = (debtor: Debtor) => {
      if (debtor.inactive) {
        return <Pill className='bg-notice-weak text-notice font-semibold'> Inactive </Pill>;
      }
      if (debtor.isArchived) {
        return <Pill className='bg-critical-weak text-critical font-semibold'> Archived </Pill>;
      }
      return <Pill className='bg-positive-weak text-green-dark-2 font-semibold'> Active </Pill>;
    };

    const rows = debtors.map((debtor: Debtor) => {
      return (
        <Table.Tr key={debtor.debtorId}>
          <Table.Td>
            <Link to={`/account/debtor-management/${debtor.debtorId}`}>
              <HyperlinkButton>{debtor.debtorName}</HyperlinkButton>
            </Link>
          </Table.Td>
          <Table.Td>{debtor.debtorNumber}</Table.Td>
          <Table.Td>{debtor.debtorEmail}</Table.Td>
          <Table.Td>{debtor.debtorContactNumber}</Table.Td>
          <Table.Td>{debtor.numberOfCustomers}</Table.Td>
          <Table.Td>{statusTag(debtor)}</Table.Td>
          <Table.Td>
            <Popover position='bottom-end' withArrow shadow='md'>
              <Popover.Target>
                <ActionIcon aria-label='Actions' variant='default' className='border-white'>
                  <MoreHoriz />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <div style={{ margin: '-4px -16px' }}>
                  <Row
                    onClick={() => copy(debtor.debtorEmail)}
                    className='cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest'
                  >{`Copy ${debtor.debtorEmail} to clipboard`}</Row>
                  {debtor.isArchived ? (
                    <Row
                      onClick={() =>
                        this.setState({
                          selectedDebtor: debtor,
                          isOpenUnarchiveModal: true,
                          visiblePopoverContent: {},
                        })
                      }
                      className='cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest text-color-red'
                    >
                      Unarchive debtor
                    </Row>
                  ) : (
                    <Row
                      onClick={() =>
                        this.setState({
                          selectedDebtor: debtor,
                          isOpenArchiveModal: true,
                          visiblePopoverContent: {},
                        })
                      }
                      className='cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest text-color-red'
                    >
                      Archive debtor
                    </Row>
                  )}
                </div>
              </Popover.Dropdown>
            </Popover>
          </Table.Td>
        </Table.Tr>
      );
    });

    return (
      <>
        <ArchiveDebtorModal
          debtor={selectedDebtor}
          isOpen={isOpenArchiveModal}
          archiveDebtorFrom={ArchiveDebtorFrom.LISTING}
          closeArchiveModal={() => this.setState({ isOpenArchiveModal: false })}
        />
        <UnarchiveDebtorModal
          debtor={selectedDebtor}
          history={history}
          isOpen={isOpenUnarchiveModal}
          unarchiveDebtorFrom={ArchiveDebtorFrom.LISTING}
          closeUnarchiveModal={() => this.setState({ isOpenUnarchiveModal: false })}
        />
        <AddDebtorModal history={history} isOpen={isOpenAddDebtorModal} onClose={this._onCloseAddDebtorModal} />
        <>
          <div className='flex-row justify-between'>
            <Title level={3} weight='regular' className='mv-none'>
              <Link to='/account/landing'>
                <span className='cursor-pointer text-color-blue-action'>{'Account >'}</span>{' '}
              </Link>
              Debtor management
            </Title>
            <PrimaryButton size='large' icon='plus' onClick={() => this.setState({ isOpenAddDebtorModal: true })}>
              Add debtor
            </PrimaryButton>
          </div>
          <div>
            <div style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
              <div className='bg-white'>
                <div className='flex-row align-center justify-start mv-medium'>
                  <div>
                    <Search
                      size='large'
                      placeholder='Search'
                      onChange={this._onEnterSearchText}
                      loading={this.state.isSearching}
                      style={{ width: '250px' }}
                      allowClear={true}
                    />
                  </div>

                  <div className='ml-small'>
                    <FilterSection
                      availableFilters={availableFilters}
                      filters={debtorFilters}
                      onChangeFilter={this._onChangeFilter}
                      displayTimezone={null}
                      displayMoreFilter={false}
                    />
                  </div>
                </div>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Debtor</Table.Th>
                      <Table.Th>Debtor ID</Table.Th>
                      <Table.Th>Email</Table.Th>
                      <Table.Th>Contact Number</Table.Th>
                      <Table.Th>Customers</Table.Th>
                      <Table.Th>Status</Table.Th>
                      <Table.Th></Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{rows}</Table.Tbody>
                </Table>
              </div>
            </div>
            {this.state.isLoading ? (
              <div className=''>
                <div className='pv-large'>
                  <ProgressBar />
                </div>
                <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
              </div>
            ) : !_.isEmpty(debtors) ? (
              <InfiniteScrollLoading
                hasMore={debtors.length >= this.state.page * this.state.pageSize}
                loadingElementId='content-container'
                loadMore={this._fetchMoreDebtors}
                loaderColSpan={7}
                loadingOffSet={60}
              ></InfiniteScrollLoading>
            ) : (
              <div style={{ borderBottom: '0px solid' }}>
                <ListEmptyState />
              </div>
            )}

            {this.state.isLoadingInfiniteScrolling && (
              <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
            )}
          </div>
        </>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  debtors: state.accountStore.debtors,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchDebtorList: dispatch.accountStore.doFetchDebtorList,
});

export default connect(mapState, mapDispatch)(DebtorManagementView);
