import * as H from 'history';
import React, { PureComponent, useCallback, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Card, Col, Empty, Input, Modal, Skeleton } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ProgressBar } from '@blueprintjs/core';
import { connect } from 'react-redux';

import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import CommonUtils from 'utilities/common-utils';
import CreateWorkflowTemplateModal from '../workflows/workflow-templates/listing/components/CreateWorkflowTemplateModal';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import PermissionUtils from 'utilities/permission-utils';
import WorkflowTemplateStatusTags from 'common-components/tags/WorkflowTemplateStatusTags';
import apiClient from 'utilities/api-client';
import { FieldLabel, Text } from 'common-components/typography';
import { FilterSection } from 'common-components/filter';
import { FilterType, WorkflowTemplateStatus, WorkflowTemplateTriggerType } from 'utilities/enum-utils';
import { GridHeader, GridRow } from '../../common-components/grids';
import { ICrumb } from 'interfaces/common-interface';
import { PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { getTriggerTypeWorkflowLabel } from '../workflows/utils/workflow-utils';

/********************************************************************
 * Services
 *******************************************************************/

/**
 * @name
 * fetchHasUserCreatedWorkflowTemplate
 *
 * @description
 * Check if a user has previously created a workflow template.
 */
async function fetchHasUserCreatedWorkflowTemplate(): Promise<boolean> {
  return apiClient
    .get('/api/portal/workflow-templates/hasUserCreatedWorkflowTemplate')
    .then(({ data }) => data)
    .then(
      ({ hasUserCreatedWorkflowTemplate }: { hasUserCreatedWorkflowTemplate: boolean }) =>
        hasUserCreatedWorkflowTemplate,
    );
}

/********************************************************************
 * CreateWorkflowTemplateModal
 *******************************************************************/

const CardContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  textAlign: 'center',
});

const CardHeader = styled.div({
  fontWeight: 'bold',
  color: '#0083FF',
});

const CardBody = styled.div({
  fontSize: '14px',
  color: '#5c7080',
});

type CreateWorkflowModalProps = {
  isVisible: boolean;
  onToggle: () => void;
};

function CreateWorkflowModal(props: CreateWorkflowModalProps) {
  let { isVisible, onToggle } = props;

  return (
    <Modal title={null} footer={null} destroyOnClose onCancel={onToggle} visible={isVisible}>
      <h3 className='text-weight-bold'>How it works</h3>
      <p>An incident workflow lets you set up a sequence of tasks to be completed. You can:</p>
      <ul>
        <li>Request approvals</li>
        <li>Ask for documents</li>
        <li>Get forms filled</li>
      </ul>
      <p style={{ marginBottom: '24px' }}>
        Before you start, <span className='text-weight-bold'>create any forms you require</span> to be filled in in this
        workflow using <span className='text-weight-bold'>Form builder</span>.
      </p>

      <div className='flex-row' style={{ gap: '16px' }}>
        <Link to='/account/forms'>
          <Card>
            <CardContent>
              <CardHeader>Build a form</CardHeader>
              <CardBody>Create a form to use in this workflow.</CardBody>
            </CardContent>
          </Card>
        </Link>

        <Link to='/account/workflows/create'>
          <Card>
            <CardContent>
              <CardHeader color='#0083FF'>Build a workflow</CardHeader>
              <CardBody>Jump straight in, no forms required.</CardBody>
            </CardContent>
          </Card>
        </Link>
      </div>
    </Modal>
  );
}

/********************************************************************
 * CreateWorkflowTemplate
 *******************************************************************/

const CreateWorkflow = function CreateWorkflow() {
  let [isLoading, setLoading] = useState(false);
  let [isOpen, setOpen] = useState(false);
  let { push } = useHistory();

  async function handleOnClick() {
    setLoading(true);
    let data = await fetchHasUserCreatedWorkflowTemplate();
    setLoading(false);
    if (!data) push('/account/workflows/create');
    else handleOnToggle();
  }

  const handleOnToggle = useCallback(
    function handleOnToggle() {
      setOpen((o) => !o);
    },
    [setOpen],
  );

  return (
    <>
      <CreateWorkflowModal isVisible={isOpen} onToggle={handleOnToggle} />

      <PrimaryButton icon='plus' onClick={handleOnClick} loading={isLoading}>
        Create workflow template
      </PrimaryButton>
    </>
  );
};

/********************************************************************
 * WorkflowTemplatesListView
 *******************************************************************/

const { Search } = Input;
interface IWorkflowTemplatesListViewProps {
  history: H.History;
  workflowTemplates: typeof state.workflowStore.workflowTemplates;
  portalUser: typeof state.authStore.portalUser;
  doGetWorkflowTemplates: typeof dispatch.workflowStore.doGetWorkflowTemplates;
}

interface IWorkflowTemplatesListViewState {
  isAddWorkflowModalOpen: boolean;
  isSearching: boolean;
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  workflowFilters: any;
}

const availableFilters = [FilterType.WORKFLOW_TEMPLATE_TYPE, FilterType.WORKFLOW_TEMPLATE_STATUS];
const defaultFilters = [
  {
    filter: FilterType.WORKFLOW_TEMPLATE_TYPE,
    values: [WorkflowTemplateTriggerType.MANUAL, WorkflowTemplateTriggerType.INCIDENT],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKFLOW_TEMPLATE_TYPE).fullSelectionName,
  },
  {
    filter: FilterType.WORKFLOW_TEMPLATE_STATUS,
    values: [WorkflowTemplateStatus.ACTIVE, WorkflowTemplateStatus.INACTIVE],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKFLOW_TEMPLATE_STATUS).fullSelectionName,
  },
];
const defaultParams = { page: 1, pageSize: 20 };
const workflowListParams = {
  [FilterType.WORKFLOW_TEMPLATE_STATUS]: 'status',
  [FilterType.WORKFLOW_TEMPLATE_TYPE]: 'triggerType',
};

const ListEmptyState = () => {
  return (
    <div className='flex-1 bg-white  align-center flex-column'>
      <div className=''>
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size='x2-large' color='secondary' weight='bold'>
        No workflow found.
      </Text>
      <br /> <br />
      <Text color='secondary'>All workflows under this filter will appear here.</Text>
      <Text color='secondary'>Try adjusting your filter, or clicking on another tab.</Text>
    </div>
  );
};

const crumbs: ICrumb[] = [
  {
    title: 'Account Management',
    target: '/account/landing',
  },
  {
    title: 'Workflow templates',
  },
];

class WorkflowTemplatesListView extends PureComponent<
  IWorkflowTemplatesListViewProps,
  IWorkflowTemplatesListViewState
> {
  state = {
    isAddWorkflowModalOpen: false,
    isSearching: false,
    isLoading: true,
    isLoadingInfiniteScrolling: false,
    searchString: '',
    workflowFilters: defaultFilters,
    page: 1,
    pageSize: 20,
  };

  closeModal = async () => {
    this.setState({ isAddWorkflowModalOpen: false });
  };

  toggleModal = () => {
    this.setState({ isAddWorkflowModalOpen: !this.state.isAddWorkflowModalOpen });
  };

  private _onSearchText = (e) => {
    const value = e.target.value;

    this.setState({ searchString: value });
    this._debounceSearch(value);
  };

  private _searchText = async (value) => {
    const { doGetWorkflowTemplates } = this.props;
    const { workflowFilters } = this.state;
    const formattedFilter = this._formatFilters(workflowFilters);

    this.setState({ isSearching: true, isLoading: true });
    await doGetWorkflowTemplates({ ...defaultParams, searchString: value, ...formattedFilter });
    this.setState({ isSearching: false, page: 1, isLoading: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onChangeFilter = async (filters: Array<any>) => {
    if (_.isEqual(filters, this.state.workflowFilters)) return;
    this.setState({ workflowFilters: filters, isLoading: true, page: 1 });
  };

  private _formatFilters = (filters: Array<any>) => {
    return filters.reduce((filterObj, filter) => {
      const newFilterObj = { ...filterObj };

      if (!_.isEmpty(filter.values)) newFilterObj[workflowListParams[filter.filter]] = filter.values;
      return newFilterObj;
    }, {});
  };

  private _fetchMoreWorkflows = async () => {
    this.setState({ isLoadingInfiniteScrolling: true });
    const { doGetWorkflowTemplates } = this.props;
    const { page, pageSize, searchString, workflowFilters } = this.state;
    const nextPage = page + 1;
    const formattedFilter = this._formatFilters(workflowFilters);

    await doGetWorkflowTemplates({ page: nextPage, pageSize, searchString: searchString, ...formattedFilter });
    this.setState({ page: nextPage, isLoadingInfiniteScrolling: false });
  };

  private _onClickRow = (workflowId) => {
    this.props.history.push(`/account/workflows/${workflowId}`);
  };

  private _onRefreshWorkflows = async () => {
    const { workflowFilters } = this.state;
    const { doGetWorkflowTemplates } = this.props;

    this.setState({ isLoading: true, searchString: '', page: 1, pageSize: 20, workflowFilters: defaultFilters });
    if (_.isEqual(workflowFilters, defaultFilters)) {
      await doGetWorkflowTemplates({ ...defaultParams });
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { doGetWorkflowTemplates } = this.props;
    const { workflowFilters, searchString } = this.state;

    if (!_.isEqual(prevState.workflowFilters, workflowFilters)) {
      const newFilters = this._formatFilters(workflowFilters);
      await doGetWorkflowTemplates({ ...defaultParams, searchString, ...newFilters });
      this.setState({ isLoading: false });
    }
  };

  componentDidMount = async () => {
    const { doGetWorkflowTemplates, portalUser, history } = this.props;
    if (!PermissionUtils.validatePermission('ViewAddEditWorkflow', portalUser.permissions.permissionRoles)) {
      history.push('/access-denied');
    }
    await doGetWorkflowTemplates({ ...defaultParams });
    this.setState({ isLoading: false });
  };

  render() {
    const { workflowTemplates } = this.props;
    const { isSearching, isLoading, isLoadingInfiniteScrolling, page, pageSize, workflowFilters, searchString } =
      this.state;

    return (
      <div id='content-container'>
        <BreadcrumbNav icon='home' theme='filled' crumbs={crumbs} isBordered={false} />
        <div className='width-full height-full' style={{ maxWidth: '1152px', margin: '0 auto' }}>
          <div>
            <CreateWorkflowTemplateModal
              onClose={this.closeModal}
              isOpen={this.state.isAddWorkflowModalOpen}
              history={this.props.history}
              onCreateSuccess={this._onRefreshWorkflows}
            />
            <div className='mb-large'>
              <Text>
                Create and manage your incident workflows by setting up the key flows and form components you need.
              </Text>
            </div>
            <div>
              <div className='flex-row justify-between align-baseline mb-large'>
                <div className='flex-row align-baseline'>
                  <Search
                    placeholder='Search for...'
                    onChange={this._onSearchText}
                    loading={isSearching}
                    style={{ width: '240px', height: '32px' }}
                    allowClear={true}
                    className='mr-medium'
                    value={searchString}
                  />
                  <FilterSection
                    availableFilters={availableFilters}
                    filters={workflowFilters}
                    onChangeFilter={this._onChangeFilter}
                    displayTimezone={null}
                    displayMoreFilter={false}
                  />
                </div>
                <CreateWorkflow handleToggleCreateWorkflow={this.toggleModal} />
              </div>
              <div>
                <GridHeader bordered containerClassName='border-width-medium border-secondary'>
                  <Col span={6} className=' bg-white'>
                    <FieldLabel text='Workflow Name' />
                  </Col>
                  <Col span={6} className=' bg-white'>
                    <FieldLabel text='Type' />
                  </Col>
                  <Col span={6} className=' bg-white'>
                    <FieldLabel text='Current Version' />
                  </Col>
                  <Col span={6} className=' bg-white'>
                    <FieldLabel text='Status' />
                  </Col>
                </GridHeader>
                {isLoading ? (
                  <div>
                    <div className='pv-large'>
                      <ProgressBar />
                    </div>
                    <Skeleton active title={true} paragraph={{ rows: 1 }} />
                    <Skeleton active title={true} paragraph={{ rows: 1 }} />
                    <Skeleton active title={true} paragraph={{ rows: 1 }} />
                  </div>
                ) : !_.isEmpty(workflowTemplates) ? (
                  <InfiniteScrollLoading
                    hasMore={workflowTemplates.length >= page * pageSize}
                    loadingElementId='content-container'
                    loadMore={this._fetchMoreWorkflows}
                    loaderColSpan={4}
                    loadingOffSet={60}
                  >
                    {workflowTemplates.map((workflow) => (
                      <GridRow
                        key={workflow.workflowTemplateId}
                        onClick={() => this._onClickRow(workflow.workflowTemplateId)}
                      >
                        <Col span={6}>
                          <Text>{workflow.name}</Text>
                        </Col>
                        <Col span={6}>
                          <Text>{getTriggerTypeWorkflowLabel(workflow.triggerType)}</Text>
                        </Col>
                        <Col span={6}>
                          <Text>{`V${workflow.version}`}</Text>
                        </Col>
                        <Col span={6}>
                          <WorkflowTemplateStatusTags workflowStatus={workflow.status} />
                        </Col>
                      </GridRow>
                    ))}
                  </InfiniteScrollLoading>
                ) : (
                  <ListEmptyState />
                )}
                {isLoadingInfiniteScrolling && (
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowTemplates: state.workflowStore.workflowTemplates,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetWorkflowTemplates: dispatch.workflowStore.doGetWorkflowTemplates,
});

export default connect(mapState, mapDispatch)(WorkflowTemplatesListView);
