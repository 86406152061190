import { Tooltip2 } from '@blueprintjs/popover2';
import { Checkbox, Empty, Input, notification, Skeleton, Tabs } from 'antd';
import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { BottomActionSheet } from 'common-components/Sheets/BottomActionSheet';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { SubTitle, Text, Title } from 'common-components/typography';
import _ from 'lodash';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { FilterType, PaymentSources, ServiceAgreementStatus } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import { ndisHelper } from 'variables/data-helpers';
import ExportLineItemActionModal from 'views/billings/payments/components/ExportLineItemActionModal';
import PreviewInvoicesModal from 'views/billings/payments/components/PreviewInvoicesModal';
import PaymentCustomerRow from '../components/details/PaymentCustomerRow';
import EditLineItemActionModal from '../components/EditLineItemActionModal';
import ProcessPaymentActionModal from '../components/ProcessPaymentActionModal';
import RejectPaymentActionModal from '../components/RejectPaymentActionModal';
import WaiveLineItemActionModel from '../components/WaiveLineItemActionModel';
import { formatFilterQuery, deselectedBillingItems } from './utils';
import type { IPaymentBillingLineItem } from 'interfaces/booking-interfaces';
import type { ViewProps } from 'types/views';
import type { FilterConfig } from 'types/filters';

const MAX_DISCOUNT = 1;
const MIN_DISCOUNT = 0;
const { Search } = Input;

const PaymentEmptyState: React.VFC = () => (
  <div className='align-center flex-column flex-1 bg-white'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Payments found.
    </Text>{' '}
    <br /> <br />
    <Text color='secondary'>All payments under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

type Action =
  | 'REJECT_LINEITEM'
  | 'PROCESS'
  | 'PROCESS_ALL'
  | 'EDIT'
  | 'WAIVE'
  | 'EXPORT'
  | 'PROCESS_ADDITIONAL'
  | 'PREVIEW_ALL';

const getActionModal = (action: Action | undefined) =>
  action === 'REJECT_LINEITEM'
    ? RejectPaymentActionModal
    : action === 'PROCESS' || action === 'PROCESS_ALL'
    ? ProcessPaymentActionModal
    : action === 'EDIT'
    ? EditLineItemActionModal
    : action === 'WAIVE'
    ? WaiveLineItemActionModel
    : action === 'EXPORT'
    ? ExportLineItemActionModal
    : action === 'PROCESS_ADDITIONAL' || action === 'PREVIEW_ALL'
    ? PreviewInvoicesModal
    : null;

export const PayListDetailsSection: React.VFC<{
  history?: ViewProps['history'];
  isServiceProviderVCPEnabled: boolean;
  isNonBillable: boolean;
  currentFilterConfig: FilterConfig;
}> = ({ history, isServiceProviderVCPEnabled, isNonBillable, currentFilterConfig }) => {
  const paymentsList = useAppSelector((s) => s.billingsStore.paymentsList);
  const overviewPayments = useAppSelector((s) => s.billingsStore.overviewPayments);
  const paymentsFilter = useAppSelector((s) => s.billingsStore.paymentsFilter);
  const portalUser = useAppSelector((s) => s.authStore.portalUser);
  const selectedBillingLineItem = useAppSelector((s) => s.billingsStore.selectedBillingLineItem);

  const {
    billingsStore: {
      doFetchWaitingPayments,
      doFetchOverviewWaitingPayments,
      setPaymentsFilter,
      setSelectedBillingLineItem,
      doFetchFundedCategories,
      setPayments,
      doUpdateLineItem,
    },
    customersStore: { doFetchServiceAgreements, doFetchServiceAgreementDetails },
  } = useAppDispatch();

  const [showActionSheet, setShowActionSheet] = useState(false);
  const [checkAllIndicator, setCheckAllIndicator] = useState(false);
  const [indeterminateCheck, setIndeterminateCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOverview, setIsLoadingOverview] = useState(false);
  const loadingOverviewNode = useMemo(() => (isLoadingOverview ? <>&hellip;</> : null), [isLoadingOverview]);
  const [isSearching, setIsSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [pageTimestamp, setPageTimestamp] = useState(() => new Date());
  const [action, setAction] = useState<Action>();
  const [openAction, setOpenAction] = useState(false);

  const [paymentSourceType, setPaymentSourceType] = useState(PaymentSources.NDIS);
  const availableFilters = useMemo<FilterType[]>(
    () =>
      paymentSourceType === PaymentSources.NDIS
        ? [
            FilterType.DATE_RANGE,
            FilterType.CUSTOMER,
            FilterType.PAYMENT_METHODS,
            FilterType.SERVICE,
            FilterType.SERVICE_TYPE,
            FilterType.NDIS_CATEGORIES,
            FilterType.USER_LOCATION_BY_STATE,
            FilterType.PAYMENT_WARNING,
            FilterType.DEBTOR,
          ]
        : [FilterType.DATE_RANGE, FilterType.CUSTOMER],
    [paymentSourceType],
  );

  const [serviceAgreementServiceBillingLineItems, setServiceAgreementServiceBillingLineItems] = useState([]);
  const pageSize = 300;

  useEffect(() => {
    setPaymentsFilter(currentFilterConfig.filters);
    return () => {
      setPaymentsFilter([]);
    };
  }, [setPaymentsFilter, currentFilterConfig.filters]);

  const resetView = useCallback(() => {
    setCheckAllIndicator(false);
    setIndeterminateCheck(false);
    setShowActionSheet(false);
    setPage(1);
    setPayments([]);
  }, [setPayments]);

  useEffect(resetView, [resetView, paymentSourceType]);

  const appliedFilters = useMemo(
    () => (_.isEmpty(paymentsFilter) ? currentFilterConfig.filters : formatFilterQuery(paymentsFilter)),
    [paymentsFilter, currentFilterConfig.filters],
  );

  /**
   * https://goodhuman-me.atlassian.net/browse/NY-2333 - this bug-fix uncovered that the endpoint
   * used by {@link doFetchOverviewWaitingPayments} does not implement any filtering, so the totals
   * in the UI overview row will not reflect the filtering configured underneath it. Therefore the
   * overview only updates based on the selected tab (NDIS/VCS).
   */
  const fetchOverview = useCallback(() => {
    setIsLoadingOverview(true);
    doFetchOverviewWaitingPayments({ paymentSourceType, isNonBillable })
      .then(() => setIsLoadingOverview(false))
      .catch((error) => {
        setIsLoadingOverview(false);
        throw error;
      });
  }, [doFetchOverviewWaitingPayments, paymentSourceType, isNonBillable]);
  useEffect(fetchOverview, [fetchOverview]);

  const fetchPayments = useCallback(() => {
    setIsLoading(true);
    doFetchWaitingPayments({ ...appliedFilters, paymentSourceType, isNonBillable, page, pageTimestamp, pageSize })
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        throw error;
      });
  }, [doFetchWaitingPayments, appliedFilters, paymentSourceType, isNonBillable, page, pageTimestamp]);
  useEffect(fetchPayments, [fetchPayments]);

  const refreshListings = useCallback(() => {
    setPageTimestamp(() => new Date()); // triggers fetchPayments
    fetchOverview();
    resetView();
  }, [resetView, fetchOverview]);

  const fetchMorePayments = useCallback(() => setPage(page + 1), [page]);

  const openActionModal = useCallback(
    async ({ action, additionalData }: { action: Action; additionalData: null | IPaymentBillingLineItem }) => {
      let serviceAgreementServiceBillingLineItems = [];
      if (action === 'REJECT_LINEITEM' || action === 'WAIVE') {
        setSelectedBillingLineItem(additionalData);
      } else if (action === 'EDIT') {
        if (!additionalData) throw new Error('additionalData should be set');
        // TODO: duplicate with BookingBillingPanel
        const newBillingLineItem = _.cloneDeep(additionalData);
        if (paymentSourceType === PaymentSources.NDIS) {
          const ndisSupportItem = ndisHelper.getBySupportItemNumber(newBillingLineItem.supportItemNumber);
          newBillingLineItem.ruleType = ndisSupportItem.DateType;
        }
        newBillingLineItem.isEditing = true;
        newBillingLineItem.supportItemNumberArray = [newBillingLineItem.supportItemNumber];
        newBillingLineItem.claimType = newBillingLineItem.claimType === '' ? 'STD' : newBillingLineItem.claimType;

        setSelectedBillingLineItem(newBillingLineItem);

        // Find userId
        const currentBillingItem = overviewPayments.filter(
          (payment) => payment.bookingBillingLineItemId === newBillingLineItem.bookingBillingLineItemId,
        );
        const { userId } = currentBillingItem[0];
        // Set serviceAgreementServiceBillingLineItems
        try {
          const result = await Promise.all([
            doFetchFundedCategories({ bookingId: additionalData.attendanceId }),
            doFetchServiceAgreements({ userId }),
          ]);

          const serviceAgreements = result[1];
          const activeServiceAgreement = _.find(
            serviceAgreements,
            (agreement) =>
              agreement.status === ServiceAgreementStatus.ACTIVE ||
              agreement.status === ServiceAgreementStatus.EXPIRING_SOON,
          );
          if (activeServiceAgreement) {
            const serviceAgreementDetails = await doFetchServiceAgreementDetails({
              customerUserId: userId,
              serviceAgreementId: activeServiceAgreement.userServiceAgreementId,
            });

            const serviceDetails = _.find(
              serviceAgreementDetails.services,
              (service) => service.serviceId === newBillingLineItem.serviceId,
            );
            serviceAgreementServiceBillingLineItems = serviceDetails ? serviceDetails.lineItems : [];
          }
        } catch (e) {
          notification.error({
            message: 'Oops, something went wrong. Please try again.',
          });
          throw e;
        }
      } else if (action === 'PROCESS_ALL' || action === 'PREVIEW_ALL') {
        const newSelectedBillingLineItem: Record<string, unknown>[] = [];
        paymentsList.forEach((payment) =>
          payment.billingItems?.forEach((lineItem) => {
            if (!lineItem.isError)
              newSelectedBillingLineItem.push({
                firstName: payment.firstName,
                lastName: payment.lastName,
                userId: payment.userId,
                ...lineItem,
              });
          }),
        );
        setSelectedBillingLineItem(newSelectedBillingLineItem);
      } else if (action === 'PROCESS' || action === 'PROCESS_ADDITIONAL') {
        const newSelectedBillingLineItem: Record<string, unknown>[] = [];
        paymentsList.forEach((payment) =>
          payment.billingItems?.forEach((lineItem) => {
            if (lineItem.selected)
              newSelectedBillingLineItem.push({
                firstName: payment.firstName,
                lastName: payment.lastName,
                userId: payment.userId,
                ...lineItem,
              });
          }),
        );
        setSelectedBillingLineItem(newSelectedBillingLineItem);
      }
      setOpenAction(true);
      setAction(action);
      setServiceAgreementServiceBillingLineItems(serviceAgreementServiceBillingLineItems);
    },
    [
      doFetchFundedCategories,
      doFetchServiceAgreementDetails,
      doFetchServiceAgreements,
      overviewPayments,
      paymentSourceType,
      paymentsList,
      setSelectedBillingLineItem,
    ],
  );

  const changeBillableLineItem = useCallback(
    async (lineItem) => {
      try {
        const newBillingLineItem = {
          ...lineItem,
          qty: Number(lineItem.qty),
          total: Number(lineItem.gstValue ? Number(lineItem.unitPrice) * lineItem.qty : lineItem.total),
          travelDistance: Number(lineItem.travelDistance),
          unitPrice: Number(lineItem.unitPrice),
          billingQty: Number(lineItem.billingQty),
          billingPrice: Number(lineItem.billingPrice),
          billingTotal: Number(
            lineItem.billingGstValue ? Number(lineItem.billingPrice) * lineItem.billingQty : lineItem.billingTotal,
          ),
          mileagePrice: Number(lineItem.mileagePrice),
          paymentMethod: lineItem.paymentMethod ?? '',
          supportType: lineItem.supportType ?? '',
          additionalCost: Number(lineItem?.additionalCost) || 0,
        };

        newBillingLineItem.discount =
          !lineItem.discount || Number(lineItem.discount) === MIN_DISCOUNT ? MAX_DISCOUNT : MIN_DISCOUNT;

        if (!newBillingLineItem.paymentMethod && newBillingLineItem.paymentSourceType === PaymentSources.NDIS) {
          delete newBillingLineItem.paymentMethod;
        }
        if (newBillingLineItem.claimType === 'STD') newBillingLineItem.claimType = '';

        const payload = { lineItem: newBillingLineItem, type: 'payment', action: 'billable' };
        await doUpdateLineItem(payload);
        notification.success({
          message: 'Line item updated successfully.',
        });
      } catch (e) {
        notification.error({
          message: 'Oops, something went wrong. Please try again.',
        });
        throw e;
      }
    },
    [doUpdateLineItem],
  );

  const checkCustomer = useCallback(
    (userId) => {
      // Toggle selected
      const newPaymentsList = paymentsList.map((payment) => {
        let isIndeterminate = false;
        if (payment.userId === userId && payment.billingItems) {
          payment.billingItems = payment.billingItems.map((lineItem) => {
            if (!lineItem.isError) {
              return { ...lineItem, selected: !payment.checkAllIndicator };
            }
            if (!payment.checkAllIndicator && !isIndeterminate) isIndeterminate = true;
            return { ...lineItem };
          });

          return { ...payment, checkAllIndicator: !payment.checkAllIndicator, indeterminateCheck: isIndeterminate };
        }
        return { ...payment };
      });

      const someRowsHaveCheck = newPaymentsList.some(
        (b) => b.checkAllIndicator === true || b.indeterminateCheck === true,
      );
      const indeterminateCheck = someRowsHaveCheck
        ? !newPaymentsList.every((b) => b.checkAllIndicator === true)
        : false;
      const checkAllIndicator = someRowsHaveCheck ? newPaymentsList.every((b) => b.checkAllIndicator === true) : false;

      setPayments(newPaymentsList);
      setShowActionSheet(someRowsHaveCheck);
      setIndeterminateCheck(indeterminateCheck);
      setCheckAllIndicator(checkAllIndicator);
    },
    [paymentsList, setPayments],
  );

  const checkItem = useCallback(
    (bookingBillingLineItemId) => {
      // Toggle selected
      const newPaymentsList = paymentsList.map((payment) => {
        const newBillingItems = payment.billingItems?.map((lineItem) => {
          if (lineItem.bookingBillingLineItemId === bookingBillingLineItemId) {
            return { ...lineItem, selected: !lineItem.selected };
          }
          return { ...lineItem };
        });

        const someRowsHaveCheck = newBillingItems?.some((b) => b.selected === true);
        const indeterminateCheck = someRowsHaveCheck && !newBillingItems?.every((b) => b.selected === true);
        const checkAllIndicator = newBillingItems?.every((b) => b.selected === true);

        return { ...payment, billingItems: newBillingItems, someRowsHaveCheck, indeterminateCheck, checkAllIndicator };
      });

      const someRowsHaveCheck = newPaymentsList.some(
        (b) => b.checkAllIndicator === true || b.indeterminateCheck === true,
      );
      const indeterminateCheck = someRowsHaveCheck
        ? !newPaymentsList.every((b) => b.checkAllIndicator === true)
        : false;
      const checkAllIndicator = someRowsHaveCheck ? newPaymentsList.every((b) => b.checkAllIndicator === true) : false;

      setPayments(newPaymentsList);
      setShowActionSheet(someRowsHaveCheck);
      setIndeterminateCheck(indeterminateCheck);
      setCheckAllIndicator(checkAllIndicator);
    },
    [paymentsList, setPayments],
  );

  const onCheckAll = useCallback(() => {
    if (_.isEmpty(paymentsList)) return;

    let isIndeterminate = false;

    const newCheckAll = !checkAllIndicator;
    const newSelectedBookings = paymentsList.map((b) => {
      let isCustomerIndeterminate = false;
      if (b.billingItems?.every((line) => line.isError || line.isNoNDISNumberError)) {
        if (!checkAllIndicator) isIndeterminate = true;
        return { ...b };
      }
      const newBillingItems = b.billingItems?.map((c) => {
        if (c.isError || c.isNoNDISNumberError) {
          if (!b.checkAllIndicator && !isCustomerIndeterminate) isCustomerIndeterminate = true;
          return { ...c };
        }
        return { ...c, selected: newCheckAll };
      });
      if (isCustomerIndeterminate) isIndeterminate = true;
      return {
        ...b,
        billingItems: newBillingItems,
        checkAllIndicator: newCheckAll,
        indeterminateCheck: isCustomerIndeterminate,
      };
    });

    setPayments(newSelectedBookings);
    setCheckAllIndicator(newCheckAll);
    setShowActionSheet(newCheckAll);
    setIndeterminateCheck(isIndeterminate);
  }, [checkAllIndicator, paymentsList, setPayments]);

  const onActionDeselect = useCallback(() => {
    const newSelectedBookings = deselectedBillingItems(paymentsList);
    setShowActionSheet(false);
    setIndeterminateCheck(false);
    setCheckAllIndicator(false);
    setPayments(newSelectedBookings);
  }, [paymentsList, setPayments]);

  const refreshGlobalCheckboxes = useCallback(() => {
    const someRowsHaveCheck = paymentsList.some((b) => b.checkAllIndicator === true || b.indeterminateCheck === true);
    const indeterminateCheck = someRowsHaveCheck && !paymentsList.every((b) => b.checkAllIndicator === true);
    const checkAllIndicator = someRowsHaveCheck && paymentsList.every((b) => b.checkAllIndicator === true);

    setShowActionSheet(someRowsHaveCheck);
    setIndeterminateCheck(indeterminateCheck);
    setCheckAllIndicator(checkAllIndicator);
  }, [paymentsList]);

  const closeActionModal = useCallback(() => {
    refreshGlobalCheckboxes();
    setOpenAction(false);
  }, [refreshGlobalCheckboxes]);

  const applySearchFilter = useCallback(
    (searchStr: string) => {
      const newPlanPaymentsFilter = _.clone(paymentsFilter);
      const existingSearchIndex = _.findIndex(
        newPlanPaymentsFilter,
        (filter: typeof paymentsFilter) => filter.filter === 'search',
      );
      if (existingSearchIndex > -1) {
        if (searchStr === '') {
          newPlanPaymentsFilter.splice(existingSearchIndex, 1);
        } else {
          newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
        }
      } else {
        newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
      }
      setPaymentsFilter(newPlanPaymentsFilter);
      setIsSearching(false);
    },
    [setPaymentsFilter, paymentsFilter],
  );

  const searchText = useCallback(
    (txt: string) => {
      applySearchFilter(txt);
      setIsSearching(false);
    },
    [applySearchFilter],
  );
  const debounceSearch = useMemo(() => _.debounce(searchText, 500), [searchText]);
  const onEnterSearchText: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setIsSearching(true);
      debounceSearch(e.target.value);
    },
    [debounceSearch],
  );

  const TargetActionModal = useMemo(() => getActionModal(action), [action]);

  const filteredOverviewPayments = useMemo(
    () => overviewPayments.filter((payment) => !payment.isFiltered),
    [overviewPayments],
  );

  const canProcess = useMemo(
    () =>
      portalUser
        ? PermissionUtils.validatePermission('ProcessPaymentBillingLineItem', portalUser.permissions.permissionRoles)
        : false,
    [portalUser],
  );

  const selectedLineItem = useMemo(
    () => paymentsList.filter((payment) => payment.billingItems?.some((lineItem) => lineItem.selected)),
    [paymentsList],
  );

  return (
    <div id='scroll' className='width-full flex-column flex-1 bg-white' style={{ position: 'relative' }}>
      {TargetActionModal && (
        <TargetActionModal
          isOpen={openAction}
          onClose={closeActionModal}
          deselect={onActionDeselect}
          selectedBillingLineItem={selectedBillingLineItem}
          paymentSourceType={paymentSourceType}
          serviceAgreementServiceBillingLineItems={serviceAgreementServiceBillingLineItems}
          formatFilterQuery={() => formatFilterQuery(paymentsFilter)}
          isNonBillable={isNonBillable}
        />
      )}

      {/* Do NOT remove this container div. It's required for Safari sticky to work. Why it works, I have no idea.*/}
      <div className='mb-4'>
        <div className='booking-header'>
          {/* Header */}
          <div className='align-center flex-row justify-between'>
            <div>
              <Title level={3} className='mv-none' lineHeight={150}>
                {isNonBillable ? currentFilterConfig.customTitle : currentFilterConfig.title}
              </Title>
              <Text color='secondary'>{currentFilterConfig.description}</Text>
            </div>
            <div className='flex-column align-center'>
              <div className='pr-x-small'>
                <Tooltip2 content='Refresh this view' position='bottom'>
                  <IconButton
                    color='white'
                    onClick={() => refreshListings()}
                    iconColor='black'
                    size='large'
                    icon='reload'
                  />
                </Tooltip2>
              </div>
            </div>
          </div>

          <Tabs
            defaultActiveKey='ALL'
            animated={true}
            onChange={setPaymentSourceType as (k: string) => void}
            className='mt-large'
          >
            <Tabs.TabPane tab={PaymentSources.NDIS} key={PaymentSources.NDIS} />
            {isServiceProviderVCPEnabled && <Tabs.TabPane tab={PaymentSources.VCP} key={PaymentSources.VCP} />}
          </Tabs>

          <div className='p-large bg-quaternary mt-medium mb-large rounded-big' style={{ margin: 'auto' }}>
            <div className='flex-row justify-between'>
              <div className='flex-row'>
                <div style={{ width: '150px' }}>
                  <SubTitle>Bookings</SubTitle>
                  <div className='text-size-x2-large' style={{ lineHeight: '26px' }}>
                    {loadingOverviewNode ?? _(filteredOverviewPayments).groupBy('attendanceId').keys().value().length}
                  </div>
                </div>
                <div style={{ width: '150px' }}>
                  <SubTitle>Customers</SubTitle>
                  <div className='text-size-x2-large' style={{ lineHeight: '26px' }}>
                    {loadingOverviewNode ?? _(filteredOverviewPayments).groupBy('userId').keys().value().length}
                  </div>
                </div>
                <div style={{ width: '200px' }}>
                  <SubTitle>Total</SubTitle>
                  <div className='text-size-x2-large' style={{ lineHeight: '26px' }}>
                    {loadingOverviewNode ??
                      CommonUtils.formatPrice(
                        _.reduce(
                          filteredOverviewPayments,
                          (total, billingLineItem) => {
                            return (
                              Number(total) +
                              Number(billingLineItem.billingTotalWithDiscount ?? billingLineItem.billingTotal)
                            );
                          },
                          0,
                        ),
                      )}
                  </div>
                </div>
              </div>
              {/* Flex stretch doesnt work with ant design Col, couldn't vertical align middle */}
              <div className='text-align-right width-full' style={{ marginTop: '5px' }}>
                {canProcess && (
                  <>
                    <SecondaryButton
                      size='large'
                      disabled={filteredOverviewPayments.length <= 0}
                      className='mv-x-small'
                      onClick={() => openActionModal({ action: 'PREVIEW_ALL', additionalData: null })}
                    >
                      Preview Invoices
                    </SecondaryButton>
                    <PrimaryButton
                      size='large'
                      className='ml-medium mv-x-small'
                      disabled={filteredOverviewPayments.length <= 0}
                      onClick={() => openActionModal({ action: 'PROCESS_ALL', additionalData: null })}
                    >
                      Process All
                    </PrimaryButton>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='pb-medium align-center flex-row justify-between'>
            <div className='mr-x2-large' style={{ minWidth: '300px' }}>
              <Search
                onChange={onEnterSearchText}
                loading={isSearching}
                defaultValue={paymentsFilter.search ? paymentsFilter.search : ''}
                placeholder='Search for customer...'
                size='large'
                allowClear={true}
              />
            </div>
            <FilterSection
              availableFilters={availableFilters}
              filters={paymentsFilter ? paymentsFilter : []}
              onChangeFilter={setPaymentsFilter}
              displayTimezone={portalUser?.timezone}
              containerClassName='mv-small'
            />
          </div>
        </div>

        <table className='payment-listing' role='grid'>
          <thead>
            <tr>
              <th className='nowrap check-all sticky top-0 min-w-[64px]'>
                {canProcess && (
                  <Checkbox onClick={onCheckAll} checked={checkAllIndicator} indeterminate={indeterminateCheck} />
                )}
              </th>
              <th className='nowrap sticky top-0 w-2/6'>
                <SubTitle>Customer</SubTitle>
              </th>
              <th className='nowrap sticky top-0 w-3/6' colSpan={2}>
                <SubTitle>Line Items (Bookings)</SubTitle>
              </th>
              <th className='nowrap text-align-right sticky top-0 w-1/6'>
                <SubTitle containerClassName='text-align-right'>Total</SubTitle>
              </th>
              <th className='nowrap sticky top-0 min-w-[48px]' />
            </tr>
          </thead>

          <tbody>
            {isLoading ? (
              <tr style={{ borderBottom: '0px solid !important' }}>
                <td colSpan={8}>
                  <Skeleton
                    paragraph={{ rows: 5, width: '100%' }}
                    active={true}
                    className='anim-slide-left pt-medium'
                    title={false}
                  />
                </td>
              </tr>
            ) : _.isEmpty(paymentsList) ? (
              <tr style={{ cursor: 'default' }}>
                <td colSpan={8} style={{ borderBottom: '0px solid' }}>
                  <PaymentEmptyState />
                </td>
              </tr>
            ) : (
              <InfiniteScrollLoading
                hasMore={paymentsList ? paymentsList.length >= page * pageSize : false}
                loadingElementId='scroll'
                loadMore={fetchMorePayments}
                loaderColSpan={8}
                loadingOffSet={60}
              >
                {paymentsList.map((paymentItem, index) => (
                  <PaymentCustomerRow
                    paymentItem={paymentItem}
                    onCheckCustomer={checkCustomer}
                    onCheckItem={checkItem}
                    history={history}
                    key={index}
                    openActionModal={openActionModal}
                    canProcess={canProcess}
                    isNonBillable={Boolean(isNonBillable)}
                    changeBillableLineItem={changeBillableLineItem}
                  />
                ))}
              </InfiniteScrollLoading>
            )}
          </tbody>
        </table>
      </div>

      {showActionSheet && selectedLineItem.length > 0 && (
        <BottomActionSheet
          paymentsList={selectedLineItem}
          onDeselect={onActionDeselect}
          refreshPaymentListings={refreshListings}
          openActionModal={openActionModal}
          hasAdditionalButton={true}
          additionalButtonLabel='Preview Invoices'
        />
      )}

      {!isLoading && !showActionSheet && paymentsList.length && (
        <ItemCountSheet itemCount={paymentsList.length} itemLabel='Customer' />
      )}
    </div>
  );
};
