import React, { Component } from 'react';
import { Row, Col, Icon, Radio } from 'antd';
import { Text, Paragraph } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import ndisHelper from 'variables/data/ndis-helper';
import _ from 'lodash';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import FundingUtils from 'utilities/funding-utils';
import Search from 'antd/es/input/Search';
import { Popover } from '@blueprintjs/core';
import { FilterItemMenu } from 'common-components/filter';
import { FilterType } from 'utilities/enum-utils';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';

interface IAddNDISLineItemModalProps {
  closeLineItemModal: () => void;
  isOpen: boolean;
  onChangeSupportItemNumber: (value: string) => void;
  filterFunded?: boolean;
  fundedCategories?: any;
  hasPTNLCLineItems: boolean;
  hasABTLineItems: boolean;
  serviceBillingItems?: any;
  hideWarningFunding?: boolean;
  isFilterOutTravelItem?: boolean;
  serviceAgreementItems?: any[];
  isFilterOutPTNLCLineItem?: boolean;
}

interface IAddNDISLineItemModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  selectLineItem: string;
  isProceedOpen: boolean;
  showNotFunding: boolean;
  isFilterOpen: boolean;
  searchFilter: string;
  isLoadingList: boolean;
  categoriesFilter: any;
  page: number;
  pageSize: number;
  billingLineItemList: any;
}

class AddNDISLineItemModal extends Component<IAddNDISLineItemModalProps, IAddNDISLineItemModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    showNotFunding: false,
    selectLineItem: null,
    isProceedOpen: false,
    isFilterOpen: false,
    searchFilter: null,
    isLoadingList: true,
    categoriesFilter: null,
    page: 1,
    pageSize: 10,
    billingLineItemList: [],
  };

  private _saveLineItem = async () => {
    let isFormValid = true;

    if (!this.state.selectLineItem) {
      isFormValid = false;
    }

    if (isFormValid) {
      this.setState({
        isActionModalOpen: false,
        selectLineItem: null,
        searchFilter: null,
      });
      this.props.onChangeSupportItemNumber(this.state.selectLineItem);
    }
  };

  private _closeLineItemCheckModal = () => {
    const { selectLineItem } = this.state;

    if (selectLineItem) {
      this.setState({ isProceedOpen: true, searchFilter: null });
    } else {
      this._closeLineItemModal();
    }
  };

  private _closeLineItemModal = () => {
    this.setState({ isProceedOpen: false, selectLineItem: null, searchFilter: null });
    this.props.closeLineItemModal();
  };

  private _onChangeSupportItemNumber = (event) => {
    this.setState({ selectLineItem: event });
  };

  private _onEnterSearchText = async (e) => {
    const txt = e.target.value;
    this.setState({ isLoadingList: true, searchFilter: txt }, async () => {
      await this._debounceSearch(txt);
    });
  };

  private _searchText = async (txt) => {
    this.setState({
      billingLineItemList: await ndisHelper.getLineItems(1, 10, txt, this.state.categoriesFilter),
      isLoadingList: false,
      page: 1,
      pageSize: 10,
    });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _saveNewFilterValue = async (filterType, newFilterValue, selectionLabel) => {
    this.setState({
      categoriesFilter: newFilterValue,
      billingLineItemList: await ndisHelper.getLineItems(1, 10, this.state.searchFilter, newFilterValue),
      isLoadingList: false,
    });
  };

  private _setFilterOpen = (nextOpenState) => {
    this.setState({ isFilterOpen: nextOpenState });
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  private _fetchMoreLineItem = async () => {
    this.setState({ isLoadingList: true, page: this.state.page + 1 }, () => {
      const newBillingLineItemList = this.state.billingLineItemList.concat(
        ndisHelper.getLineItems(
          this.state.page,
          this.state.pageSize,
          this.state.searchFilter,
          this.state.categoriesFilter,
        ),
      );
      this.setState({
        billingLineItemList: newBillingLineItemList,
        isLoadingList: false,
      });
    });
  };

  private _isNotInServiceAgreement = (lineItem) => {
    return !_.find(this.props.serviceAgreementItems, {
      supportItemNumber: lineItem.SupportItemNumber,
    });
  };

  componentDidMount = async () => {
    this.setState({
      billingLineItemList: await ndisHelper.getLineItems(this.state.page, this.state.pageSize, null, null),
      isLoadingList: false,
    });
  };

  componentDidUpdate = async (prevProps: Readonly<IAddNDISLineItemModalProps>) => {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        billingLineItemList: await ndisHelper.getLineItems(this.state.page, this.state.pageSize, null, null),
        isLoadingList: false,
      });
    }
  };

  render() {
    const {
      isLoadingList,
      searchFilter,
      categoriesFilter,
      isFilterOpen,
      page,
      pageSize,
      selectLineItem,
      billingLineItemList,
    } = this.state;

    const { isFilterOutTravelItem = false, hideWarningFunding = false, isFilterOutPTNLCLineItem = false } = this.props;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className='mr-medium' size='large' onClick={this._closeProceedModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size='large' onClick={this._closeLineItemModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          title={
            <>
              Add new <b>NDIS Line Item</b>
            </>
          }
          onClose={this._closeLineItemCheckModal}
        >
          <div className='anim-slide-left'>
            <SpinningLoadingActionModel isOpen={this.state.isLoadingFilter} verticalAlignment={'highest'} />
            <Paragraph>Please select a NDIS Line Item to be added to the Service.</Paragraph>

            <Row type={'flex'} align={'middle'}>
              <Search
                onChange={this._onEnterSearchText}
                loading={isLoadingList}
                placeholder='Search for a line item...'
                allowClear
                style={{ width: '300px' }}
                value={searchFilter}
              />
              <Popover
                content={
                  <FilterItemMenu
                    filter={{ filter: FilterType.NDIS_CATEGORIES, values: categoriesFilter, selectionLabel: '' }}
                    saveNewFilterValue={this._saveNewFilterValue}
                    removeFilter={() => false}
                    canRemove={false}
                    displayTimezone={null}
                  />
                }
                popoverClassName={'mb-medium'}
                position={'bottom-left'}
                usePortal={false}
                isOpen={isFilterOpen}
                interactionKind='click'
                onInteraction={(nextOpenState) => this._setFilterOpen(nextOpenState)}
              >
                <div
                  className={`${
                    !isFilterOpen
                      ? 'bg-blue-action-lightest text-color-blue-action'
                      : 'bg-blue-action text-color-white border-blue-action'
                  } mh-medium ph-small pv-x-small inline-block cursor-pointer rounded select-none`}
                >
                  <b>Categories</b>
                  <Icon type={'down'} className={'text-size-small ml-small'} />
                </div>
              </Popover>
            </Row>

            <Row style={{ height: '350px', overflow: 'auto' }} className={'bordered mt-small'} id={'list-item-scroll'}>
              {isLoadingList ? (
                <SpinningLoader size={50} message={'Retrieving line items..'} />
              ) : (
                <InfiniteScrollLoading
                  hasMore={billingLineItemList.length >= page * pageSize}
                  loadingElementId={'list-item-scroll'}
                  loadMore={this._fetchMoreLineItem}
                  loaderColSpan={7}
                  loadingOffSet={60}
                >
                  {_.map(billingLineItemList, (lineItem, key) => {
                    const isDisabled =
                      (this.props.hasPTNLCLineItems && lineItem.DateType === 'PTNLC') ||
                      (this.props.hasABTLineItems && lineItem.DateType === 'ABT') ||
                      (isFilterOutTravelItem && (lineItem.DateType === 'PTNLC' || lineItem.DateType === 'ABT')) ||
                      (isFilterOutPTNLCLineItem && lineItem.DateType === 'PTNLC');
                    const isChecked = selectLineItem === lineItem.SupportItemNumber;
                    const isFunded = FundingUtils.doesLineItemFundingCategoryExistInFundingPackage(
                      lineItem.SupportPurposeType,
                      lineItem.SupportCategoryNumber,
                      lineItem.SupportItemNumber,
                      this.props.fundedCategories,
                    );
                    const isNotInServiceAgreement = this._isNotInServiceAgreement(lineItem);
                    return (
                      <Row
                        className={`pv-small bordered-bottom ${
                          isDisabled ? 'bg-secondary' : 'hover-bg-tertiary cursor-pointer'
                        } ${isChecked && 'bg-blue-lightest'}`}
                        key={key}
                        onClick={() =>
                          !isDisabled ? this._onChangeSupportItemNumber(lineItem.SupportItemNumber) : false
                        }
                      >
                        <Col span={2} className={'text-align-center'}>
                          {!isDisabled && <Radio checked={isChecked} value={lineItem.SupportItemNumber} />}
                        </Col>
                        <Col span={22}>
                          {lineItem.SupportItemNumber}{' '}
                          {isDisabled && !(this.props.isFilterOutTravelItem || isFilterOutPTNLCLineItem) && (
                            <Text weight={'bold'}>(A booking cannot have more than one of this type.)</Text>
                          )}
                          {isDisabled && (this.props.isFilterOutTravelItem || isFilterOutPTNLCLineItem) && (
                            <Text weight={'bold'}>(Cannot change item to this type.)</Text>
                          )}
                          {!hideWarningFunding && !isFunded && isNotInServiceAgreement && (
                            <Text color={'orange'}>(Not funded/in service agreement)</Text>
                          )}
                          {!hideWarningFunding && !isFunded && !isNotInServiceAgreement && (
                            <Text color={'orange'}>(Not funded)</Text>
                          )}
                          {!hideWarningFunding && isFunded && isNotInServiceAgreement && (
                            <Text color={'orange'}>(Not in service agreement)</Text>
                          )}
                          <br />
                          <Text size={'regular'}>{lineItem.SupportItem}</Text>
                        </Col>
                      </Row>
                    );
                  })}
                </InfiniteScrollLoading>
              )}
            </Row>
            <div className={'mb-small mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <Col>
                  <GhostButton size={'large'} onClick={this._closeLineItemCheckModal}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size={'large'} disabled={!selectLineItem} onClick={this._saveLineItem}>
                    Add Line Item
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

export default AddNDISLineItemModal;
