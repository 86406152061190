import { Popover } from '@blueprintjs/core';
import { Col, Icon, notification, Row } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { GridHeader, GridRow } from 'common-components/grids';
import { FieldLabel, SubTitle, Text } from 'common-components/typography';
import { IWorkflow, IWorkflowAction } from 'interfaces/workflow-interfaces';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import EditValueFormModal from 'views/form-builder/components/EditValueFormModal';
import AttachFormModalWrapper from 'views/form-builder/components/AttachFormModal';
import { getStepAvailable } from 'views/workflows/utils/workflow-utils';
import ListingEmptyState from '../../ListingEmptyState';
import LoadingProcess from '../../LoadingProcess';
import DeleteWorkflowFormModal from '../../DeleteWorkflowFormModal';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import { StatusTag } from 'common-components/tags';
import { downloadFileFromUrl } from 'utilities/file-utils';

interface Props {
  selectedWorkflow: IWorkflow;
  selectedWorkflowAction: IWorkflowAction;
  workflowSubmittedForms: typeof state.workflowStore.workflowSubmittedForms;
  setWorkflowSubmittedForms: typeof dispatch.workflowStore.setWorkflowSubmittedForms;
  doGetWorkflowSubmittedForms: typeof dispatch.workflowStore.doGetWorkflowSubmittedForms;
  doCreateSubmittedForm: typeof dispatch.workflowStore.doCreateSubmittedForm;
  doGetWorkflowFormDetail: typeof dispatch.workflowStore.doGetWorkflowFormDetail;
  doDeleteSubmittedForm: typeof dispatch.workflowStore.doDeleteSubmittedForm;
  doUpdateWorkflowForm: typeof dispatch.workflowStore.doUpdateWorkflowForm;
  workflowForm: typeof state.workflowStore.workflowForm;
  isDisableAction: boolean;
  timezone: string;
}

interface State {
  isLoading: boolean;
  editType: string;
  isEditForm: boolean;
  currentForm: any;
}

class WorkflowSubmittedFormPanel extends PureComponent<Props, State> {
  state = {
    isLoading: false,
    editType: '',
    isEditForm: false,
    currentForm: null,
  };

  private _onOpenWorkflowFormModal = (editType: string, form?: any) => {
    this.setState({
      ...this.state,
      editType,
    });
    if (form) {
      this.setState({ currentForm: form });
    }
  };

  private _onCloseWorkflowFormModal = () => {
    this.setState({
      editType: '',
      currentForm: null,
    });
  };

  private _onCreateWorkflowForm = async (
    formId: string,
    elementValues: IElementValue[],
    formName: string,
    formVersionId: string,
    status: string,
  ) => {
    const { doCreateSubmittedForm, selectedWorkflow } = this.props;
    try {
      await doCreateSubmittedForm({
        workflowId: selectedWorkflow.workflowId,
        formVersionId,
        formElement: elementValues,
        status,
      });
      this._onCloseWorkflowFormModal();

      await this._refreshFormList();
      notification.success({
        message: 'Form added',
        description: 'You have successfully added a form to this workflow.',
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _onOpenEditWorkflowFormModal = async (workflowFormId: string, isEdit = false) => {
    const { doGetWorkflowFormDetail } = this.props;
    await doGetWorkflowFormDetail({ workflowFormId });
    this.setState({
      editType: 'edit',
      isEditForm: isEdit,
    });
  };

  private _onEditWorkflowForm = async (id, elementValues, status) => {
    const { doUpdateWorkflowForm } = this.props;
    try {
      await doUpdateWorkflowForm({ workflowFormId: id, isFromNote: false, formData: elementValues, status });
      await this._refreshFormList();
      notification.success({
        message: 'Form updated',
        description: 'You have successfully edited a form.',
      });
    } catch (e) {
      notification.error({ message: 'Update workflow form fail, please try again.', description: e.message });
    }

    this._onCloseWorkflowFormModal();
  };

  private _handleExportPdf = (workflowFormId) => {
    window.open(`/pdf?type=view-form&workflowFormId=${workflowFormId}`, '_blank');
  };

  private _onDeleteWorkflowForm = async (form) => {
    const { doDeleteSubmittedForm } = this.props;
    try {
      await doDeleteSubmittedForm({ workflowFormId: form.workflowFormId });
      await this._refreshFormList();
      notification.success({
        message: 'Form deleted',
        description: 'You have successfully deleted a form from this workspace.',
      });
    } catch (e) {
      notification.error({ message: 'Delete workflow form fail, please try again.', description: e.message });
    }

    this._onCloseWorkflowFormModal();

    await this._refreshFormList();
  };

  private _refreshFormList = async () => {
    const { selectedWorkflow, doGetWorkflowSubmittedForms } = this.props;

    const step = getStepAvailable(selectedWorkflow.steps);
    this.setState({ isLoading: true });

    const payload = {
      workflowId: selectedWorkflow.workflowId,
      workflowStepId: step?.workflowStepId,
    };
    await doGetWorkflowSubmittedForms(payload);

    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    await this._refreshFormList();
  };

  componentWillUnmount = () => {
    this.props.setWorkflowSubmittedForms([]);
  };

  render() {
    const { isLoading, isEditForm, editType, currentForm } = this.state;
    const { selectedWorkflowAction, workflowSubmittedForms, isDisableAction, workflowForm, timezone } = this.props;

    return (
      <div className='bg-white p-x-large'>
        {editType === 'add' && (
          <AttachFormModalWrapper
            isOpen={editType === 'add'}
            onSave={this._onCreateWorkflowForm}
            isAddedFormOnly={false}
            onClose={this._onCloseWorkflowFormModal}
            isWorkflowFormVersion={true}
            isShowOnlyEditModal={true}
            timezone={timezone}
          />
        )}

        {editType === 'edit' && (
          <EditValueFormModal
            isOpen={editType === 'edit'}
            onClose={this._onCloseWorkflowFormModal}
            onSave={this._onEditWorkflowForm}
            formElements={workflowForm.formContent}
            elementValues={workflowForm.formData}
            formId={workflowForm.workflowFormId}
            canEditForm={workflowForm.canEditForm}
            mode={isEditForm ? 'EDIT' : 'VIEW'}
            isEditModal={true}
            timezone={timezone}
          />
        )}

        {editType === 'delete' && (
          <DeleteWorkflowFormModal
            isOpen={editType === 'delete'}
            onClose={this._onCloseWorkflowFormModal}
            onDelete={() => this._onDeleteWorkflowForm(currentForm)}
          />
        )}

        <Row type='flex' align='middle' justify='space-between'>
          <div className='flex-column flex-1'>
            <Text size='x3-large' weight='bolder'>
              Submitted forms
            </Text>
            <Text color='secondary'>View all digital or uploaded form submissions for this workflow.</Text>
          </div>

          {selectedWorkflowAction.canChangeMember && !isDisableAction && (
            <PrimaryButton size='large' icon={'plus'} onClick={() => this._onOpenWorkflowFormModal('add')}>
              Add form
            </PrimaryButton>
          )}
        </Row>

        <div className='mt-large'>
          <GridHeader bordered containerClassName='border-secondary'>
            <Col span={6}>
              <SubTitle containerClassName='line-height-135'>Form name</SubTitle>
            </Col>
            <Col span={5}>
              <SubTitle containerClassName='line-height-135'>Submitted</SubTitle>
            </Col>
            <Col span={5}>
              <SubTitle containerClassName='line-height-135'>Form type</SubTitle>
            </Col>
            <Col span={3}>
              <SubTitle containerClassName='line-height-135'>Status</SubTitle>
            </Col>
            <Col span={4}>
              <SubTitle containerClassName='line-height-135'>Completed by</SubTitle>
            </Col>
            <Col span={1}>
              <FieldLabel text='' />
            </Col>
          </GridHeader>

          {isLoading ? (
            <LoadingProcess />
          ) : workflowSubmittedForms && workflowSubmittedForms.length > 0 ? (
            workflowSubmittedForms.map((form, index) => (
              <GridRow containerClassName='bg-white hover-bg-quaternary' key={index}>
                <Col span={6}>
                  <HyperlinkButton
                    onClick={() =>
                      form.type === 'form'
                        ? this._onOpenEditWorkflowFormModal(form.workflowFormId, false)
                        : downloadFileFromUrl({
                            documentUrl: attachment.url,
                            documentName: attachment.name,
                          })
                    }
                    className='word-break-all'
                  >
                    {form.type === 'attachment' && <Icon type='download' className='text-color-blue-action mr-small' />}
                    {form.formName}
                  </HyperlinkButton>
                </Col>
                <Col span={5} className='break-word'>
                  <Text>
                    {form.isManuallyAdded
                      ? 'Manually added'
                      : form.stepNumber === 0
                      ? 'Trigger Step'
                      : `Step ${form.stepNumber}`}
                  </Text>
                </Col>
                <Col span={5} className='text-capitalize'>
                  <Text>{form.formType.toLocaleLowerCase()} form</Text>
                </Col>
                <Col span={3} className='text-capitalize'>
                  <StatusTag status={form.status} />
                </Col>
                <Col span={4}>
                  <Text>{form.approvedBy}</Text>
                </Col>

                {form && (form.type === 'form' || form.canEdit || form.canDelete) && (
                  <Col span={1} className='p-none'>
                    <Popover
                      content={
                        <ActionMenu>
                          {form.type === 'form' && (
                            <ActionMenuItem
                              text='Export as PDF'
                              onClick={() => this._handleExportPdf(form.workflowFormId)}
                            />
                          )}
                          {form.canEdit && (
                            <ActionMenuItem
                              text='Edit'
                              onClick={() => this._onOpenEditWorkflowFormModal(form.workflowFormId, true)}
                            />
                          )}
                          {form.canDelete && (
                            <ActionMenuItem
                              text='Delete'
                              textClassName='text-color-red'
                              onClick={() => this._onOpenWorkflowFormModal('delete', form)}
                            />
                          )}
                        </ActionMenu>
                      }
                      popoverClassName={'mb-medium'}
                      position={'bottom-right'}
                      interactionKind='click'
                    >
                      <Icon type='ellipsis' className='text-color-blue-action p-x-small bordered cursor-pointer' />
                    </Popover>
                  </Col>
                )}
              </GridRow>
            ))
          ) : (
            <ListingEmptyState title='No submitted form found.' description='All submitted forms will appear here.' />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workflowSubmittedForms: state.workflowStore.workflowSubmittedForms,
  workflowForm: state.workflowStore.workflowForm,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setWorkflowSubmittedForms: dispatch.workflowStore.setWorkflowSubmittedForms,
  doGetWorkflowSubmittedForms: dispatch.workflowStore.doGetWorkflowSubmittedForms,
  doCreateSubmittedForm: dispatch.workflowStore.doCreateSubmittedForm,
  doGetWorkflowFormDetail: dispatch.workflowStore.doGetWorkflowFormDetail,
  doDeleteSubmittedForm: dispatch.workflowStore.doDeleteSubmittedForm,
  doUpdateWorkflowForm: dispatch.workflowStore.doUpdateWorkflowForm,
});

export default connect(mapState, mapDispatch)(WorkflowSubmittedFormPanel);
