import {
  CustomerPermissionType,
  FormBuilderPermissionType,
  PaymentPermissionType,
  PlanManagementPermissionType,
  ServiceDepartmentPermissionType,
  TeamPermissionType,
  UserPermissionRole,
  WorkflowPermissionType,
} from 'utilities/enum-utils';
import { Member, RolePermissionList } from './form-utils';

export const INVITE_MEMBER_ROLE_TYPE = {
  OWNER: 'OWNER',
  CUSTOMER_MANAGER: 'CUSTOMER_MANAGER',
  FINANCE_MANAGER: 'FINANCE_MANAGER',
  ROSTERING_MANAGER: 'ROSTERING_MANAGER',
  TEAM_MEMBER: 'TEAM_MEMBER',
  NO_ACCESS: 'NO_ACCESS',
};

export const PaymentPermissionTypeNumber = {
  [PaymentPermissionType.MEMBER]: {
    level: 1,
    permission: UserPermissionRole.PaymentMember,
  },
  [PaymentPermissionType.MANAGER]: {
    level: 2,
    permission: UserPermissionRole.PaymentManager,
  },
};

export const TeamPermissionTypeNumber = {
  [TeamPermissionType.VIEW_ONLY]: {
    level: 1,
    permission: UserPermissionRole.TeamViewOnly,
  },
  [TeamPermissionType.MANAGE]: {
    level: 2,
    permission: UserPermissionRole.TeamManager,
  },
};

export const CustomerPermissionTypeNumber = {
  [CustomerPermissionType.VIEW_ONLY]: {
    level: 1,
    permission: UserPermissionRole.CustomerAccessViewOnly,
  },
  [CustomerPermissionType.MANAGER]: {
    level: 2,
    permission: UserPermissionRole.CustomerAccessManager,
  },
};

export const FormBuilderPermissionTypeNumber = {
  [FormBuilderPermissionType.ACCESS]: {
    level: 1,
    permission: UserPermissionRole.FormBuilder,
  },
};

export const WorkflowPermissionTypeNumber = {
  [WorkflowPermissionType.BASIC]: {
    level: 1,
    permission: UserPermissionRole.WorkflowBasic,
  },
  [WorkflowPermissionType.ELEVATED]: {
    level: 2,
    permission: UserPermissionRole.WorkflowElevated,
  },
  [WorkflowPermissionType.MANAGER]: {
    level: 3,
    permission: UserPermissionRole.WorkflowManager,
  },
};

export const PlanManagementPermissionTypeNumber = {
  [PlanManagementPermissionType.ACCESS]: {
    level: 1,
    permission: UserPermissionRole.PlanManager,
  },
};

export const ServiceDepartmentPermissionTypeNumber = {
  [ServiceDepartmentPermissionType.MANAGER_OF_ALL_SERVICES]: {
    level: 1,
    permission: UserPermissionRole.ServiceAdmin,
  },
};

export const ROLE_PERMISSION_LIST: RolePermissionList[] = [
  {
    role: INVITE_MEMBER_ROLE_TYPE.OWNER,
    label: 'Owner',
    title: 'Owners',
    description: 'Owners have access to all areas of the GoodHuman workspace and can make administrative changes.',
  },
  {
    role: INVITE_MEMBER_ROLE_TYPE.CUSTOMER_MANAGER,
    label: 'Customer manager',
    subRole: {
      payments: PaymentPermissionType.NO_ACCESS,
      team: TeamPermissionType.VIEW_ONLY,
      customer: CustomerPermissionType.MANAGER,
      formBuilder: FormBuilderPermissionType.NO_ACCESS,
      workflow: WorkflowPermissionType.BASIC,
      planManagement: PlanManagementPermissionType.NO_ACCESS,
      serviceDepartments: ServiceDepartmentPermissionType.NO_ACCESS,
    },
    title: 'Customer managers',
    description: 'Customer managers can view and edit all customer information.',
  },
  {
    role: INVITE_MEMBER_ROLE_TYPE.FINANCE_MANAGER,
    label: 'Finance manager',
    subRole: {
      payments: PaymentPermissionType.MANAGER,
      team: TeamPermissionType.VIEW_ONLY,
      customer: CustomerPermissionType.VIEW_ONLY,
      formBuilder: FormBuilderPermissionType.NO_ACCESS,
      workflow: WorkflowPermissionType.BASIC,
      planManagement: PlanManagementPermissionType.ACCESS,
      serviceDepartments: ServiceDepartmentPermissionType.NO_ACCESS,
    },
    title: 'Finance managers',
    description: 'Finance managers have full access to the Payments and Plan Management areas of GoodHuman.',
  },
  {
    role: INVITE_MEMBER_ROLE_TYPE.ROSTERING_MANAGER,
    label: 'Rostering manager',
    subRole: {
      payments: PaymentPermissionType.NO_ACCESS,
      team: TeamPermissionType.MANAGE,
      customer: CustomerPermissionType.VIEW_ONLY,
      formBuilder: FormBuilderPermissionType.NO_ACCESS,
      workflow: WorkflowPermissionType.BASIC,
      planManagement: PlanManagementPermissionType.NO_ACCESS,
      serviceDepartments: ServiceDepartmentPermissionType.MANAGER_OF_ALL_SERVICES,
    },
    title: 'Rostering managers',
    description: 'Rostering managers have full access to all team member profiles and all services.',
  },
  {
    role: INVITE_MEMBER_ROLE_TYPE.TEAM_MEMBER,
    label: 'Team member',
    subRole: {
      payments: PaymentPermissionType.NO_ACCESS,
      team: TeamPermissionType.VIEW_ONLY,
      customer: CustomerPermissionType.VIEW_ONLY,
      formBuilder: FormBuilderPermissionType.NO_ACCESS,
      workflow: WorkflowPermissionType.BASIC,
      planManagement: PlanManagementPermissionType.NO_ACCESS,
      serviceDepartments: ServiceDepartmentPermissionType.NO_ACCESS,
    },
    title: 'General Team members',
    description: 'General team members have the minimum default access to the GoodHuman workspace.',
  },
  {
    role: INVITE_MEMBER_ROLE_TYPE.NO_ACCESS,
    label: 'No access',
  },
];

export const COLUMN_PROPERTIES = {
  FIRST_NAME: {
    title: 'FIRST NAME',
    dataIndex: 'firstName',
    key: 'firstName',
    width: 200,
    marginLeft: '$space200',
  },
  LAST_NAME: {
    title: 'LAST NAME',
    dataIndex: 'lastName',
    key: 'lastName',
    width: 200,
    marginLeft: '$space200',
  },
  EMAIL: {
    title: 'EMAIL',
    dataIndex: 'email',
    key: 'email',
    width: 224,
    marginLeft: '$space200',
  },
  PHONE: {
    title: 'MOBILE NUMBER',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 192,
    marginLeft: '$space200',
  },
  WORKSPACE_PERMISSIONS: {
    dataIndex: 'permissions',
    key: 'permissions',
    width: 300,
  },
  INVITE_TO_APP: {
    dataIndex: 'hasWorkerAppAccess',
    key: 'hasWorkerAppAccess',
    width: 160,
  },
};

export const ADVANCED_PERMISSION_ROLE_TYPE = {
  PAYMENT: 'PAYMENT',
  TEAM: 'TEAM',
  CUSTOMER: 'CUSTOMER',
  PLAN_MANAGEMENT: 'PLAN_MANAGEMENT',
  FORM_BUILDER: 'FORM_BUILDER',
  WORKFLOW: 'WORKFLOW',
  SUBSCRIPTION: 'SUBSCRIPTION',
};
export const defaultPermissionState = {
  payments: UserPermissionRole.NoAccess,
  team: UserPermissionRole.NoAccess,
  customerAccess: UserPermissionRole.CustomerAccessViewOnly,
  customerView: UserPermissionRole.CustomerViewAll,
  workflow: UserPermissionRole.WorkflowBasic,
  planManagement: UserPermissionRole.NoAccess,
  formBuilder: UserPermissionRole.NoAccess,
  subscription: UserPermissionRole.NoAccess,
};

export const ADVANCED_PERMISSION = 'ADVANCED_PERMISSION';

export const initialState: Member = {
  index: 0,
  memberId: new Date().getTime().toString(),
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  permissions: [],
  hasWorkerAppAccess: true,
  roles: [INVITE_MEMBER_ROLE_TYPE.NO_ACCESS],
  isChecked: false,
  hasPortalAccess: false,
  mobileCountryCode: 'AU',
  isError: false,
  advancedPermissions: null,
  serviceDepartmentPermissions: [],
};

export const OWNER_PERMISSION = 'Owner';

export const LIMIT_MAX_CHARACTERS = 9;

export enum TEAM_MANANGEMENT_TABS {
  TEAM = 'TEAM',
  INVITES = 'INVITES',
  UNINVITED = 'UNINVITED',
}
