import React, { useState } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { TabPane, Tabs } from 'design-components';
import { Text, Title } from 'common-components/typography';
import { ServiceAgreementsTable } from './service-agreements-table/ServiceAgreementsTable';
import { useFetchServiceAgreements } from 'stores/hooks/query-hooks/use-query-fetch-service-agreements';
import { NoServiceAgreements } from './components/NoServiceAgreements';
import { PanelSkeleton } from './components/PanelSkeleton';

export const ServiceAgreementsPanel = (props: { customerId: string }): JSX.Element => {
  const { customerId } = props;
  const { data: serviceAgreements, isLoading } = useFetchServiceAgreements({ customerId });
  const [filteredServiceAgreementIds, setFilteredServiceAgreementIds] = useState(['']);
  const paymentTypeMap = ['NDIS', 'VCP'] as const;

  if (isLoading) {
    return <PanelSkeleton />;
  }

  if (!serviceAgreements?.length) {
    return <NoServiceAgreements />;
  }

  const paymentTabs = serviceAgreements
    .map((sa) => sa.paymentSourceType)
    .filter((value, index, self) => self.indexOf(value) === index);

  const filteredServiceAgreements = serviceAgreements.filter((item) =>
    paymentTabs.length > 1 ? filteredServiceAgreementIds.includes(item.userServiceAgreementId) : item,
  );

  const onSelectionChange = (key: React.Key) => {
    const serviceAgreementIds = serviceAgreements
      .filter((serviceAgreement) => serviceAgreement.paymentSourceType === paymentTypeMap[Number(key)])
      .map((sa) => sa.userServiceAgreementId);

    setFilteredServiceAgreementIds([...serviceAgreementIds]);
  };

  const tabsSection = () => (
    <Tabs onSelectionChange={(key) => onSelectionChange(key)}>
      <TabPane key="0" title="NDIS">
        <></>
      </TabPane>
      <TabPane key="1" title="VCP">
        <></>
      </TabPane>
    </Tabs>
  );

  return (
    <CenteredLayout className="flex-column row-gap-regular">
      <Title level={2} className="mv-none">
        Service Agreements
      </Title>
      <Text color="secondary">Manage service agreements for this customer.</Text>

      {paymentTabs.length > 1 && tabsSection()}
      <ServiceAgreementsTable serviceAgreements={filteredServiceAgreements} />
    </CenteredLayout>
  );
};
