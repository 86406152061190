import { I18nProvider } from '@good/components';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import './integrations/gtm';
import './integrations/i18n/locales/en/index';
import './theme';
import './index.css';
import App from './App';
import Gate from './stores/rematch/components/Gate';
import { ErrorBoundary, GlobalErrorMessage } from './common-components';
import { Favicon } from './config/favicon';
import { NewRelic } from './integrations/new-relic';
import { providerConfig } from './integrations/launch-darkly';
import { WorkspaceApiTrpcProvider } from 'providers/workspace-api-trpc-provider';
import { ReactAriaRouterProvider } from 'providers/react-aria-router-provider';

import { MantineProvider } from '@good/ui/core';
import { Notifications } from '@good/ui/notifications';
import { goodTheme } from '@good/ui/theme';

import '@good/ui/core/styles.css';
import '@good/ui/dates/styles.css';
import '@good/ui/notifications/styles.css';

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Gate>
    <Router>
      <CompatRouter>
        <QueryClientProvider client={queryClient}>
          <LDProvider {...providerConfig}>
            <I18nProvider locale='en-AU'>
              <WorkspaceApiTrpcProvider>
                <Favicon />
                <NewRelic />
                <ErrorBoundary
                  error={() => (
                    <GlobalErrorMessage
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: 'calc(100vh - 80px)',
                        justifyContent: 'center',
                        width: '100vw',
                      }}
                    />
                  )}
                >
                  <MantineProvider theme={goodTheme} forceColorScheme='light'>
                    <ReactAriaRouterProvider>
                      <Notifications />
                      <App />
                    </ReactAriaRouterProvider>
                  </MantineProvider>
                </ErrorBoundary>
              </WorkspaceApiTrpcProvider>
            </I18nProvider>
          </LDProvider>
        </QueryClientProvider>
      </CompatRouter>
    </Router>
  </Gate>,
);
