import { createTRPCReact, CreateTRPCReact, inferReactQueryProcedureOptions } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '@good/functions';

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const reactTrpc: CreateTRPCReact<AppRouter, unknown, null> = createTRPCReact<AppRouter>();

export type BillingSubscription = RouterOutputs['billingSubscriptions']['fetch'];
export type BillingHistory = RouterOutputs['sil']['billingHistory']['fetch'];
export type SilCustomerData = RouterOutputs['sil']['customers']['fetch'];
export type SilRosterData = RouterOutputs['sil']['roster']['fetch'];
export type ServiceDocumentData = RouterOutputs['services']['serviceDocuments']['list'][number];
export type ServiceCustomerDocumentData = RouterOutputs['services']['customerDocuments']['list'][number];
export type ServiceBookingDocumentData = RouterOutputs['services']['bookingDocuments']['list'][number];
export type Service = NonNullable<RouterOutputs['services']['service']['fetch']>;
