import { Text } from '@goodhuman-me/components';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { LIMIT_MAX_CHARACTERS } from 'views/team-v2/utils/constants';
import { Member, MESSAGE_ERRORS, regexMobile } from '../../utils/form-utils';
import { FormComponents } from '../components/FormComponents';

interface IProps {
  memberDetails: Member;
}

const PhoneNumberColumn: React.FC<IProps> = ({ memberDetails }) => {
  const { register, control, trigger } = useFormContext();
  const index = memberDetails.index;

  const validateAuPhoneNumber = (value: string) => {
    if (!value || value === '') return;
    const phoneNumber = parsePhoneNumberFromString(value, 'AU');
    const isValid = phoneNumber?.isValid();
    if (!isValid) {
      return MESSAGE_ERRORS.MOBILE;
    }
  };

  return (
    <FormComponents.TextFieldItem
      name="mobile"
      placeholder="xxx xxx xxx"
      control={control}
      gap={4}
      {...register(`teamMemberList.${index}.mobile` as const, {
        required: MESSAGE_ERRORS.MOBILE,
        pattern: {
          value: regexMobile,
          message: MESSAGE_ERRORS.MOBILE,
        },
        validate: validateAuPhoneNumber,
        onBlur: () => trigger(`teamMemberList.${index}.mobile`),
      })}
      prefix={<Text size="small">+61</Text>}
      maxLength={LIMIT_MAX_CHARACTERS}
    />
  );
};

export default PhoneNumberColumn;
