import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { Icon, Input, notification, Select } from 'antd';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import _ from 'lodash';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CommonUtils from 'utilities/common-utils';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { validateEmailFormat } from 'utilities/validator-utils';
import { type NewRelicBrowser, WithNewRelicBrowser } from 'integrations/new-relic-browser';

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

interface IAddDebtorModalProps {
  isOpen: any;
  onClose: any;
  history: any;
  doAddDebtor: typeof dispatch.accountStore.doAddDebtor;
  newRelicBrowser: NewRelicBrowser;
}

interface IAddDebtorModalState {
  debtorName: string;
  organisationName: string | null;
  debtorNumber: string | null;
  email: string;
  contactNumber: string | null;
  address: string;
  selectedLattitude: number | null;
  selectedLongitude: number | null;
  selectedAddress: any;
  debtorNameError: boolean;
  debtorNameErrorMessage: string | undefined;
  contactNumberError: boolean;
  contactNumberErrorMessage: string | undefined;
  emailError: boolean;
  emailErrorMessage: string | undefined;
  debtorNumberError: boolean;
  debtorNumberErrorMessage: string | undefined;
}

class AddDebtorModal extends Component<IAddDebtorModalProps, IAddDebtorModalState> {
  override state: IAddDebtorModalState = {
    debtorName: '',
    organisationName: null,
    debtorNumber: null,
    email: '',
    contactNumber: null,
    address: '',
    selectedLattitude: null,
    selectedLongitude: null,
    selectedAddress: null,
    debtorNameError: false,
    debtorNameErrorMessage: undefined,
    contactNumberError: false,
    contactNumberErrorMessage: undefined,
    emailError: false,
    emailErrorMessage: undefined,
    debtorNumberError: false,
    debtorNumberErrorMessage: undefined,
  };

  private _resetErrors = () => {
    this.setState({
      debtorNameError: false,
      debtorNameErrorMessage: undefined,
      contactNumberError: false,
      contactNumberErrorMessage: undefined,
      emailError: false,
      emailErrorMessage: undefined,
      debtorNumberError: false,
      debtorNumberErrorMessage: undefined,
    });
  };

  private _onCloseModal = () => {
    this.setState({
      debtorName: '',
      debtorNumber: null,
      email: '',
      contactNumber: null,
      address: '',
      selectedLattitude: null,
      selectedLongitude: null,
      selectedAddress: null,
    });
    this._resetErrors();
    this.props.onClose();
  };

  private _handleChange = (address) => {
    this.setState({ address });
  };

  private _handleSelect = (address) => {
    this.setState({ address: address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          selectedAddress: CommonUtils.createAddressFromGoogleMap(results[0].address_components),
        });
      })
      .catch((error) => console.error('Error', error));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({ selectedLattitude: lat, selectedLongitude: lng }))
      .catch((error) => console.error('Error', error));
  };

  private _validateMobileNumber = (value) => {
    const region = 'AU';

    if (!_.isEmpty(value)) {
      let phoneNumber = parsePhoneNumberFromString(value, region);
      if (phoneNumber === undefined || !phoneNumber || !phoneNumber.isValid()) {
        return false;
      }
    }
    return true;
  };

  private addDebtor = async () => {
    const {
      address,
      selectedLattitude,
      selectedLongitude,
      selectedAddress,
      contactNumber,
      debtorName,
      email,
      debtorNumber,
      organisationName,
    } = this.state;
    const { doAddDebtor, history } = this.props;
    this._resetErrors();
    let hasValidationError = false;

    if (!debtorName) {
      this.setState({ debtorNameError: true, debtorNameErrorMessage: 'Please enter a debtor name' });
      hasValidationError = true;
    }
    if (!email) {
      this.setState({ emailError: true, emailErrorMessage: 'Please enter an email' });
      hasValidationError = true;
    }
    if (email && !validateEmailFormat(email)) {
      this.setState({ emailError: true, emailErrorMessage: 'Please enter a valid email' });
      hasValidationError = true;
    }
    if (!this._validateMobileNumber(contactNumber)) {
      this.setState({ contactNumberError: true, contactNumberErrorMessage: 'Please enter a valid phone number' });
      hasValidationError = true;
    }

    if (hasValidationError) {
      return;
    }

    let debtorAdress = {};
    if (selectedAddress) {
      debtorAdress = { ...selectedAddress, geoLat: selectedLattitude, geoLng: selectedLongitude, fullAddress: address };
    }

    const payload = {
      newDebtor: {
        debtorName: debtorName,
        organisationName: organisationName,
        debtorNumber: debtorNumber,
        debtorContactNumber: contactNumber,
        debtorEmail: email,
        debtorCountryCode: contactNumber ? 'AU' : null,
        debtorAddress: debtorAdress,
      },
      isNewDebtor: true,
    };

    try {
      const newDebtorId = await doAddDebtor(payload);

      history.push(`debtor-management/${newDebtorId}`);
    } catch (e) {
      let capturedKnownError = false;

      if ('meta' in e && 'message' in e.meta) {
        if (e.meta.message.includes('The provided debtor email already exists')) {
          capturedKnownError = true;
          this.setState({ emailError: true, emailErrorMessage: 'The provided debtor email already exists' });
        }

        if (e.meta.message.includes('The provided debtor number already exists')) {
          capturedKnownError = true;
          this.setState({
            debtorNumberError: true,
            debtorNumberErrorMessage: 'The provided debtor number already exists',
          });
        }
      }

      if (!capturedKnownError) {
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
        this.props.newRelicBrowser?.noticeError(e);
      }
    }
  };

  render() {
    const {
      debtorName,
      organisationName,
      debtorNumber,
      email,
      contactNumber,
      debtorNameError,
      debtorNameErrorMessage,
      contactNumberError,
      contactNumberErrorMessage,
      emailError,
      emailErrorMessage,
      debtorNumberError,
      debtorNumberErrorMessage,
    } = this.state;

    return (
      <>
        <ActionModal
          title={<Text className={'text-size-x2-large'}>Add debtor</Text>}
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          width='medium'
        >
          <div className='anim-slide-left'>
            <div>
              <SubTitle>Debtor Name</SubTitle>
              <div className='mt-x2-small'>
                <Input
                  style={{ width: '50%' }}
                  placeholder={'Enter name'}
                  size={'large'}
                  value={debtorName}
                  onChange={(e) => this.setState({ debtorName: e.target.value })}
                  className={debtorNameError ? 'border-red' : ''}
                />
              </div>
              {debtorNameError && (
                <Text size='regular' color='red' className='mt-small'>
                  {debtorNameErrorMessage}
                </Text>
              )}
            </div>
            <div className='mt-medium'>
              <div className='flex'>
                <SubTitle>Organisation Name</SubTitle>
                <Text color='secondary' size='small' className='ml-small'>
                  (Optional)
                </Text>
              </div>
              <div className='mt-x2-small'>
                <Input
                  style={{ width: '50%' }}
                  placeholder={'Enter organisation name'}
                  size={'large'}
                  value={organisationName}
                  onChange={(e) => this.setState({ organisationName: e.target.value })}
                />
              </div>
            </div>
            <div className='mt-medium'>
              <SubTitle>Debtor ID</SubTitle>
              <div className='mt-x2-small'>
                <Input
                  style={{ width: '50%' }}
                  placeholder={'Enter ID'}
                  size={'large'}
                  value={debtorNumber}
                  onChange={(e) => this.setState({ debtorNumber: e.target.value })}
                  className={debtorNumberError ? 'border-red' : ''}
                />
              </div>
              {debtorNumberError && (
                <Text size='regular' color='red' className='mt-small'>
                  {debtorNumberErrorMessage}
                </Text>
              )}
            </div>
            <div className='mt-medium'>
              <SubTitle>Email</SubTitle>
              <div className='mt-x2-small'>
                <Input
                  style={{ width: '50%' }}
                  placeholder={'Enter email'}
                  size={'large'}
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  className={emailError ? 'border-red' : ''}
                />
              </div>
              {emailError && (
                <Text size='regular' color='red' className='mt-small'>
                  {emailErrorMessage}
                </Text>
              )}
            </div>

            <div className='mt-medium'>
              <div className='flex'>
                <SubTitle>Contact Number</SubTitle>
                <Text color='secondary' size='small' className='ml-small'>
                  (Optional)
                </Text>
              </div>
              <div className='flex-row mt-x2-small'>
                <Select disabled size='large' className='mr-small' style={{ width: '110px' }} defaultValue='AU'>
                  <Select.Option value='AU'>+61 (AU)</Select.Option>
                </Select>

                <Input
                  size={'large'}
                  placeholder={'Enter number'}
                  style={{ width: '200px' }}
                  value={contactNumber}
                  onChange={(e) => this.setState({ contactNumber: e.target.value })}
                  className={contactNumberError ? 'border-red' : ''}
                />
              </div>
              {contactNumberError && (
                <Text size='regular' color='red' className='mt-small'>
                  {contactNumberErrorMessage}
                </Text>
              )}
            </div>
            <div className='mt-medium mb-large'>
              <div className='flex'>
                <SubTitle>Address</SubTitle>
                <Text color='secondary' size='small' className='ml-small'>
                  (Optional)
                </Text>
              </div>
              <div className={'mt-x2-small'}>
                <PlacesAutocomplete
                  value={this.state.address}
                  onSelect={this._handleSelect}
                  onChange={this._handleChange}
                  shouldFetchSuggestions={this.state.address.length > 2}
                  searchOptions={searchAutocompleteOptions}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <Input
                        value={this.state.address}
                        size={'large'}
                        {...getInputProps({
                          placeholder: 'Search for an address ...',
                          className: 'location-search-input',
                        })}
                      />
                      <div className='autocomplete-dropdown-container'>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                          const style = suggestion.active
                            ? {
                                backgroundColor: '#fafafa',
                                cursor: 'pointer',
                                borderTop: '1px ridge grey',
                                borderLeft: '1px ridge grey',
                                borderRight: '1px ridge grey',
                                padding: '10px',
                              }
                            : {
                                backgroundColor: '#ffffff',
                                cursor: 'pointer',
                                borderTop: '1px ridge grey',
                                borderLeft: '1px ridge grey',
                                borderRight: '1px ridge grey',
                                padding: '10px',
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>
                                <Icon type={'environment'} /> {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </div>

            <ActionModalFooter>
              <SecondaryButton size='large' className='mr-medium' onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size='large' onClick={() => this.addDebtor()}>
                Create debtor
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </ActionModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddDebtor: dispatch.accountStore.doAddDebtor,
});

export default WithNewRelicBrowser(connect(mapState, mapDispatch)(AddDebtorModal));
