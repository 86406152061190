import { Col, Input, message, Row, Typography } from 'antd';
import React, { Component } from 'react';

import { Card, ProgressBar } from '@blueprintjs/core';
import { logoBlack } from 'assets/logo';

import { backgroundSVG, checkmarkSVG, signupGraphicSVG } from 'assets/onboarding';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import { IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import CenteredLayout from '../../layouts/CenteredLayout';

import './css/register-onboarding.css';

class RegisterView extends Component<any, any> {
  state = {
    login: '',
    password: '',
    isLoggingIn: false,
    loading: false,
    mobile: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  };

  // Simple state handler
  _handleInput = (target, value) => {
    const targetObj = {};
    targetObj[target] = value;
    this.setState(targetObj);
  };

  _onClickLogin = async () => {
    if (!this.checkFormValidation()) {
      message.error('Please fill in the required fields.');
      return;
    }

    if (this.state.password === this.state.confirmPassword) {
      const dismissMessage = message.loading('Registering ...');
      this.setState({ isLoggingIn: true });

      try {
        const { doRegister, doFetchPortalUserList, setCurrentPortalUser, invitationItem } = this.props;

        // TODO : hardcoded mobile number for now
        const payload = {
          email: invitationItem.email,
          password: this.state.password,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          token: invitationItem.token,
          serviceProviderId: invitationItem.serviceProviderId,
          mobile: this.state.mobile,
        };

        const currentUser = await doRegister(payload);

        const result = await doFetchPortalUserList();

        if (_.isEmpty(result)) {
          dismissMessage();
          void message.error(
            'You are not authorized to login to any service provider. Please contact your administrator.',
          );
          this.setState({ isLoggingIn: false });
        } else {
          this.setState({ isLoggingIn: false }, () => {
            // TODO This doesn't look very safe.
            dismissMessage();
            setCurrentPortalUser(result[0]);

            void message.success(`Sign in successful for ${currentUser.email}.`, 2);
            this.props.history.push('/home');
          });
        }
      } catch (err) {
        dismissMessage();
        this.setState({ isLoggingIn: false });
        void message.error(err.message);
      }
    } else {
      message.error('Password and Confirm Password should be same');
    }
  };

  async componentDidMount() {
    const { location, doCheckVerify, doSignOutUsers } = this.props;

    this.setState({ loading: true });
    const token = new URLSearchParams(location.search).get('token');

    const data = await doCheckVerify({ token: token });
    if (data.token) {
      await doSignOutUsers();
      this.setState({ firstName: data.firstName, lastName: data.lastName, loading: false });
    }
    this.setState({ loading: false });
  }

  checkFormValidation = () => {
    if (this.state.firstName.length < 1) {
      return false;
    }

    if (this.state.lastName.length < 1) {
      return false;
    }

    if (this.state.mobile.length < 9) {
      return false;
    }

    if (this.state.password.length < 8) {
      return false;
    }

    if (this.state.confirmPassword.length < 8) {
      return false;
    }

    if (this.state.confirmPassword !== this.state.password) {
      return false;
    }

    return true;
  };

  render() {
    // const { authStore } = this.props;
    const { invitationItem } = this.props;

    const isValid = this.checkFormValidation();

    if (this.state.loading) {
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card style={{ width: '25%', textAlign: 'center' }} className='anim-fade-in'>
            <div style={{ marginBottom: '16px' }}>
              <Typography.Text style={{ fontSize: '16px' }}>Loading Invitation...</Typography.Text>
            </div>
            <ProgressBar animate={true} />
          </Card>
        </div>
      );
    }

    if (_.isEmpty(invitationItem)) {
      return <ErrorStateView />;
    }

    return (
      <CenteredLayout maxWidth={1152} height='100vh' topPadded={false}>
        <section id='top-logo' className='pt-large pb-large'>
          <img src={logoBlack} alt='' style={{ height: '20px' }} className='ml-medium' />
        </section>

        <Row gutter={32}>
          <Col span={12}>
            <div
              className='flex-1 flex-column mh-medium sign-in-anim-4'
              style={{
                height: 'calc(100vh - 88px)',
                backgroundImage: `url(${backgroundSVG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
            >
              <div className='pt-x5-large ph-x5-large'>
                <div className='pb-x-large sign-in-anim-1'>
                  <Text className='text-size-x4-large'>Love your customers</Text> <br />
                  <Text className='text-size-x4-large'>Inspire your team</Text> <br />
                </div>
                <div className='pb-x-large inline-block sign-in-anim-2'>
                  <div className='pb-x-small flex-row align-center'>
                    <img src={checkmarkSVG} alt='' className='mr-small' />
                    <Text size='x2-large'>NDIS made simple</Text>
                  </div>

                  <div className='pb-x-small flex-row align-center'>
                    <img src={checkmarkSVG} alt='' className='mr-small' />
                    <Text size='x2-large'>Get paid faster</Text>
                  </div>

                  <div className='pb-x-small flex-row align-center'>
                    <img src={checkmarkSVG} alt='' className='mr-small' />
                    <Text size='x2-large'>Connect with your community</Text>
                  </div>
                </div>

                <img src={signupGraphicSVG} className='sign-in-anim-3' alt='sign-up' />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className='pr-large anim-slide-left' style={{ height: 'calc(100vh - 72px)', overflowY: 'auto' }}>
              <section id='welcome' className='pb-large'>
                <div className='mb-small'>
                  <Text size='x4-large'>
                    {/* TODO : First Name */}
                    Welcome, <span className='text-weight-bold'>{invitationItem.firstName}</span>!
                  </Text>
                </div>

                <Text>
                  You&apos;ve been invited to join <span className='text-weight-bold'>The Bridge</span> on{' '}
                  <span className='text-weight-bold'>GoodHuman</span>.
                </Text>
              </section>

              <section id='user-form' className='pb-x4-large'>
                <Row gutter={16} className='pb-x-large'>
                  <Col span={12}>
                    <SubTitle>First Name</SubTitle>
                    {/* TODO : First Name */}
                    <Input
                      size='large'
                      style={{ marginBottom: -1 }}
                      placeholder='First Name'
                      value={invitationItem.firstName}
                      onChange={(e) => this._handleInput('firstName', e.target.value)}
                    />
                  </Col>
                  <Col span={12}>
                    <SubTitle>Last Name</SubTitle>
                    {/* TODO :  Last Name */}
                    <Input
                      size='large'
                      style={{ marginBottom: -1 }}
                      placeholder='last name'
                      value={invitationItem.lastName}
                    />
                  </Col>
                </Row>
                {/* No preferred name for now */}
                {/*<Row gutter={16} className="pb-large">*/}
                {/*  <Col span={12}>*/}
                {/*    <SubTitle>Preferred Name (Optional)</SubTitle>*/}
                {/*    /!* TODO :Preferred Name *!/*/}
                {/*    <Input size="large" />*/}
                {/*  </Col>*/}
                {/*  <Col span={12} />*/}
                {/*</Row>*/}

                <Row gutter={16} className='pb-large'>
                  <Col span={24}>
                    <SubTitle>Mobile Number</SubTitle>

                    <div className='flex-row'>
                      <Input
                        size='large'
                        disabled={true}
                        value='+61 (AU)'
                        style={{ width: '100px' }}
                        className='mr-medium'
                      />

                      {/* TODO :Preferred Name */}
                      <Input
                        size='large'
                        style={{ marginBottom: -1 }}
                        placeholder='Mobile number (4xxxxxxxx)'
                        type='mobile'
                        value={this.state.mobile}
                        onChange={(e) => this._handleInput('mobile', e.target.value)}
                        // prefix={<Icon type={'phone'} />}
                      />
                    </div>
                  </Col>
                  <Col span={12} />
                </Row>

                <Row className='pb-x-large'>
                  <Col span={24}>
                    <SubTitle>Login</SubTitle>
                    <div className='pb-x-small'>
                      <Text size='regular' color='secondary'>
                        Use this email to login. This has been assigned by The Bridge.
                      </Text>
                    </div>

                    {/* TODO :Login Name */}
                    <Input
                      size='large'
                      style={{ marginBottom: -1 }}
                      placeholder='Enter your email here'
                      disabled
                      value={invitationItem.email}
                      onChange={(e) => this._handleInput('lastName', e.target.value)}
                    />
                  </Col>
                </Row>
                <Row gutter={0}>
                  <Col span={24}>
                    <SubTitle>Password</SubTitle>
                    <div className='pb-x-small'>
                      <Text size='regular' color='secondary'>
                        Must contain at least one uppercase letter, one lowercase letter and one number. Minimum 8
                        characters.
                      </Text>
                    </div>

                    <Row gutter={16}>
                      <Col span={12}>
                        {/* TODO :Password */}
                        <Input.Password
                          size='large'
                          placeholder='Password'
                          // value={this.state.password}
                          onChange={(e) => this._handleInput('password', e.target.value)}
                          // prefix={<Icon type={'lock'} />}
                        />
                      </Col>
                      <Col span={12}>
                        {/* TODO :Confirm password */}
                        <Input.Password
                          size='large'
                          placeholder='Confirm Password'
                          // value={this.state.password}
                          onChange={(e) => this._handleInput('confirmPassword', e.target.value)}
                          // prefix={<Icon type={'lock'} />}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </section>

              <section id='buttons'>
                <div className='flex-col small'>
                  {/* Sign In Button */}
                  <div
                    className={`bg-blue-darker text-align-center mb-medium rounded-full ${
                      !isValid ? 'dimmer cursor-not-allowed' : 'hover-undim cursor-pointer'
                    }`}
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                    onClick={this._onClickLogin}
                  >
                    <Text className='text-color-white text-weight-bold text-size-x-large'>Create my account</Text>
                  </div>

                  {/* Already have an account button */}
                  <div
                    className='border-blue bordered  hover-undim text-align-center  mb-large rounded-full cursor-pointer bg-white'
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                    onClick={() => {
                      this.props.history.push('/login');
                    }}
                  >
                    <Text className='text-color-blue-darker text-weight-bold text-size-x-large'>
                      I already have an account
                    </Text>
                  </div>
                </div>

                <div className='text-align-center pb-x2-large'>
                  <Text size='regular' color='tertiary'>
                    By creating an account, you agree to the GoodHuman Terms of Service.
                  </Text>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  authStore: state.authStore,
  portalUserList: state.authStore.portalUserList,
  invitationItem: state.authStore.invitation,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  // doIncrement: dispatch.userStore.incrementAsync
  doSignOutUsers: dispatch.authStore.doSignOutUsers,
  doRegister: dispatch.authStore.doRegister,
  doFetchPortalUserList: dispatch.authStore.doFetchPortalUserList,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  doCheckVerify: dispatch.authStore.doCheckValidateToken,
});

const connectStateDispatch = connect(mapState, mapDispatch);

export default compose(connectStateDispatch)(RegisterView);

const ErrorStateView = () => (
  <CenteredLayout maxWidth={1152} height='100vh' topPadded={false}>
    <section id='top-logo' className='pt-large pb-large'>
      <img src={logoBlack} alt='' style={{ height: '20px' }} className='ml-medium' />
    </section>

    <Row gutter={32}>
      <Col span={12}>
        <div
          className='flex-1 flex-column mh-medium'
          style={{
            height: 'calc(100vh - 88px)',
            backgroundImage: `url(${backgroundSVG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        >
          <div className='pt-x5-large ph-x5-large'>
            <div className='pb-x-large'>
              <Text className='text-size-x4-large'>Love your customers</Text> <br />
              <Text className='text-size-x4-large'>Inspire your team</Text> <br />
            </div>
            <div className='pb-x-large inline-block'>
              <div className='pb-x-small flex-row align-center'>
                <img src={checkmarkSVG} alt='' className='mr-small' />
                <Text size='x2-large'>NDIS made simple</Text>
              </div>

              <div className='pb-x-small flex-row align-center'>
                <img src={checkmarkSVG} alt='' className='mr-small' />
                <Text size='x2-large'>Get paid faster</Text>
              </div>

              <div className='pb-x-small flex-row align-center'>
                <img src={checkmarkSVG} alt='' className='mr-small' />
                <Text size='x2-large'>Connect with your community</Text>
              </div>
            </div>

            <img src={signupGraphicSVG} alt='sign-up' />
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div style={{ height: '80vh' }} className='flex-column justify-center align-center'>
          <div className='pb-x-large'>
            <Text className='text-size-x3-large text-weight-bold text-color-cobalt-dark'>
              Oops! Something went wrong.
            </Text>
          </div>
          <Text className='text-size-x2-large text-align-center'>
            Please check with the <span className='text-weight-black'>GoodHuman</span> team, <br /> and they&apos;ll
            help sort this out for you.
          </Text>
        </div>
      </Col>
    </Row>
  </CenteredLayout>
);
