import React from 'react';
import { Link } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { Logo } from '@good/components';
import { Group, Box, Flex, ActionIcon, type ActionIconProps, type FlexProps } from '@good/ui/core';
import { EnvTag } from './env-tag';
import { FrillWidgetIcon } from 'common-components/navigation/frill-widget-icon';
import { Profile } from './profile';
import { NotificationsButton } from './notifications-button';
import { MessagesButton } from './messages-button';
import { useIsMobile } from 'utilities/react-hooks/use-is-mobile';

type ToggleSidebarButtonProps = ActionIconProps & {
  onClick: () => void;
  opened: boolean;
};

const ToggleSidebarButton = ({ opened, onClick, ...actionIconProps }: ToggleSidebarButtonProps) => {
  return (
    <ActionIcon variant='white' color='gray.8' onClick={onClick} {...actionIconProps}>
      {opened ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
    </ActionIcon>
  );
};

type HeaderProps = FlexProps & {
  navbarOpenDesktop: boolean;
  navbarOpenMobile: boolean;
  toggleNavbarOpenDesktop: () => void;
  toggleNavbarOpenMobile: () => void;
};

export const Header = ({
  navbarOpenDesktop,
  navbarOpenMobile,
  toggleNavbarOpenDesktop,
  toggleNavbarOpenMobile,
  ...flexProps
}: HeaderProps) => {
  const isMobile = useIsMobile();

  return (
    <Flex align='center' justify='space-between' data-testid='header-root' {...flexProps}>
      <Group>
        <ToggleSidebarButton hiddenFrom='sm' opened={navbarOpenMobile} onClick={toggleNavbarOpenMobile} />
        <ToggleSidebarButton visibleFrom='sm' opened={navbarOpenDesktop} onClick={toggleNavbarOpenDesktop} />
        <Box w={isMobile ? 26 : 102}>
          <Link to='/home'>
            <Logo size={isMobile ? 'sm' : 'md'} />
          </Link>
        </Box>
        <EnvTag />
      </Group>
      <Box>
        <Group gap={24} wrap='nowrap'>
          <Group gap={8} wrap='nowrap'>
            <MessagesButton />
            <NotificationsButton />
            <FrillWidgetIcon />
          </Group>
          <Profile />
        </Group>
      </Box>
    </Flex>
  );
};
