import { Divider, Icon, Popover } from 'antd';
import { Paragraph, Text } from 'common-components/typography';
import { ISubscriptionOverview } from 'interfaces/subscription-interfaces';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { SubscriptionStatus } from 'utilities/enum-utils';
import { IRootState } from 'stores/rematch/root-store';

interface IActivityReportsCardProps {
  className?: string;
  subscriptionOverview: ISubscriptionOverview;
}

const ActivityReportsCard: React.FC<IActivityReportsCardProps> = ({ className, subscriptionOverview }) => {
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);
  const { activityInfo, estimatedCostInfo, subscriptionInfo } = subscriptionOverview;

  const pausedSubscription = subscriptionInfo?.subscriptionStatus === SubscriptionStatus.PAUSED;
  const cancelledSubscription = subscriptionInfo?.subscriptionStatus === SubscriptionStatus.CANCELED;
  const inactiveSubscription = pausedSubscription || cancelledSubscription;

  const inactiveSubscriptionContentPopover = (
    <div className="p-12" style={{ width: '390px' }}>
      <Text weight="bolder" size="large">
        No cost breakdown available
      </Text>
      <Paragraph className="mt-medium">
        Your subscription has been cancelled and you will not be charged in the next billing cycle.
      </Paragraph>
    </div>
  );

  const questionContentPopover = (
    <div className="p-12" style={{ width: '390px' }}>
      <Text weight="bolder" size="regular">
        Estimated cost breakdown
      </Text>
      <div className="flex mv-medium justify-between">
        <div>
          <Text size="regular">Minimum fee</Text>
          <Text color="gray-dark" size="regular" className="block">
            Up to 10 active users
          </Text>
        </div>
        <Text size="regular">$250</Text>
      </div>
      <div className="flex justify-between">
        <div>
          <Text size="regular">{estimatedCostInfo?.upcomingQty} x active users</Text>
          <Text color="gray-dark" size="regular" className="block">
            $25 per user
          </Text>
        </div>
        <Text size="regular">${Number(estimatedCostInfo?.upcomingQty) * 25}</Text>
      </div>
      {estimatedCostInfo?.tax && (
        <div className="flex justify-between flex mv-medium">
          <div>
            <Text size="regular">Tax</Text>
            <Text color="gray-dark" size="regular" className="block">
              GST ({estimatedCostInfo.tax_percent}%)
            </Text>
          </div>
          <Text size="regular">${Number(estimatedCostInfo.tax)}</Text>
        </div>
      )}

      <Divider style={{ margin: '12px 0' }} />

      <div className="flex justify-between">
        <Text weight="bolder" size="regular">
          EST. TOTAL
        </Text>
        <Text weight="bolder" size="regular">
          ${estimatedCostInfo?.estTotal}
        </Text>
      </div>
    </div>
  );

  return (
    <div className={`p-x-large bordered rounded select-none ${className ?? ''} `} style={{ minHeight: '206px' }}>
      <Text size="x2-large" className="mt-small" weight="bolder">
        Activity this month
      </Text>

      <Divider style={{ margin: '24px 0 15px' }} />

      <div className="flex justify-between">
        <div>
          <Text size="regular" className="inline-block mb-12">
            Next payment due
          </Text>
          <div>
            {inactiveSubscription
              ? 'N/A'
              : moment.tz(activityInfo?.nextPaymentDue, portalUser?.timezone).format('D MMM YYYY')}
          </div>
        </div>
        <div>
          <Text size="regular" className="inline-block mb-12">
            Active users
          </Text>
          <div>
            {activityInfo?.activeTotalUser} user{activityInfo?.activeTotalUser !== 1 ? 's' : ''}
          </div>
        </div>
        <div>
          <Text size="regular" className="inline-block mb-12">
            Estimated bill
          </Text>
          <div className="flex align-center">
            <Text>
              ${inactiveSubscription || !activityInfo?.estimatedTotalBill ? '0.00' : activityInfo?.estimatedTotalBill}
            </Text>
            &nbsp;
            <Popover
              content={inactiveSubscription ? inactiveSubscriptionContentPopover : questionContentPopover}
              placement="bottomRight"
              overlayClassName="rounded-big"
            >
              <Icon type="question-circle" style={{ fontSize: '13px' }} />
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityReportsCard;
