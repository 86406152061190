import { HyperlinkButton } from 'common-components/buttons';
import { Title } from 'common-components/typography';
import React from 'react';

const SupportCard: React.FC = () => {
  return (
    <div className="flex justify-left align-center bordered rounded select-none ph-x2-large" style={{ minHeight: 128 }}>
      <Title level={4} className="mv-none mr-large" weight="bolder">
        Get support
      </Title>
      <HyperlinkButton onClick={() => window.open('https://goodhuman.zendesk.com/hc/en-au/requests/new', '_blank')}>
        Message us, we&apos;re here to help
      </HyperlinkButton>
    </div>
  );
};

export default SupportCard;
