import React, { Component } from 'react';
import { SubTitle, Text } from 'common-components/typography';
import ServiceListItem from '../components/ServiceListItem';
import { IServiceListItem } from 'interfaces/service-interfaces';
import '../css/new-service-listing.css';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import _ from 'lodash';
import { Empty, Skeleton } from 'antd';
import { ServiceType } from 'utilities/enum-utils';

interface ServiceListItemsState {}

interface ServiceListItemsProps {
  filteredServiceList: IServiceListItem[];
  fetchMoreServices: any;
  page: number;
  pageSize: number;
  setSelectedServiceId: (selectedServiceId: string, serviceType: ServiceType) => void;
  isLoading: boolean;
  isLoadingMore: boolean;
}

const ServiceEmptyState = () => (
  <tr>
    <td colSpan={4}>
      <div className={'text-align-center mt-x-large'}>
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className='mv-none' />
        <Text size='x2-large' color='secondary' weight='bold'>
          No services found.
        </Text>
        <br />
        <Text color='secondary'>All services under this filter will appear here.</Text>
      </div>
    </td>
  </tr>
);

class ServiceListItemsSection extends Component<ServiceListItemsProps, ServiceListItemsState> {
  _handleItemClick = (serviceId, serviceType) => {
    const { setSelectedServiceId } = this.props;
    setSelectedServiceId(serviceId, serviceType);
  };

  render() {
    const { filteredServiceList } = this.props;
    return (
      <div className='height-full bg-white' style={{ position: 'relative' }} id='content-container'>
        <table className='service-listing'>
          <thead>
            <tr>
              <th className='nowrap p-small pl-medium' style={{ top: '0px' }}>
                <SubTitle>Service name</SubTitle>
              </th>
              <th className='nowrap width-1/6' style={{ top: '0px' }}>
                <SubTitle>Status</SubTitle>
              </th>
              <th className='nowrap width-1/6' style={{ top: '0px' }}>
                <SubTitle>Service type</SubTitle>
              </th>
              <th className='nowrap width-1/6' style={{ top: '0px' }}>
                <SubTitle>Department</SubTitle>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.isLoading ? (
              <tr>
                <td colSpan={4}>
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                </td>
              </tr>
            ) : (
              <InfiniteScrollLoading
                hasMore={filteredServiceList.length >= this.props.page * this.props.pageSize}
                loadingElementId={'content-container'}
                loadMore={this.props.fetchMoreServices}
                loaderColSpan={4}
                loadingOffSet={60}
              >
                {filteredServiceList.length > 0 ? (
                  _.map(filteredServiceList, (item) => (
                    <ServiceListItem key={item.serviceId} handleItemClick={this._handleItemClick} data={item} />
                  ))
                ) : (
                  <ServiceEmptyState />
                )}
              </InfiniteScrollLoading>
            )}
            {/* {this.props.isLoadingMore && (
              <tr style={{ borderBottom: '0px solid !important' }}>
                <td colSpan={4}>
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ServiceListItemsSection;
