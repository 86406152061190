import React from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import SpinningLoader from 'common-components/loading/SpinningLoader';

/**
 * Placeholder Loader until we decide how we wanting loading to
 * look. This should be updated later on down the track when we
 * get further input from design and product.
 */
export const Loader = () => (
  <CenteredLayout backgroundColor="white" bordered={true} className="ph-small">
    <SpinningLoader size={100} message="Fetching service details" />
  </CenteredLayout>
);
