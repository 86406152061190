import React from 'react';

import { reactTrpc } from '../../utilities/react-trpc';
import CenteredLayout from '../../layouts/CenteredLayout';

export function TestTrpcView() {
  const { data: ping } = reactTrpc.health.ping.useQuery();
  const { data: whoAmI } = reactTrpc.health.whoAmI.useQuery();

  return (
    <CenteredLayout backgroundColor='white' bordered={true}>
      <div className='mb-4'>
        <h1 className='text-b-xl text-weight-bold'>Ping</h1>
        <div>
          <span className='text-weight-bold'>Ping - </span> <span>{ping?.message}</span>
        </div>
      </div>

      <div>
        <h1 className='text-b-xl text-weight-bold'>Who Am I</h1>
        <div>
          <span className='text-weight-bold'>Display name - </span> <span>{whoAmI?.displayName}</span>
        </div>
        <div>
          <span className='text-weight-bold'>Email - </span> <span>{whoAmI?.email}</span>
        </div>
      </div>
    </CenteredLayout>
  );
}
