/* eslint-disable */
import { Popover as PopoverBlueprint, ProgressBar } from '@blueprintjs/core';
import { Message } from '@goodhuman-me/components';
import { Alert, Avatar, Checkbox, Col, Empty, Form, Icon, Input, Popover, Skeleton, Tabs } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { HyperlinkButton, IconButton, SecondaryButton } from 'common-components/buttons';
import CustomViewsModal from 'common-components/custom-views/modals/CustomViewsModal';
import CustomViewPopover from 'common-components/custom-views/popover/CustomViewPopover';
import { FilterSection } from 'common-components/filter';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import PinnedAlertIcon from 'common-components/pinned-alerts/PinnedAlertIcon';
import CustomerConnectionStatusTag from 'common-components/tags/CustomerConnectionStatusTags';
import CustomerStatusTags from 'common-components/tags/CustomerStatusTags';
import PaymentSourceTagV2 from 'common-components/tags/PaymentSourceTagV2';
import { FieldLabel, Text } from 'common-components/typography';
import * as H from 'history';
import { INewBookingData } from 'interfaces/booking-interfaces';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ICustomView } from 'src/interfaces/custom-views-interface';
import { ICustomerAlert, ICustomerListItem } from 'src/interfaces/customer-interfaces';
import { IRootDispatch, IRootState, dispatch, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  CustomViewsModalType,
  CustomViewsType,
  CustomerAlertType,
  CustomerStatusType,
  CustomerViews,
  FilterType,
} from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import CreateNewActivityRecordModalV2 from 'views/bookings/listings/components/CreateNewActivityRecordModalV2';
import CreateNewBookingModal from 'views/bookings/listings/components/CreateNewBookingModal';
import { GridHeader, GridRow } from '../../../common-components/grids';
import CustomerViewTabPopover from '../CustomerViews/components/CustomerViewTabPopover';
import { BudgetStatusColumn } from './BudgetStatusColumn';
const { Search } = Input;
const { TabPane } = Tabs;
interface ICustomerListingPanelProps extends FormComponentProps {
  doGetCustomer?: typeof dispatch.customersStore.doGetCustomer;
  doGetCustomers?: typeof dispatch.customersStore.doGetCustomers;
  doGetCustomerParentGuardiansList?: typeof dispatch.customersStore.doGetCustomerParentGuardiansList;
  filteredCustomers?: ICustomerListItem[];
  customerFilter: typeof state.customersStore.customerFilter;
  portalUser: typeof state.authStore.portalUser;
  setCustomerFilter: typeof dispatch.customersStore.setCustomerFilter;
  setHasCustomerFilterChanged: typeof dispatch.customersStore.setHasCustomerFilterChanged;
  setRefreshList: typeof dispatch.customersStore.setRefreshList;
  setCustomers: typeof dispatch.customersStore.setCustomers;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  doFetchCustomerViews: typeof dispatch.customersStore.doFetchCustomerViews;
  setDefaultCustomerViews: typeof dispatch.customersStore.setDefaultCustomerViews;
  customerViews: typeof state.customersStore.customerViews;
  defaultCustomerViews: typeof state.customersStore.defaultCustomerViews;
  setDisplayedCustomerListingTabs: typeof dispatch.customersStore.setDisplayedCustomerListingTabs;
  displayedCustomerListingTabs: typeof state.customersStore.displayedCustomerListingTabs;
  setCustomerListingActiveTab: typeof dispatch.customersStore.setCustomerListingActiveTab;
  customerListingActiveTab: typeof state.customersStore.customerListingActiveTab;
  refreshList: typeof state.customersStore.refreshList;
  customers?: ICustomerListItem[];
  doSetNewBookingData?: any;
  newBookingData?: INewBookingData | any;
  history: H.History;
  doAddCustomerView: (action: any) => Promise<any>;
  doUpdateCustomerViewTab: (action: any) => Promise<any>;
  doDuplicateCustomerView: (action: any) => Promise<any>;
  doDeleteCustomerView: (action: any) => Promise<any>;
  flags: { [key: string]: boolean };
}

interface ICustomerListingPanelState {
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  isFilterOpen: boolean;
  isSearching: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  displayedTabs: string[];
  selectedRows: string[];
  isAllRowsSelected: boolean;
  showCreateBookingModal: boolean;
  showCustomerViewModal: boolean;
  showCustomerViewPopover: boolean;
  showTabIconPopover: string;
  viewModalType: CustomViewsModalType;
  customerViews: any[];
  selectedCustomer: any;
  selectedCustomerId: string;
  showCreateActivityRecordModal: boolean;
}

const availableFilters = [
  FilterType.BLOCKED_SUPPORT_WORKER,
  FilterType.CUSTOMER_ALERTS,
  FilterType.CUSTOMER_CONNECTION_STATUS,
  FilterType.CUSTOMER_MANAGED_BY,
  FilterType.CUSTOMER_STATUS,
  FilterType.DATE_ADDED,
  FilterType.MANAGEMENT_METHOD,
  FilterType.FUNDING_SOURCES,
  FilterType.PERMANENT_CONDITIONS,
  FilterType.PINNED_ALERTS,
  FilterType.PREFERRED_SUPPORT_WORKER,
  FilterType.USER_LOCATION_BY_STATE,
  FilterType.CASE_MANAGER,
  FilterType.SUPPORT_COORDINATOR,
];

const defaultFilterValue = [
  { [FilterType.CUSTOMER]: [] },
  { [FilterType.SERVICE]: [] },
  { [FilterType.CUSTOMER_STATUS]: [] },
];

const ListEmptyState = () => (
  <div className='align-center flex-column  flex-1 bg-white'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Customer found.
    </Text>{' '}
    <br /> <br />
    <Text color='secondary'>All customers under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter, or clicking on another tab.</Text>
  </div>
);

class CustomerListingPanel extends Component<ICustomerListingPanelProps, ICustomerListingPanelState> {
  state = {
    isLoading: false,
    isLoadingInfiniteScrolling: false,
    isFilterOpen: false,
    isSearching: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    displayedTabs: [],
    selectedRows: [],
    isAllRowsSelected: false,
    showCreateBookingModal: false,
    viewModalType: CustomViewsModalType.CREATE,
    showCustomerViewModal: false,
    showCustomerViewPopover: false,
    showTabIconPopover: '',
    customerViews: [],
    selectedCustomer: null,
    selectedCustomerId: '',
    showCreateActivityRecordModal: false,
  };

  private _loadContent = async () => {
    const {
      doGetCustomers,
      doFetchCustomerViews,
      setCustomerListingActiveTab,
      setCustomers,
      setDisplayedCustomerListingTabs,
      setDefaultCustomerViews,
      defaultCustomerViews,
      displayedCustomerListingTabs,
      customerListingActiveTab,
      history,
      portalUser,
    } = this.props;
    this.setState({ isLoading: true });
    let newDefaultCustomerViews = defaultCustomerViews;
    const hasAssignedCustomersView = defaultCustomerViews.some(
      (view) => view.customViewId === CustomerViews.ASSIGNED_CUSTOMERS,
    );
    const hasSupportCoordinationCustomersView = defaultCustomerViews.some(
      (view) => view.customViewId === CustomerViews.SUPPORT_COORDINATION_CUSTOMERS,
    );

    const shouldRenameCustomersTab =
      portalUser.customerViewsConfig.displaySupportCoordinationCustomersView ||
      portalUser.customerViewsConfig.displayAssignedCustomersView;
    if (shouldRenameCustomersTab) {
      newDefaultCustomerViews = newDefaultCustomerViews.map((view) => {
        if (view.customViewId === 'CUSTOMERS') {
          return { ...view, name: 'All Customers' };
        }
        return view;
      });
    }

    // commented out because needs to be fixed.
    // Start : Display Support Coordinator Customers Tab Logic
    // if (
    //   portalUser.customerViewsConfig.displaySupportCoordinationCustomersView &&
    //   !hasSupportCoordinationCustomersView
    // ) {
    //   const supportCoordinationCustomersView = {
    //     customViewId: CustomerViews.SUPPORT_COORDINATION_CUSTOMERS,
    //     name: 'Support Coordination Customers',
    //     isPinned: true,
    //     isDefault: true,
    //     isCustomer: true,
    //     viewType: CustomViewsType.EVERYONE,
    //     filterValue: [
    //       { [FilterType.CUSTOMER]: [] },
    //       { [FilterType.SERVICE]: [] },
    //       { [FilterType.CUSTOMER_STATUS]: [CustomerStatusType.ACTIVE] },
    //     ],
    //   };

    //   newDefaultCustomerViews = [supportCoordinationCustomersView, ...newDefaultCustomerViews];
    //   setDefaultCustomerViews(newDefaultCustomerViews);
    // }
    // END : Display Support Coordinator Customers Tab Logic

    // Start : Display Assigned Customers Tab Logic
    if (portalUser.customerViewsConfig.displayAssignedCustomersView && !hasAssignedCustomersView) {
      const assignedCustomersView = {
        customViewId: CustomerViews.ASSIGNED_CUSTOMERS,
        name: 'Assigned Customers',
        isPinned: true,
        isDefault: true,
        isCustomer: true,
        viewType: CustomViewsType.EVERYONE,
        filterValue: [
          { [FilterType.CUSTOMER]: [] },
          { [FilterType.SERVICE]: [] },
          { [FilterType.CUSTOMER_STATUS]: [CustomerStatusType.ACTIVE] },
        ],
      };
      newDefaultCustomerViews = [assignedCustomersView, ...newDefaultCustomerViews];
      setDefaultCustomerViews(newDefaultCustomerViews);
    }
    // END : Display Assigned Customer Tab Logic

    doFetchCustomerViews({});
    const showActiveTab = _.get(history, 'location.state.showActiveTab');
    if (showActiveTab && customerListingActiveTab) {
      !customerListingActiveTab.isDefault &&
        (await setDisplayedCustomerListingTabs([...displayedCustomerListingTabs, customerListingActiveTab]));
    } else {
      setCustomerListingActiveTab(newDefaultCustomerViews[0]);
    }
    let appliedFilters = this._generateFilters();
    await this.props.setCustomerFilter(appliedFilters);
    setCustomers([]);
    await doGetCustomers({
      filters: this.props.customerFilter,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      isViewAssignedCustomers: newDefaultCustomerViews[0]?.customViewId === CustomerViews.ASSIGNED_CUSTOMERS,
      hasSupportCoordinationCustomersView:
        newDefaultCustomerViews[0]?.customViewId === CustomerViews.SUPPORT_COORDINATION_CUSTOMERS,
    });
    this.setState({ isLoading: false });
  };

  private _generateFilters = () => {
    const { customerListingActiveTab, defaultCustomerViews } = this.props;
    let activeTab = defaultCustomerViews[0];
    if (!_.isEmpty(customerListingActiveTab) && _.get(customerListingActiveTab, 'filterValue')) {
      activeTab = customerListingActiveTab;
    }
    return activeTab.filterValue
      .filter((filter) => !_.isEmpty(filter))
      .map((filter) => {
        const [[key, value]] = Object.entries(filter);
        return {
          filter: key,
          values: value || [],
          selectionLabel: CommonUtils.getFilterText(
            key,
            key === FilterType.DATE_ADDED ? [moment(value[0]), moment(value[1])] : value,
          ),
        };
      });
  };

  private _getCustomerProfileHref = (customerID: string) => {
    const { portalUser } = this.props;
    if (portalUser === null) {
      return '#';
    }

    if (!PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles)) {
      return '#';
    }

    return `/customer/details/${customerID}`;
  };

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setHasCustomerFilterChanged(true);
    this.props.setCustomerFilter(filters);
  };

  private _searchText = async (txt) => {
    const {
      doGetCustomers,
      setCustomerFilter,
      customerFilter,
      doGetCustomerParentGuardiansList,
      customerListingActiveTab,
    } = this.props;
    this.setState({ page: 1 });
    const filterSearchIndex = customerFilter.findIndex((filter) => filter['search']);
    filterSearchIndex > -1
      ? (customerFilter[filterSearchIndex] = { search: txt })
      : customerFilter.push({ search: txt });
    setCustomerFilter([...customerFilter]);
    if (_.get(customerListingActiveTab, 'isCustomer')) {
      await doGetCustomers({
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        sortByRelevance: true,
        filters: customerFilter,
        isViewAssignedCustomers: _.get(customerListingActiveTab, 'customViewId') === CustomerViews.ASSIGNED_CUSTOMERS,
        hasSupportCoordinationCustomersView:
          _.get(customerListingActiveTab, 'customViewId') === CustomerViews.SUPPORT_COORDINATION_CUSTOMERS,
      });
    } else {
      await doGetCustomerParentGuardiansList({
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        sortByRelevance: true,
      });
    }
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _changeTab = async (selectedTabId) => {
    const {
      setCustomerFilter,
      customerFilter,
      setCustomerListingActiveTab,
      displayedCustomerListingTabs,
      defaultCustomerViews,
    } = this.props;
    const displayedTabs = [...defaultCustomerViews, ...displayedCustomerListingTabs];
    const activeTab = displayedTabs.find((tab) => tab.customViewId === selectedTabId);
    setCustomerListingActiveTab(activeTab);
    this.setState({ isLoading: true, page: 1, selectedRows: [] });
    setCustomerFilter({ ...customerFilter, userType: event });
    this.setState({ isLoading: false });
  };

  private _fetchMoreCustomers = async () => {
    const { doGetCustomers, doGetCustomerParentGuardiansList, customerListingActiveTab } = this.props;
    const nextPage = this.state.page + 1;
    this.setState({ isLoadingInfiniteScrolling: true, page: nextPage });

    if (_.get(customerListingActiveTab, 'isCustomer')) {
      await doGetCustomers({
        filters: this.props.customerFilter,
        page: nextPage,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        isViewAssignedCustomers: _.get(customerListingActiveTab, 'customViewId') === CustomerViews.ASSIGNED_CUSTOMERS,
        hasSupportCoordinationCustomersView:
          _.get(customerListingActiveTab, 'customViewId') === CustomerViews.SUPPORT_COORDINATION_CUSTOMERS,
      });
    } else {
      await doGetCustomerParentGuardiansList({
        filters: this.props.customerFilter,
        page: nextPage,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
    }
    this.setState({ isLoadingInfiniteScrolling: false });
  };

  private _renderViewTabIcon = (tab: ICustomView) => {
    const togglePopOver = (value) => {
      const showTabIconPopover = value ? tab.customViewId : '';
      this.setState({ showTabIconPopover });
    };
    return (
      <Popover
        content={
          <CustomerViewTabPopover
            onHidePopOver={togglePopOver}
            currentTab={tab}
            onShowCustomerViewModal={this._openCustomerViewModal}
          />
        }
        visible={this.state.showTabIconPopover === tab.customViewId}
        onVisibleChange={togglePopOver}
        placement='bottom'
        trigger='click'
      >
        <Icon className='ml-small mr-none' type='caret-down' />
      </Popover>
    );
  };

  private _toggleAddViewPopOverChange = (visible) => {
    this.setState({ showCustomerViewPopover: visible });
  };

  private _renderExtraTabs = () => {
    const {
      displayedCustomerListingTabs,
      customerViews,
      setDisplayedCustomerListingTabs,
      setCustomerListingActiveTab,
    } = this.props;
    return (
      <>
        <Popover
          content={
            <CustomViewPopover
              pageViews={customerViews}
              displayedPageListingTabs={displayedCustomerListingTabs}
              onCreateNewView={() => this._openCustomerViewModal(CustomViewsModalType.CREATE)}
              onDisplayTab={this._toggleAddViewPopOverChange}
              setDisplayedPageListingTabs={setDisplayedCustomerListingTabs}
              setPageListingActiveTab={setCustomerListingActiveTab}
            />
          }
          trigger='click'
          visible={this.state.showCustomerViewPopover}
          onVisibleChange={this._toggleAddViewPopOverChange}
        >
          <HyperlinkButton className='ph-large'>+ Add view</HyperlinkButton>
        </Popover>
        <HyperlinkButton className='ph-large' onClick={this._goCustomerAllViews}>
          All views
        </HyperlinkButton>
      </>
    );
  };

  private _goCustomerAllViews = () => {
    const { history } = this.props;
    history.push(`/customers/all-views`);
  };

  private _openCustomerViewModal = (type: CustomViewsModalType) => {
    this.setState({ showCustomerViewPopover: false });
    this.setState({ viewModalType: type, showCustomerViewModal: true });
  };

  private _closeCustomerViewModal = () => {
    this.setState({ viewModalType: CustomViewsModalType.CREATE, showCustomerViewModal: false });
  };

  private _getFilteredCustomersByOtherStatuses = (statuses: CustomerStatusType[]) => {
    return this.props.filteredCustomers.filter((customer) => !statuses.includes(customer.customerStatus));
  };

  private _updateSelectedRows = (e, customerId: string) => {
    e.stopPropagation();
    const { selectedRows } = this.state;
    const customerIdIndex = selectedRows.indexOf(customerId);
    const isExisting = customerIdIndex > -1;
    if (isExisting) {
      selectedRows.splice(customerIdIndex, 1);
    } else {
      selectedRows.push(customerId);
    }
    const filteredCustomersNoArchived = this._getFilteredCustomersByOtherStatuses([CustomerStatusType.ARCHIVED]);
    const isAllRowsSelected = selectedRows.length > 0 && selectedRows.length === filteredCustomersNoArchived.length;
    this.setState({ isAllRowsSelected, selectedRows });
  };

  private _selectAllRows() {
    const filteredCustomersNoArchived = this._getFilteredCustomersByOtherStatuses([CustomerStatusType.ARCHIVED]);
    const selectedRows = filteredCustomersNoArchived.map((customer) => customer.userId);
    this.setState({ isAllRowsSelected: true, selectedRows });
  }

  private _clearSelectedRows = () => {
    this.setState({ isAllRowsSelected: false, selectedRows: [] });
  };

  private _toggleSelectedRows = () => {
    const filteredCustomersNoArchived = this._getFilteredCustomersByOtherStatuses([CustomerStatusType.ARCHIVED]);
    if (this.state.selectedRows.length < filteredCustomersNoArchived.length) {
      this._selectAllRows();
    } else {
      this._clearSelectedRows();
    }
  };

  private _openBookingModal = async () => {
    const selectedCustomerId = this.state.selectedRows[0];
    const { customers, newBookingData, doSetNewBookingData, doGetCustomer } = this.props;
    const selectedCustomer = _.find(customers, (customer) => customer.userId === selectedCustomerId);
    if (selectedCustomer) {
      await doGetCustomer({ userId: selectedCustomerId });
      await doSetNewBookingData({ ...newBookingData, selectedCustomerId, selectedCustomer });
    }
    this.setState({ showCreateBookingModal: true });
  };

  private _closeBookingModal = () => this.setState({ showCreateBookingModal: false });

  private _onCloseCreateActivityRecord = () => this.setState({ showCreateActivityRecordModal: false });

  private _onSaveView = (tab: ICustomView) => {
    if (tab && tab.isOwner) {
      this._openCustomerViewModal(CustomViewsModalType.SAVE_VIEW);
    } else if (tab && tab.isDefault) {
      this._openCustomerViewModal(CustomViewsModalType.SAVE_DEFAULT_VIEW);
    } else {
      this._openCustomerViewModal(CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS);
    }
  };

  private _customerAlertDescription(alertType: CustomerAlertType, numberOfAlerts: number) {
    const agreements = numberOfAlerts !== 1 ? 'agreements' : 'agreement';
    const documents = numberOfAlerts !== 1 ? 'documents' : 'document';

    switch (alertType) {
      case CustomerAlertType.OverBudgetActual:
        return `service ${agreements} exceed quote amount.`;
      case CustomerAlertType.OverBudgetForecasted:
        return `service ${agreements} is forcasted to exceed quote amount.`;
      case CustomerAlertType.ServiceAgreementExpired:
        return `No active/upcoming service ${agreements}.`;
      case CustomerAlertType.ServiceAgreementExpiringSoon:
        return `service ${agreements} expiring soon.`;
      case CustomerAlertType.DocumentExpired:
        return `${documents} expired.`;
      case CustomerAlertType.DocumentExpiringSoon:
        return `${documents} expiring soon.`;
      default:
        return null;
    }
  }

  private _renderCustomerAlertPopoverContent(alerts: ICustomerAlert[]) {
    return alerts.map(({ alertType, numberOfAlerts }, idx) => (
      <div key={`alert_${idx}`}>
        {alertType === CustomerAlertType.ServiceAgreementExpired ? '' : <b>{numberOfAlerts}</b>}{' '}
        {this._customerAlertDescription(alertType, numberOfAlerts)} <br />
      </div>
    ));
  }

  private _createNewBookingForSelectedCustomer = async (selectedCustomerId) => {
    const { customers, newBookingData, doSetNewBookingData, doGetCustomer } = this.props;
    const selectedCustomer = _.find(customers, (customer) => customer.userId === selectedCustomerId);
    if (selectedCustomer) {
      await doGetCustomer({ userId: selectedCustomerId });
      await doSetNewBookingData({ ...newBookingData, selectedCustomerId, selectedCustomer });
    }

    this.setState({ showCreateBookingModal: true, selectedCustomer: { ...selectedCustomer } });
  };

  private _createNewActivityRecordForSelectedCustomer = (selectedCustomer) => {
    this.setState({
      showCreateActivityRecordModal: true,
      selectedCustomerId: selectedCustomer.userId,
      selectedCustomer,
    });
  };

  private _loadCustomers = async () => {
    const { doGetCustomers, doGetCustomerParentGuardiansList, setCustomers, customerListingActiveTab } = this.props;

    const pageTimestamp = new Date();
    this.setState({ page: 1, pageTimestamp });
    setCustomers([]);
    if (_.get(customerListingActiveTab, 'isCustomer')) {
      await doGetCustomers({
        filters: this.props.customerFilter,
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp,
        isViewAssignedCustomers: _.get(customerListingActiveTab, 'customViewId') === CustomerViews.ASSIGNED_CUSTOMERS,
        hasSupportCoordinationCustomersView:
          _.get(customerListingActiveTab, 'customViewId') === CustomerViews.SUPPORT_COORDINATION_CUSTOMERS,
      });
    } else {
      await doGetCustomerParentGuardiansList({
        filters: this.props.customerFilter,
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp,
      });
    }
    this.setState({ isLoading: false });
  };

  private _debounceLoadCustomers = _.debounce(this._loadCustomers, 500);

  private _onLoadCustomers = async () => {
    this.setState({ isLoading: true });
    this._debounceLoadCustomers();
  };

  componentDidMount() {
    this.props.setSelectedSideNavMenuKeys(['/customers']);
    this._loadContent();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      doGetCustomers,
      setCustomerFilter,
      doGetCustomerParentGuardiansList,
      setCustomers,
      filteredCustomers,
      customerListingActiveTab,
      refreshList,
    } = this.props;

    const shouldUpdateSelectedRows =
      !_.isEqual(prevProps.filteredCustomers, filteredCustomers) && this.state.isAllRowsSelected;
    if (shouldUpdateSelectedRows) {
      this._selectAllRows();
    }

    if (!_.isEqual(prevProps.customerListingActiveTab, customerListingActiveTab)) {
      await setCustomerFilter(this._generateFilters());
    }

    if (!_.isEqual(prevProps.customerFilter, this.props.customerFilter)) {
      await this._onLoadCustomers();
    }
    if (!_.isEqual(prevProps.refreshList, refreshList)) {
      const pageTimestamp = new Date();
      this.setState({ isLoading: true, page: 1, pageTimestamp });
      setCustomers([]);
      if (_.get(customerListingActiveTab, 'isCustomer')) {
        await doGetCustomers({
          filters: this.props.customerFilter,
          page: this.state.page,
          pageSize: this.state.pageSize,
          pageTimestamp,
          isViewAssignedCustomers: _.get(customerListingActiveTab, 'customViewId') === CustomerViews.ASSIGNED_CUSTOMERS,
          hasSupportCoordinationCustomersView:
            _.get(customerListingActiveTab, 'customViewId') === CustomerViews.SUPPORT_COORDINATION_CUSTOMERS,
        });
      } else {
        await doGetCustomerParentGuardiansList({
          filters: this.props.customerFilter,
          page: 1,
          pageSize: this.state.pageSize,
          pageTimestamp,
        });
      }
      this.setState({ isLoading: false });
      this.props.setRefreshList(false);
    }
  };

  componentWillUnmount() {
    const { setCustomerFilter, setDisplayedCustomerListingTabs } = this.props;
    setCustomerFilter([]);
    setDisplayedCustomerListingTabs([]);
  }

  private _renderAlerts(customerAlerts) {
    return (
      <Popover content={this._renderCustomerAlertPopoverContent(customerAlerts)} title={<b>Alerts</b>}>
        <Text className='cursor-pointer'>
          <Message iconPosition='start' tone='critical'>
            {customerAlerts.length} {customerAlerts.length !== 1 ? 'alerts' : 'alert'}
          </Message>
        </Text>
      </Popover>
    );
  }

  render() {
    const {
      filteredCustomers,
      portalUser,
      displayedCustomerListingTabs,
      defaultCustomerViews,
      customerListingActiveTab,
      customerViews,
      customerFilter,
      doAddCustomerView,
      doDeleteCustomerView,
      doUpdateCustomerViewTab,
      doDuplicateCustomerView,
      setCustomerFilter,
      flags,
    } = this.props;

    const { crem61 } = flags;
    const { isAllRowsSelected, selectedRows, viewModalType } = this.state;
    const renderExtraTabs = this._renderExtraTabs();
    const isNotGuardian = (selectedCustomer) => selectedCustomer.isDependent || selectedCustomer.isIndependent;
    const generateActiveTabClassName = (activeTab, tab) =>
      _.get(activeTab, 'customViewId') === tab.customViewId ? `text-color-blue-action` : 'text-color-secondary';
    const hasAssignedCustomerView = defaultCustomerViews.some(
      (view) => view.customViewId === CustomerViews.ASSIGNED_CUSTOMERS,
    );
    const isActiveAssignedCustomersViewTab =
      _.get(customerListingActiveTab, 'customViewId') === CustomerViews.ASSIGNED_CUSTOMERS;
    const isActiveSupportCoordinationCustomersViewTab =
      _.get(customerListingActiveTab, 'customViewId') === CustomerViews.SUPPORT_COORDINATION_CUSTOMERS;

    return (
      <div className='width-full'>
        <CreateNewBookingModal
          isOpen={this.state.showCreateBookingModal}
          history={this.props.history}
          closeCreateBookingModal={this._closeBookingModal}
          incomingCustomer={this.state.selectedCustomer ? this.state.selectedCustomer : null}
        />
        {this.state.showCreateActivityRecordModal && (
          <CreateNewActivityRecordModalV2
            isOpen={this.state.showCreateActivityRecordModal}
            onClose={this._onCloseCreateActivityRecord}
            history={this.props.history}
            selectedCustomer={this.state.selectedCustomer}
          />
        )}

        <CustomViewsModal
          isOpen={this.state.showCustomerViewModal}
          onCloseViewModal={this._closeCustomerViewModal}
          onUpdateViewModal={this._openCustomerViewModal}
          type={viewModalType}
          pageViews={customerViews}
          pageFilter={customerFilter}
          pageListingActiveTab={customerListingActiveTab}
          defaultViews={defaultCustomerViews}
          defaultFilterValue={defaultFilterValue}
          setPageFilter={setCustomerFilter}
          doAddView={doAddCustomerView}
          doDeleteView={doDeleteCustomerView}
          doUpdateViewTab={doUpdateCustomerViewTab}
          doDuplicateView={doDuplicateCustomerView}
        />
        <Tabs
          type='card'
          tabBarExtraContent={renderExtraTabs}
          activeKey={_.get(customerListingActiveTab, 'customViewId')}
          defaultActiveKey={hasAssignedCustomerView ? 'ASSIGNED_CUSTOMERS' : 'CUSTOMERS'}
          animated={true}
          onChange={this._changeTab}
        >
          {defaultCustomerViews.map((tab) => {
            return (
              <TabPane
                tab={<span className={generateActiveTabClassName(customerListingActiveTab, tab)}>{tab.name}</span>}
                key={tab.customViewId}
              />
            );
          })}
          {displayedCustomerListingTabs.map((tab: ICustomView) => {
            return (
              <TabPane
                tab={
                  <span className={generateActiveTabClassName(customerListingActiveTab, tab)}>
                    {tab.isPinned && <Icon type='pushpin' theme={'filled'} className={`mr-small`} />}
                    {tab.name}
                    {this._renderViewTabIcon(tab)}
                  </span>
                }
                key={tab.customViewId}
              />
            );
          })}
        </Tabs>
        <div className='align-center flex-row justify-start'>
          <div>
            <Search
              placeholder='Search for...'
              onChange={this._onEnterSearchText}
              loading={this.state.isSearching}
              style={{ width: '250px', height: '32px' }}
              allowClear={true}
            />
          </div>
          <div className='ml-small'>
            <FilterSection
              availableFilters={
                isActiveAssignedCustomersViewTab || isActiveSupportCoordinationCustomersViewTab
                  ? availableFilters.filter((filter) => filter !== FilterType.SUPPORT_COORDINATOR)
                  : availableFilters
              }
              filters={this.props.customerFilter}
              onChangeFilter={this._onChangeFilter}
              displayTimezone={portalUser.timezone}
              displayMoreFilter={_.get(customerListingActiveTab, 'isCustomer') ? true : false}
            />
          </div>
          {!isActiveAssignedCustomersViewTab && (
            <div className='flex-grow flex-row justify-end'>
              <SecondaryButton
                className='text-color-blue-action mr-x-small ml-small'
                onClick={() => this._onSaveView(customerListingActiveTab)}
              >
                <Icon className='ml-small mr-none' type='save' /> Save view
              </SecondaryButton>
            </div>
          )}
        </div>
        {selectedRows.length > 0 && (
          <Alert
            className='anim-slide-down shadow-container bordered-none bg-blue-lightest mb-medium'
            message={
              <div className='align-center p-x-small flex-row'>
                <b>{selectedRows.length} customer selected</b>
                <HyperlinkButton className='mh-large' onClick={this._clearSelectedRows}>
                  Clear selection
                </HyperlinkButton>
                <div className='flex-grow'></div>
                {selectedRows.length === 1 && (
                  <SecondaryButton onClick={this._openBookingModal}>Create booking</SecondaryButton>
                )}
              </div>
            }
            type='info'
          />
        )}
        <GridHeader bordered containerClassName='border border-secondary rounded-sm'>
          {_.get(customerListingActiveTab, 'isCustomer') ? (
            <>
              <Col span={1} className=' bg-white'>
                <Checkbox
                  indeterminate={!!selectedRows.length && !isAllRowsSelected}
                  checked={!!selectedRows.length}
                  onClick={this._toggleSelectedRows}
                />
              </Col>
              <Col span={5} className=' bg-white'>
                <FieldLabel text='Customer' />
              </Col>
              <Col span={2} className=' bg-white'>
                <FieldLabel text='status' />
              </Col>
              <Col span={2} className=' bg-white'>
                <FieldLabel text='funding' />
              </Col>
              {!isActiveAssignedCustomersViewTab ? (
                <Col span={3} className=' bg-white'>
                  <FieldLabel text='location' />
                </Col>
              ) : null}
              <Col span={3} className=' bg-white'>
                <FieldLabel text='Alerts' />
              </Col>
              <Col span={4} className=' bg-white'>
                <FieldLabel text='legal guardian(s)' />
              </Col>
              {isActiveAssignedCustomersViewTab && crem61 ? (
                <Col span={5} className='bg-white'>
                  <FieldLabel text='budget' />
                </Col>
              ) : null}
              <Col span={1} className='bg-white' />
            </>
          ) : (
            <>
              <Col span={8} className=' bg-white'>
                <FieldLabel text='legal guardian name' />
              </Col>
              <Col span={7} className=' bg-white'>
                <FieldLabel text='connection status' />
              </Col>
              <Col span={8} className=' bg-white'>
                <FieldLabel text='managed customers' />
              </Col>
            </>
          )}
        </GridHeader>
        {this.state.isLoading ? (
          <div className=''>
            <div className='pv-large'>
              <ProgressBar />
            </div>
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
          </div>
        ) : !_.isEmpty(filteredCustomers) ? (
          <InfiniteScrollLoading
            hasMore={filteredCustomers.length >= this.state.page * this.state.pageSize}
            loadingElementId={'content-container'}
            loadMore={this._fetchMoreCustomers}
            loaderColSpan={7}
            loadingOffSet={60}
          >
            {filteredCustomers.map((customer, index) => {
              const customerAlerts = customer.customerAlerts;

              const hasCreateActivityRecordPermission = customer.customerStatus !== CustomerStatusType.ARCHIVED;

              return (
                <GridRow key={index}>
                  {_.get(customerListingActiveTab, 'isCustomer') ? (
                    <>
                      <Col span={1}>
                        {isNotGuardian(customer) && hasCreateActivityRecordPermission && (
                          <Checkbox
                            checked={selectedRows.indexOf(customer.userId) > -1}
                            onClick={(e) => this._updateSelectedRows(e, customer.userId)}
                          />
                        )}
                      </Col>
                      <Col span={5}>
                        <Link to={this._getCustomerProfileHref(customer.userId)}>
                          <div className='align-center cursor-pointer flex-row'>
                            <div>
                              <Avatar className='mr-medium' size='large' icon='user' src={customer.attachmentUrl} />
                            </div>
                            <div
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              <Text>{`${customer.firstName} ${customer.lastName}`}</Text>
                            </div>

                            {customer.pinnedAlertNumber > 0 && (
                              <PinnedAlertIcon
                                pinnedAlertNumber={customer.pinnedAlertNumber}
                                customerUserId={customer.userId}
                              />
                            )}
                          </div>
                        </Link>
                      </Col>
                      <Col span={2}>
                        <CustomerStatusTags
                          key={customer.userId}
                          customerStatus={customer.customerStatus ? customer.customerStatus : 'CONNECTED'}
                        />
                      </Col>
                      <Col span={2}>
                        {!_.isEmpty(customer.serviceAgreementPaymentSourceType) ? (
                          customer.serviceAgreementPaymentSourceType.map((type) => (
                            <PaymentSourceTagV2 key={type} paymentSource={type} size={'small'} />
                          ))
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                      {!isActiveAssignedCustomersViewTab ? (
                        <Col span={3}>
                          {customer.locality ? (
                            <Text>{customer.locality}</Text>
                          ) : (
                            <Text color={'tertiary'}>Locality not set</Text>
                          )}{' '}
                        </Col>
                      ) : null}
                      <Col span={3}>{!_.isEmpty(customerAlerts) ? this._renderAlerts(customerAlerts) : '-'}</Col>
                      <Col span={4}>
                        {!_.isEmpty(customer.guardians) ? (
                          <>
                            <div className='flex-row flex-wrap justify-between'>
                              {_.chain(customer.guardians)
                                .slice(0, 2)
                                .map((guardian, index) => {
                                  return (
                                    <div
                                      key={guardian.userId + index}
                                      className='align-center mr-medium mv-x2-small flex-row'
                                    >
                                      <div>
                                        <Avatar
                                          className='mr-small'
                                          size='small'
                                          icon='user'
                                          src={guardian.attachmentUrl}
                                        />
                                      </div>
                                      {PermissionUtils.validatePermission(
                                        'ViewCustomerProfile',
                                        portalUser.permissions.permissionRoles,
                                      ) ? (
                                        <Link to={this._getCustomerProfileHref(guardian.userId)}>
                                          <HyperlinkButton color={'black'}>
                                            {guardian.firstName + ' ' + guardian.lastName}
                                          </HyperlinkButton>
                                        </Link>
                                      ) : (
                                        <Text>{guardian.firstName + ' ' + guardian.lastName}</Text>
                                      )}
                                    </div>
                                  );
                                })
                                .value()}
                            </div>
                            <div>
                              {customer.guardians && customer.guardians.length > 2 && (
                                <Text color={'secondary'}>({customer.guardians.length - 2} more)</Text>
                              )}
                            </div>
                          </>
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                      {isActiveAssignedCustomersViewTab && crem61 ? (
                        <Col span={5}>
                          <BudgetStatusColumn customerId={customer.userId} />
                        </Col>
                      ) : null}
                      <Col span={1} onClick={(e) => e.stopPropagation()}>
                        {hasCreateActivityRecordPermission && (
                          <PopoverBlueprint
                            interactionKind='click'
                            position='bottom-right'
                            content={
                              <ActionMenu className='pv-small'>
                                <ActionMenuItem
                                  text='Create booking'
                                  onClick={() => this._createNewBookingForSelectedCustomer(customer.userId)}
                                />
                                <ActionMenuItem
                                  text='Create activity record'
                                  onClick={() => this._createNewActivityRecordForSelectedCustomer(customer)}
                                />
                              </ActionMenu>
                            }
                          >
                            <IconButton
                              icon='ellipsis'
                              size='default'
                              iconColor='blue-action'
                              color='white'
                              bordered={true}
                              className='ml-medium bordered border-tertiary'
                            />
                          </PopoverBlueprint>
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={8}>
                        <div className='align-center cursor-pointer flex-row'>
                          <div>
                            <Avatar className='mr-medium' size='large' icon='user' src={customer.attachmentUrl} />
                          </div>
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            <Text>{customer.firstName + ' ' + customer.lastName}</Text>
                          </div>

                          {customer.pinnedAlertNumber > 0 && (
                            <PinnedAlertIcon
                              pinnedAlertNumber={customer.pinnedAlertNumber}
                              customerUserId={customer.userId}
                            />
                          )}
                        </div>
                      </Col>
                      <Col span={7}>
                        <CustomerConnectionStatusTag customerStatus={customer.connected} />
                      </Col>
                      <Col span={8}>
                        {!_.isEmpty(customer.dependentUsers) ? (
                          <div className='flex-row'>
                            {_.chain(customer.dependentUsers)
                              .slice(0, 2)
                              .map((dependentUser) => {
                                return (
                                  <Link to={this._getCustomerProfileHref(dependentUser.userId)}>
                                    <div
                                      className='align-center mr-medium cursor-pointer flex-row'
                                      key={dependentUser.userId}
                                    >
                                      <div>
                                        <Avatar
                                          className='mr-small'
                                          size='small'
                                          icon='user'
                                          src={dependentUser.attachmentUrl}
                                        />
                                      </div>
                                      <Text>{dependentUser.firstName + ' ' + dependentUser.lastName}</Text>
                                    </div>
                                  </Link>
                                );
                              })
                              .value()}
                            <div>
                              {customer.dependentUsers && customer.dependentUsers.length > 2 && (
                                <Text>({customer.dependentUsers.length - 2} more)</Text>
                              )}
                            </div>
                          </div>
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                    </>
                  )}
                </GridRow>
              );
            })}
            <div id='scroll' />
          </InfiniteScrollLoading>
        ) : (
          <div style={{ borderBottom: '0px solid' }}>
            <ListEmptyState />
          </div>
        )}
        {this.state.isLoadingInfiniteScrolling && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  filteredCustomers: state.customersStore.filteredCustomers,
  customers: state.customersStore.customers,
  customerFilter: state.customersStore.customerFilter,
  refreshList: state.customersStore.refreshList,
  portalUser: state.authStore.portalUser,
  newBookingData: state.bookingsStore.newBookingData,
  customerViews: state.customersStore.customerViews,
  displayedCustomerListingTabs: state.customersStore.displayedCustomerListingTabs,
  customerListingActiveTab: state.customersStore.customerListingActiveTab,
  defaultCustomerViews: state.customersStore.defaultCustomerViews,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomers: dispatch.customersStore.doGetCustomers,
  doGetCustomerParentGuardiansList: dispatch.customersStore.doGetCustomerParentGuardiansList,
  setCustomerFilter: dispatch.customersStore.setCustomerFilter,
  setHasCustomerFilterChanged: dispatch.customersStore.setHasCustomerFilterChanged,
  setRefreshList: dispatch.customersStore.setRefreshList,
  setCustomers: dispatch.customersStore.setCustomers,
  setDefaultCustomerViews: dispatch.customersStore.setDefaultCustomerViews,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  doFetchCustomerViews: dispatch.customersStore.doFetchCustomerViews,
  doAddCustomerView: dispatch.customersStore.doAddCustomerView,
  doUpdateCustomerViewTab: dispatch.customersStore.doUpdateCustomerView,
  doDuplicateCustomerView: dispatch.customersStore.doDuplicateCustomerView,
  doDeleteCustomerView: dispatch.customersStore.doDeleteCustomerView,
  setDisplayedCustomerListingTabs: dispatch.customersStore.setDisplayedCustomerListingTabs,
  setCustomerListingActiveTab: dispatch.customersStore.setCustomerListingActiveTab,
});

export default connect(
  mapState,
  mapDispatch,
)(withLDConsumer()(Form.create<ICustomerListingPanelProps>()(CustomerListingPanel)));
