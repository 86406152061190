import React, { Component } from 'react';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { GhostButton } from 'common-components/buttons';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import _ from 'lodash';

const { Option } = Select;

interface ITeamContactDetailRowProps extends FormComponentProps {
  removeTeamContactInputRow: () => void;
  contact: any;
  handleInputData: (any) => void;
  handleDropDownClick: (value: string, name: string) => void;
  rowID: string;
  form: WrappedFormUtils<Form>;
}

interface ITeamContactDetailRowState {
  isActionModalOpen: boolean;
}

class TeamContactDetailRow extends Component<ITeamContactDetailRowProps, ITeamContactDetailRowState> {
  state = {
    isActionModalOpen: false,
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';
    if (!_.isEmpty(value)) {
      const phoneNumber = parsePhoneNumberFromString(value, region);
      if (!phoneNumber || !phoneNumber.isValid()) {
        callback(new Error('Invalid Number'));
        return;
      }
    }
    callback();
  };

  private _removeTeamContactInputRow = () => {
    this.props.removeTeamContactInputRow();
  };

  private _handleInputData = (event) => {
    this.props.handleInputData(event);
  };

  private _handleDropDownClick = (value, name) => {
    this.props.handleDropDownClick(value, name);
  };

  render() {
    const { form, contact, rowID } = this.props;
    const { getFieldDecorator } = form;

    return (
      <React.Fragment>
        {' '}
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item className="mb-none">
              {getFieldDecorator(`${rowID}-numberType`, {
                rules: [{ required: true, message: 'Type is required' }],
                initialValue: contact.numberType,
              })(
                <Select
                  dropdownMatchSelectWidth={true}
                  onChange={(event) => this._handleDropDownClick(event, 'numberType')}
                  size="large"
                  className="width-full"
                >
                  <Option value="">Select</Option>
                  <Option value="Work">Work</Option>
                  <Option value="Home">Home</Option>
                  <Option value="Mobile">Mobile</Option>
                  <Option value="Personal">Personal</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item className="mb-none">
              {getFieldDecorator('numberCountryCode', {
                rules: [{ required: true, message: 'Country code is required' }],
                initialValue:
                  contact.numberCountryCode !== '' && contact.numberCountryCode !== null
                    ? contact.numberCountryCode
                    : 'AU',
              })(
                <Select
                  dropdownMatchSelectWidth={true}
                  onChange={(event) => this._handleDropDownClick(event, 'numberCountryCode')}
                  size="large"
                  className="width-full"
                >
                  <Option value="AU">+61 (AU)</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item className="mb-none">
              {getFieldDecorator(`${rowID}-number`, {
                initialValue: contact.number,
                rules: [
                  { required: true, message: 'Number is required' },
                  {
                    validator: this._validateMobileNumber,
                    message: 'Invalid Number.',
                  },
                ],
              })(
                <Input
                  className="width-full"
                  size="large"
                  name="number"
                  placeholder="Contact Number"
                  onChange={this._handleInputData}
                />,
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item className="mb-none">
              <GhostButton onClick={this._removeTeamContactInputRow}>Remove</GhostButton>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="mb-large">
            <Form.Item className="m-none">
              {getFieldDecorator(
                `${rowID}-preferredContact`,
                {},
              )(
                <Checkbox
                  name="preferredContact"
                  defaultChecked={contact.preferredContact}
                  checked={contact.preferredContact}
                  onChange={this._handleInputData}
                />,
              )}{' '}
              Use as the preferred contact number
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TeamContactDetailRow;
