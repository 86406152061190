import React, { Component } from 'react';
import { Col, Divider, Form, Icon, Input, notification, Radio, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, FieldLabel, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _, { trim } from 'lodash';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { IAddCustomerDetailWizard, IMatchingUser } from 'src/interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import CommonUtils from 'utilities/common-utils';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FormItem from 'antd/lib/form/FormItem';
import { IGoogleMapAddressComponent } from 'src/interfaces/address-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Stack } from 'common-components/stack';
import { sendToZendeskPortal } from '../../../../../integrations/zendesk';
import customerSource from 'variables/data/customer-source.json';
import { ID_OTHER_CUSTOMER_SOURCE } from 'views/customers/utils/constants';
import { ndisNumberRegex } from '@constants/billing/ndis';

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

interface IAddCustomerStepCustomerDetailProps extends FormComponentProps {
  portalUser: typeof state.authStore.portalUser;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard; // rename this
  addCustomerDetailWizardState: typeof state.customersStore.addCustomerDetailWizardState;
  onCheckDuplicate: (matchesFound: boolean, customerFuzzyMatchingList: IMatchingUser[]) => void;
  setAddCustomerDetailWizard: typeof dispatch.customersStore.setAddCustomerDetailWizard;
  setAddCustomerDetailWizardState: typeof dispatch.customersStore.setAddCustomerDetailWizardState;
  doFetchAddCustomerFuzzyMatching: typeof dispatch.customersStore.doFetchAddCustomerFuzzyMatching;
}

interface IAddCustomerStepCustomerDetailState {
  customerDetails: IAddCustomerDetailWizard;
  isCheckingDuplicate: boolean;
  address: string;
  selectedLattitude: number;
  selectedLongitude: number;
  addressComponents: IGoogleMapAddressComponent[];
  addressTypes: string[];
  isRequireManualAddress: boolean;
  isShowConfirmEmptyAddressModal: boolean;
}

class AddCustomerStepCustomerDetail extends Component<
  IAddCustomerStepCustomerDetailProps,
  IAddCustomerStepCustomerDetailState
> {
  state: IAddCustomerStepCustomerDetailState = {
    customerDetails: !_.isEmpty(this.props.addCustomerDetailWizard)
      ? this.props.addCustomerDetailWizard
      : {
          firstName: null,
          lastName: null,
          hasGuardians: false,
          isEnquiry: false,
          customerSource: null,
          customerSourceId: null,
          guardians: [],
          isInviteToApp: false,
          email: null,
          isIndependent: true,
          address: null,
        },
    isCheckingDuplicate: false,
    address: '',
    selectedLattitude: undefined,
    selectedLongitude: undefined,
    addressComponents: undefined,
    addressTypes: [],
    isRequireManualAddress: true,
    isShowConfirmEmptyAddressModal: false,
  };

  private validateBeforeNextStep = async () => {
    const { form, setAddCustomerDetailWizard, onCheckDuplicate } = this.props;
    const customerDetails = { ...this.state.customerDetails };
    const basicFields = ['firstName', 'lastName', 'mobile', 'email', 'ndisNumber', 'isEnquiry'];
    const addressFields = ['streetaddress', 'suburb', 'state', 'postcode', 'streetaddress2'];

    if (customerDetails.customerSourceId === ID_OTHER_CUSTOMER_SOURCE) {
      basicFields.push('customerSource');
    }

    try {
      const isBasicFormValid = await new Promise((resolve) => {
        form.validateFields(basicFields, async (err) => {
          if (err) {
            resolve(false);
          }
          resolve(true);
        });
      });
      if (!isBasicFormValid) {
        return;
      }

      if (!form.getFieldValue('isEnquiry')) {
        customerDetails.customerSource = null;
        customerDetails.customerSourceId = null;
      } else if (customerDetails.customerSourceId !== ID_OTHER_CUSTOMER_SOURCE) {
        customerDetails.customerSource = null;
      }

      const customerAddress = await this.extractCustomerAddress(addressFields);

      this.setState({ isCheckingDuplicate: true });
      await setAddCustomerDetailWizard({
        ...customerDetails,
        mobileCountryCode: 'AU',
        address: customerAddress,
      });

      const customerFuzzyMatching: IMatchingUser[] = await this.props.doFetchAddCustomerFuzzyMatching({
        firstName: this.state.customerDetails?.firstName ?? '',
        lastName: this.state.customerDetails?.lastName ?? '',
      });
      onCheckDuplicate(customerFuzzyMatching?.length > 0, customerFuzzyMatching);
    } catch (error) {
      if (error !== 'Stop validating') {
        console.error(error);
        notification.error({
          message: error.message ?? 'Oops, something went wrong! Please try again.',
          description: error.cause,
        });
      }
    } finally {
      this.setState({ isCheckingDuplicate: false });
    }
  };

  private updateCustomerDetail = (fieldName, value) => {
    const { customerDetails } = this.state;
    this.setState({
      customerDetails: { ...customerDetails, [fieldName]: typeof value === 'number' ? value : trim(value) },
    });
  };

  private continueWithoutAddress = () => {
    this.setState({ isShowConfirmEmptyAddressModal: false, isRequireManualAddress: false }, () => {
      this.props.form.resetFields();
      this.validateBeforeNextStep();
    });
  };

  private backToValidateManualAddress = () => {
    this.setState({ isShowConfirmEmptyAddressModal: false });
    this.props.form.validateFieldsAndScroll();
  };

  private handleChange = (address) => {
    this.setState({ address });
    if (address === '') {
      this.props.setAddCustomerDetailWizardState({ addressLookup: '' });
      this.setState({ customerDetails: { ...this.state.customerDetails, address: null } });
    }
  };

  private handleSelect = async (address, placeId) => {
    this.setState({ address: address });
    this.props.setAddCustomerDetailWizardState({ addressLookup: address });
    try {
      const results = await geocodeByPlaceId(placeId);
      const { lat, lng } = await getLatLng(results[0]);
      const addressComponents: IGoogleMapAddressComponent[] = results[0].address_components.map((addressComponent) => ({
        shortName: addressComponent.short_name,
        longName: addressComponent.long_name,
        postcodeLocalities: addressComponent.postcode_localities,
        types: addressComponent.types,
      }));

      this.setState({
        addressComponents,
        addressTypes: results[0].types,
        selectedLattitude: lat,
        selectedLongitude: lng,
      });
    } catch (error) {
      this.props.setAddCustomerDetailWizardState({ addressLookup: '' });
      this.setState({ customerDetails: { ...this.state.customerDetails, address: null } });
    }
  };

  private extractCustomerAddress = async (addressFields: string[]) => {
    const { form, addCustomerDetailWizardState } = this.props;
    const { addressComponents, customerDetails, isRequireManualAddress, addressTypes, address } = this.state;

    if (addCustomerDetailWizardState.isManualAddress) {
      const addressValues = form.getFieldsValue(addressFields);
      const requiredAddressFields = addressFields.filter((field) => field !== 'streetaddress2');
      const isManualFieldsEmpty = addressFields.every((field) => !addressValues[field]);
      const isSomeManualFieldsError = addressFields.some((field) => form.getFieldError(field));

      // When all manual fields are empty or user select continue without address
      if (!isSomeManualFieldsError && (isManualFieldsEmpty || !isRequireManualAddress)) {
        return undefined;
      }

      const isPostcodeValid = addressValues['postcode'].match(new RegExp('^\\d{4}$'));
      if (!isPostcodeValid || requiredAddressFields.some((field) => !addressValues[field])) {
        this.setState({ isShowConfirmEmptyAddressModal: true });
        throw 'Stop validating'; // to stop validating process
      } else {
        const streetAddress1 = form.getFieldValue('streetaddress');
        const streetAddress2 = form.getFieldValue('streetaddress2');
        const suburb = form.getFieldValue('suburb');
        const state = form.getFieldValue('state');
        const postcode = form.getFieldValue('postcode');
        const country = 'Australia';

        const fulladdress = `${streetAddress1} ${streetAddress2}, ${suburb}, ${state}, ${country}`;
        const latlong = await CommonUtils.getLatLongByAddress(fulladdress);

        return {
          isPrimary: true,
          state: state,
          geoLat: latlong.lat,
          geoLng: latlong.lng,
          country: country,
          locality: suburb,
          postcode: postcode,
          fullAddress: CommonUtils.formatFullAddress({
            streetAddress1,
            streetAddress2,
            locality: suburb,
            state,
            postcode,
            country,
          }),
          streetAddress1,
          streetAddress2,
        };
      }
    } else {
      /** To persist address when user back to step 1 from later steps */
      if (!addressComponents && customerDetails.address) {
        return customerDetails.address;
      }
      if (!addCustomerDetailWizardState.addressLookup) {
        return undefined;
      }
      const postal_code = addressComponents.find((address) => address.types[0] === 'postal_code');

      const country = addressComponents.find((address) => address.types[0] === 'country');

      const province = addressComponents.find((address) => address.types[0] === 'administrative_area_level_1');

      const locality = addressComponents.find((address) => address.types[0] === 'locality');

      const street_number = addressComponents.find((address) => address.types[0] === 'street_number');

      const route = addressComponents.find((address) => address.types[0] === 'route');

      const subpremise = addressComponents.find((address) => address.types[0] === 'subpremise');

      let streetAddress1 = street_number
        ? `${subpremise ? subpremise.longName.replace(/\D/g, '') + '/' : ''}${street_number.longName}`
        : '';

      const validLocationTypes = ['establishment'];

      const isCurrentLocationAddressValid = addressTypes.some((type) => validLocationTypes.includes(type));
      if (isCurrentLocationAddressValid) {
        const rawName = address.split(',')[0].trim();
        const capitalizedName = rawName
          .split(' ')
          .map((word) => _.capitalize(word))
          .join(' ');
        streetAddress1 = `${capitalizedName}${streetAddress1 ? ' ' + streetAddress1 : route ? ',' : ''}`;
      }

      if (route) streetAddress1 += ` ${route.longName}`;

      return {
        isPrimary: true,
        state: province?.shortName ?? '',
        geoLat: this.state.selectedLattitude,
        geoLng: this.state.selectedLongitude,
        country: country?.longName ?? '',
        locality: locality?.longName ?? '',
        postcode: postal_code?.longName ?? '',
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2: '',
          locality: locality?.longName ?? '',
          state: province?.shortName ?? '',
          postcode: postal_code?.longName ?? '',
          country: country?.longName ?? '',
        }),
        streetAddress1,
        streetAddress2: '',
      };
    }
  };

  private validatePostcode = (rule, value, callback) => {
    if (this.state.isRequireManualAddress && !value.match(new RegExp('^\\d{4}$'))) {
      callback(Error('Please enter a postcode'));
      return;
    }
    callback();
  };

  private switchToManualAddress = () => {
    this.props.setAddCustomerDetailWizardState({ isManualAddress: true, addressLookup: '' });
    this.setState({ address: '', customerDetails: { ...this.state.customerDetails, address: null } });
  };

  private switchToLookupAddress = () => {
    this.props.setAddCustomerDetailWizardState({ isManualAddress: false, addressLookup: '' });
    this.setState({ address: '', customerDetails: { ...this.state.customerDetails, address: null } });
  };

  private _changeIsEnquiry = (value) => {
    const { customerDetails } = this.state;

    const newCustomerDetails = { ...customerDetails, isEnquiry: value };

    this.setState({ customerDetails: newCustomerDetails });
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';

    try {
      if (!_.isEmpty(value)) {
        let phoneNumber = parsePhoneNumberFromString(value, region);
        if (!phoneNumber || !phoneNumber.isValid()) {
          throw new Error('Invalid Mobile Number');
        }
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { form, addCustomerDetailWizardState } = this.props;
    const { customerDetails, isCheckingDuplicate, isRequireManualAddress, isShowConfirmEmptyAddressModal } = this.state;
    const { getFieldDecorator } = form;

    return (
      <>
        <ActionModal
          isOpen={isShowConfirmEmptyAddressModal}
          title="Incomplete addresses will not be saved"
          onClose={this.backToValidateManualAddress}
        >
          <Paragraph className="pv-medium">
            Looks like you’ve started filling out your customer’s address. Make sure all fields are completed for the
            address to be saved to your customer’s profile.
          </Paragraph>
          <ActionModalFooter>
            <SecondaryButton size="large" className="mr-medium" onClick={this.backToValidateManualAddress}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this.continueWithoutAddress}>
              Continue without an address
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <div className="anim-slide-left pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph className="mb-medium" size="x3-large" weight="bold">
                  Firstly, a few basic details
                </Paragraph>
                <Stack gap="xl">
                  <Text>
                    We need some basic information about this customer including their name, contact details, NDIS
                    number and primary address.
                  </Text>
                  <Text>
                    If you would like to add this customer as an enquiry please indicate that this is the case.
                  </Text>
                  <Text>
                    Visit the <HyperlinkButton onClick={() => sendToZendeskPortal()}>Help Centre</HyperlinkButton> to
                    learn more.
                  </Text>
                </Stack>
              </div>
            </Col>
            <Col span={18} className="pl-x-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <Row>
                  <Col>
                    <Title className="mb-large" level={4}>
                      Customer information
                    </Title>
                    <Paragraph className="mb-medium">
                      We just need the basics to begin with. Additional information can be added once this record is
                      created.
                    </Paragraph>
                  </Col>
                </Row>
                <div className="mb-large flex-row flex-wrap">
                  <div style={{ maxWidth: '300px' }} className="mr-large">
                    <SubTitle containerClassName="mb-x-small" textClassName="mb-x-small">
                      First Name
                    </SubTitle>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('firstName', {
                        initialValue: customerDetails.firstName,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter a First Name',
                          },
                        ],
                      })(
                        <Input
                          style={{ width: '250px' }}
                          onChange={(value) => this.updateCustomerDetail('firstName', value.target.value)}
                          type="text"
                          size="large"
                          placeholder="John"
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <SubTitle containerClassName="mb-x-small" textClassName="mb-x-small">
                      Last Name
                    </SubTitle>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('lastName', {
                        initialValue: customerDetails.lastName,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter a Last Name',
                          },
                        ],
                      })(
                        <Input
                          style={{ width: '250px' }}
                          onChange={(value) => this.updateCustomerDetail('lastName', value.target.value)}
                          type="text"
                          size="large"
                          placeholder="Smith"
                        />,
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="mb-large flex-row flex-wrap">
                  <div style={{ maxWidth: '300px' }} className="mr-large">
                    <div className="flex-row align-center mb-x-small">
                      <SubTitle>Mobile Number</SubTitle>
                      <Text className="ml-x-small" size="regular" color="secondary">
                        (Optional)
                      </Text>
                    </div>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('mobile', {
                        initialValue: customerDetails.mobile,
                        rules: [
                          {
                            validator: this._validateMobileNumber,
                          },
                        ],
                      })(
                        <Input
                          style={{ width: '250px' }}
                          // type="number"
                          size="large"
                          addonBefore="+61(AU)"
                          onChange={(value) => this.updateCustomerDetail('mobile', value.target.value)}
                          placeholder="000 000 000"
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <div className="flex-row align-center mb-x-small">
                      <SubTitle>Email Address</SubTitle>
                      <Text className="ml-x-small" size="regular" color="secondary">
                        (Optional)
                      </Text>
                    </div>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('email', {
                        initialValue: customerDetails.email,
                        rules: [
                          {
                            type: 'email',
                            message: 'Please enter valid Email',
                          },
                        ],
                      })(
                        <Input
                          size="large"
                          style={{ width: '250px' }}
                          onChange={(value) => this.updateCustomerDetail('email', value.target.value)}
                          placeholder="Enter email..."
                        />,
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="mb-large">
                  <div className="flex-row align-center mb-x-small">
                    <SubTitle>NDIS Number</SubTitle>
                    <Text className="ml-x-small" size="regular" color="secondary">
                      (Optional)
                    </Text>
                  </div>
                  <Form.Item className={'m-none'}>
                    {getFieldDecorator('ndisNumber', {
                      initialValue: customerDetails.ndisNumber,
                      rules: [
                        {
                          pattern: ndisNumberRegex,
                          message: 'Invalid NDIS Number',
                        },
                      ],
                    })(
                      <Input
                        style={{ width: '250px' }}
                        onChange={(value) => this.updateCustomerDetail('ndisNumber', value.target.value)}
                        type="text"
                        size="large"
                        placeholder="Enter NDIS number..."
                      />,
                    )}
                  </Form.Item>
                </div>
                {addCustomerDetailWizardState.isManualAddress ? (
                  <div>
                    <Divider className="mv-large" />
                    <div className="flex-row align-center mb-x-small">
                      <SubTitle>Primary address</SubTitle>
                      <Text className="ml-x-small" size="regular" color="secondary">
                        (Optional)
                      </Text>
                    </div>
                    <div className="mv-large bordered rounded-big p-medium">
                      <div style={{ marginBottom: 18 }}>
                        <SubTitle containerClassName="mb-x-small">Street Address</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('streetaddress', {
                            initialValue: customerDetails.address?.streetAddress1,
                            rules: [
                              {
                                required: isRequireManualAddress,
                                message: 'Please enter a valid address',
                              },
                            ],
                          })(<Input size="large" placeholder="Enter street address..." />)}
                        </FormItem>
                      </div>
                      <div style={{ marginBottom: 18 }}>
                        <SubTitle containerClassName="mb-x-small">Street Address 2</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('streetaddress2', {
                            initialValue: customerDetails.address?.streetAddress2,
                            rules: [
                              {
                                message: 'Street address is required',
                              },
                            ],
                          })(<Input size="large" placeholder="Enter street address 2..." />)}
                        </FormItem>
                      </div>
                      <div style={{ marginBottom: 18 }}>
                        <SubTitle containerClassName="mb-x-small">Suburb</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('suburb', {
                            initialValue: customerDetails.address?.locality,
                            rules: [
                              {
                                required: isRequireManualAddress,
                                message: 'Please enter a valid suburb',
                              },
                            ],
                          })(<Input size="large" style={{ width: 320 }} placeholder="Enter suburb..." />)}
                        </FormItem>
                      </div>
                      <div style={{ marginBottom: 18 }}>
                        <SubTitle containerClassName="mb-x-small">State</SubTitle>
                        <div className="position-relative">
                          <Icon
                            type="search"
                            className="position-absolute text-color-tertiary flex justify-center"
                            style={{ width: 40, top: 11, left: 0, fontSize: 17, zIndex: 1 }}
                          />
                          <FormItem className={'m-none pr-small'}>
                            {getFieldDecorator('state', {
                              initialValue: customerDetails.address?.state,
                              validateTrigger: 'onBlur',
                              rules: [
                                {
                                  required: isRequireManualAddress,
                                  message: 'Please select a state',
                                },
                              ],
                            })(
                              <Select
                                placeholder="Search for..."
                                className="ant-select-with-prefix"
                                style={{ width: 320 }}
                                showSearch={true}
                                optionFilterProp={'children'}
                                size="large"
                              >
                                <Select.Option value={'ACT'}>Australian Capital Territory</Select.Option>
                                <Select.Option value={'NSW'}>New South Wales</Select.Option>
                                <Select.Option value={'NT'}>Northern Territory</Select.Option>
                                <Select.Option value={'QLD'}>Queensland</Select.Option>
                                <Select.Option value={'SA'}>South Australia</Select.Option>
                                <Select.Option value={'TAS'}>Tasmania</Select.Option>
                                <Select.Option value={'VIC'}>Victoria</Select.Option>
                                <Select.Option value={'WA'}>Western Australia</Select.Option>
                              </Select>,
                            )}
                          </FormItem>
                        </div>
                      </div>
                      <div>
                        <SubTitle containerClassName="mb-x-small">Postcode</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('postcode', {
                            initialValue: customerDetails.address?.postcode ?? '',
                            rules: [{ validator: this.validatePostcode }],
                          })(
                            <Input size="large" placeholder="Enter postcode..." style={{ width: 320 }} maxLength={4} />,
                          )}
                        </FormItem>
                      </div>
                    </div>
                    <div className="mb-x4-large">
                      <HyperlinkButton onClick={this.switchToLookupAddress}>Use address lookup tool</HyperlinkButton>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="mb-large">
                      <div className="flex-row align-center mb-x-small">
                        <SubTitle>Primary address lookup</SubTitle>
                        <Text className="ml-x-small" size="regular" color="secondary">
                          (Optional)
                        </Text>
                      </div>
                      <FormItem style={{ marginBottom: 4, width: 524, maxWidth: '100%', minWidth: 250 }}>
                        <PlacesAutocomplete
                          value={this.state.address || addCustomerDetailWizardState.addressLookup}
                          onSelect={this.handleSelect}
                          onChange={this.handleChange}
                          shouldFetchSuggestions={this.state.address.length > 2}
                          searchOptions={searchAutocompleteOptions}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <Icon
                                type="search"
                                className="position-absolute  text-color-tertiary flex align-center justify-center"
                                style={{ height: 44, width: 40, top: 0 }}
                              />
                              <input
                                {...getInputProps({
                                  placeholder: 'Type customer address...',
                                  className: 'location-search-input gray-placeholder width-full pl-x-large ',
                                })}
                                style={{ fontSize: 18 }}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer',
                                        borderTop: '1px ridge grey',
                                        borderLeft: '1px ridge grey',
                                        borderRight: '1px ridge grey',
                                      }
                                    : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer',
                                        borderTop: '1px ridge grey',
                                        borderLeft: '1px ridge grey',
                                        borderRight: '1px ridge grey',
                                      };
                                  return (
                                    <div
                                      key={suggestion.placeId}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>
                                        <Icon type={'environment'} /> {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </FormItem>
                      <HyperlinkButton onClick={this.switchToManualAddress}>Enter address manually</HyperlinkButton>
                    </div>
                    <div style={{ marginBottom: 50 }}>
                      <SubTitle containerClassName="mb-x-small">Selected address</SubTitle>
                      <Text style={{ marginTop: 10 }}>
                        {addCustomerDetailWizardState.addressLookup
                          ? addCustomerDetailWizardState.addressLookup
                          : 'No address selected'}
                      </Text>
                    </div>
                  </>
                )}
                <div className="mb-large">
                  <Col>
                    <Title level={4} className="mb-large">
                      Is this customer an enquiry?
                    </Title>

                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('isEnquiry', {
                        initialValue: customerDetails.isEnquiry,
                        rules: [
                          {
                            required: true,
                            message: 'Please select an option.',
                          },
                        ],
                      })(
                        <Radio.Group onChange={(value) => this._changeIsEnquiry(value.target.value)}>
                          <Radio className="mb-medium" value={false}>
                            No
                          </Radio>
                          <br />
                          <Radio value={true}>Yes</Radio>
                        </Radio.Group>,
                      )}
                    </Form.Item>
                    {form.getFieldValue('isEnquiry') === true && (
                      <Row>
                        <Col className="mt-small">
                          <FieldLabel text="SOURCE (optional)" />
                          <div className="mt-small">
                            {getFieldDecorator('customerSourceId', {
                              initialValue: customerDetails?.customerSourceId,
                            })(
                              <Radio.Group
                                onChange={(event) => this.updateCustomerDetail('customerSourceId', event.target.value)}
                              >
                                {customerSource.map(({ tagId, tag }) => (
                                  <>
                                    <Radio className="mb-medium" key={tagId} value={tagId}>
                                      {tag}
                                    </Radio>
                                    <br />
                                  </>
                                ))}
                              </Radio.Group>,
                            )}
                          </div>
                        </Col>
                        {form.getFieldValue('customerSourceId') === ID_OTHER_CUSTOMER_SOURCE && (
                          <Col>
                            <FieldLabel text="PLEASE SPECIFY OTHER SOURCE" />
                            <FormItem>
                              {getFieldDecorator('customerSource', {
                                initialValue: customerDetails?.customerSource,
                                rules: [
                                  {
                                    required: true,
                                    message: 'Please enter an other source',
                                  },
                                  {
                                    max: 250,
                                    message: 'Please enter less than 250 characters.',
                                  },
                                ],
                              })(
                                <Input
                                  size="large"
                                  style={{ width: 524 }}
                                  placeholder="Enter other customer source here"
                                  onChange={(event) => this.updateCustomerDetail('customerSource', event.target.value)}
                                />,
                              )}
                            </FormItem>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Col>
                </div>
              </div>

              <div className="pv-large width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-medium">
                      <PrimaryButton size="large" loading={isCheckingDuplicate} onClick={this.validateBeforeNextStep}>
                        {isCheckingDuplicate ? 'Checking for duplicates' : 'Next'}
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard,
  addCustomerDetailWizardState: state.customersStore.addCustomerDetailWizardState,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard,
  setAddCustomerDetailWizardState: dispatch.customersStore.setAddCustomerDetailWizardState,
  doFetchAddCustomerFuzzyMatching: dispatch.customersStore.doFetchAddCustomerFuzzyMatching,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IAddCustomerStepCustomerDetailProps>()(AddCustomerStepCustomerDetail));
