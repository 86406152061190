import React, { Dispatch, SetStateAction, useState } from 'react';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { GridRow } from 'common-components/grids';
import { Skeleton, notification } from 'antd';
import { map } from 'lodash';
import { TeamStatus, UserPermissionRole } from 'utilities/enum-utils';
import { TmMgmtPersonColumn } from '../../team-management-columns/tm-mgmt-person-column';
import { TmMgmtTableEmpty } from '../tm-mgmt-table-empty';
import { TmMgmtStatusColumn } from '../../team-management-columns/tm-mgmt-status-column';
import { TmMgmtBusinessAppColumn } from '../../team-management-columns/tm-mgmt-business-app-column';
import { TmMgmtWorkspaceColumn } from '../../team-management-columns/tm-mgmt-workspace-column';
import { TmMgmtOptionMenuColumn } from '../../team-management-columns/tm-mgmt-option-menu-column';
import { TmMgmtLastAccessColumn } from '../../team-management-columns/tm-mgmt-last-access-column';
import { TmMgmtTeamTableActionMenu } from './tm-mgmt-team-table-action-menu';

type TmMgmtTeamTableBodyParams = {
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  setPage: Dispatch<SetStateAction<number>>;
  setSelectedModal: Dispatch<SetStateAction<string>>;
  setModalData: Dispatch<SetStateAction<string>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const TmMgmtTeamTableBody = ({
  page,
  pageSize,
  pageTimestamp,
  setPage,
  setSelectedModal,
  setModalData,
  setIsOpen,
}: TmMgmtTeamTableBodyParams) => {
  const { portalUser } = useSelector((state: IRootState) => state.authStore);
  const { accountTeamList } = useSelector((state: IRootState) => state.teamStore);
  const {
    teamStore: { doFetchAccountTeamList },
  } = useDispatch<IRootDispatch>();
  const [isLoadingInfiniteScrolling, setIsLoadingInfiniteScrolling] = useState(false);

  const getTeamMembers = () => {
    setIsLoadingInfiniteScrolling(true);

    setPage((prevPage) => {
      const newPage = prevPage + 1;

      // eslint-disable-next-line promise/catch-or-return -- handled by finally
      doFetchAccountTeamList({
        page: newPage,
        pageSize: pageSize,
        pageTimestamp: pageTimestamp,
      })
        .catch(() => {
          notification.error({ message: 'Error while fetching team members' });
        })
        .finally(() => setIsLoadingInfiniteScrolling(false));

      return newPage;
    });
  };

  const onOpenModal = (selectedModal: string, modalData = '') => {
    setIsOpen(true);
    setModalData(modalData);
    setSelectedModal(selectedModal);
  };

  const portalUserOwnerRole = portalUser.permissions.permissionRoles.find(
    (role) => role.permissionRole === UserPermissionRole.PrimaryOwner,
  )
    ? UserPermissionRole.PrimaryOwner
    : portalUser.permissions.permissionRoles.find((role) => role.permissionRole === UserPermissionRole.Owner)
    ? UserPermissionRole.Owner
    : portalUser.permissions.permissionRoles.find((role) => role.permissionRole === UserPermissionRole.TeamManager)
    ? UserPermissionRole.TeamManager
    : 999;

  if (accountTeamList.length === 0) {
    return <TmMgmtTableEmpty />;
  }

  if (isLoadingInfiniteScrolling) {
    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />;
  }

  return (
    <InfiniteScrollLoading
      hasMore={accountTeamList.length >= page * pageSize}
      loadingElementId='content-container'
      loadMore={getTeamMembers}
      loaderColSpan={7}
      loadingOffSet={60}
    >
      {map(accountTeamList, (worker, index) => (
        <GridRow key={index} containerClassName={`${worker.status !== TeamStatus.ENABLED && 'bg-quaternary'}`}>
          <TmMgmtPersonColumn
            status={worker.status}
            attachmentUrl={worker.attachmentUrl}
            firstName={worker.firstName}
            lastName={worker.lastName}
            locality={worker?.locality}
            email={worker?.email}
          />
          <TmMgmtStatusColumn status={worker.status} removedOn={worker.removedOn} />
          <TmMgmtBusinessAppColumn status={worker.status} hasWorkerAppAccess={worker.hasWorkerAppAccess} />
          <TmMgmtWorkspaceColumn
            status={worker.status}
            hasPortalAccess={worker.hasPortalAccess}
            ownerRole={worker.ownerRole}
          />
          <TmMgmtLastAccessColumn
            status={worker.status}
            lastVisited={worker.lastVisited}
            isBillable={worker.isBillable}
          />
          <TmMgmtOptionMenuColumn
            status={worker.status}
            menu={
              <TmMgmtTeamTableActionMenu
                worker={worker}
                portalUserOwnerRole={portalUserOwnerRole}
                portalUser={portalUser}
                onOpenModal={onOpenModal}
              />
            }
          />
        </GridRow>
      ))}
    </InfiniteScrollLoading>
  );
};
