import React from 'react';

import { Skeleton, Box, Stack, Loader, Center, type BoxProps } from '@good/ui/core';

type InfiniteScrollLoadingSkeletonProps = {
  animate: boolean;
} & Omit<BoxProps, 'pos'>;

export const InfiniteScrollLoadingSkeleton = ({ animate, ...boxProps }: InfiniteScrollLoadingSkeletonProps) => {
  const h = boxProps.h ?? 400;

  return (
    <tr style={{ position: 'relative', width: '100%' }}>
      <Box w='100%' my='md' {...boxProps} h={h} pos='absolute'>
        <Stack
          w='100%'
          h={h}
          pos='absolute'
          style={{ transition: 'opacity 0.2s ease-in-out' }}
          opacity={animate ? 1 : 0.6}
          gap='sm'
        >
          <Skeleton w='100%' flex={1} animate={animate} />
          <Skeleton w='100%' flex={1} animate={animate} />
          <Skeleton w='100%' flex={1} animate={animate} />
          <Skeleton w='100%' flex={1} animate={animate} />
          <Center
            w='100%'
            style={{ zIndex: 1, transition: 'opacity 0.2s ease-in-out' }}
            opacity={animate ? 1 : 0}
            p='md'
          >
            <Loader type='bars' size='sm' color='gray.3' />
          </Center>
        </Stack>
      </Box>
    </tr>
  );
};
