import React from 'react';
import { Icon } from 'antd';
import { generatePath, useNavigate, useParams } from 'react-router-dom-v5-compat';

import CenteredLayout from 'layouts/CenteredLayout';
import { HyperlinkButton } from 'common-components/buttons';
import { routes as customerRoutes, CustomerDetailNestedRoutes } from 'views/customers/routes';

import { CustomerViewHeader } from './components/customer-view-header';
import { CustomerViewTabs } from './components/customer-view-tabs';

export const CustomerDetailView = (): JSX.Element => {
  const navigate = useNavigate();
  const { userId } = useParams();

  if (!userId) {
    // TODO: Handle this properly
    return <div>Customer not found</div>;
  }

  return (
    <CenteredLayout backgroundColor="gray-200">
      <div className="mb-medium">
        <HyperlinkButton onClick={() => navigate('/customers')}>
          <Icon type="left" /> Customer List
        </HyperlinkButton>
      </div>
      <div className="my-4 flex flex-col divide-y divide-base rounded-lg border border-base bg-white">
        <CustomerViewHeader customerUserId={userId} />
        <CustomerViewTabs>
          {Object.values(customerRoutes).map(({ path, link, title }) => (
            <CustomerViewTabs.Tab key={path} to={generatePath(link, { userId })}>
              {title}
            </CustomerViewTabs.Tab>
          ))}
        </CustomerViewTabs>
      </div>
      <CustomerDetailNestedRoutes />
    </CenteredLayout>
  );
};
