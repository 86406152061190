/* eslint-disable */
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Avatar, Col, DatePicker, Icon, Pagination, Row } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ActionModal from 'common-components/modal/ActionModal';
import FilterUtils from '../../utilities/filter-utils';
import NotificationListingSection from './components/NotificationListingSection';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from '../../common-components/buttons';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { ServiceType, CustomerDetailsTabKey } from 'utilities/enum-utils';
import { StatusTag } from '../../common-components/tags';
import { SubTitle, Text, Title } from 'common-components/typography';

import CustomersGlanceSection from 'views/dashboard/components/CustomerGlanceSection';
import PermissionUtils from 'utilities/permission-utils';
import { Card } from '@good/ui/core';

const LeftPanel = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '58.333%',
  '@media (max-width: 1024px)': {
    width: '100%',
  },
});

const RightPanel = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '41.667%',
  '@media (max-width: 1024px)': {
    width: '100%',
  },
});

const PanelContainer = styled.div({
  display: 'flex',
  gap: '24px',
  flexWrap: 'nowrap',
  margin: '4px auto',
  '@media (max-width: 1024px)': {
    flexWrap: 'wrap',
  },
});

interface BookingRowItemProps {
  bookingDate: string;
  bookingTime: string;
  bookerName: string;
  profileImage: string;
  serviceName: string;
  workerName: string;
  status: string;
  serviceType: string;
}

class BookingRowItem extends Component<BookingRowItemProps> {
  state = { isHovered: false };

  setHovered = () => this.setState({ isHovered: true });
  setUnhovered = () => this.setState({ isHovered: false });

  render() {
    const { bookingDate, bookingTime, bookerName, serviceName, profileImage, workerName, status, serviceType } =
      this.props;

    return (
      <Row
        className='bordered-top ph-large cursor-pointer hover-bg-tertiary'
        type='flex'
        align='middle'
        onMouseEnter={this.setHovered}
        onMouseLeave={this.setUnhovered}
      >
        <Col span={6}>
          <div className='pv-medium'>
            <Text weight={'bold'}>
              {/*Monday, 15 Sep 2019*/}
              {bookingDate}
            </Text>
            <br />
            <Text>
              {/*9.30 AM - 3.30 PM*/}
              {bookingTime}
            </Text>
          </div>
        </Col>
        <Col span={6}>
          <div className='pv-medium'>
            <Avatar icon='user' className='mr-medium' size='large' src={profileImage} />
            <Text weight='black'>
              {/*Andrew Murray*/}
              {bookerName}
            </Text>
          </div>
        </Col>
        <Col span={6}>
          <div className='pv-medium'>
            <Text weight='black' color='secondary'>
              {/*Youth Jobs Now*/}
              {serviceName}
            </Text>
            <br />
            {!_.isEmpty(workerName) ? (
              <>
                <Text color='secondary'>Assigned to </Text>
                <Text weight='black' color='secondary'>
                  {/*Tom Mitchell*/}
                  {workerName}
                </Text>
              </>
            ) : serviceType === ServiceType.INDIVIDUAL ? (
              <Text color='secondary'>Not Assigned </Text>
            ) : (
              <></>
            )}
          </div>
        </Col>
        <Col span={6}>
          <div className='pv-medium text-align-right'>
            <StatusTag status={status} />
          </div>
        </Col>
      </Row>
    );
  }
}

class BookingListingSection extends Component<any, any> {
  state = {
    currentPage: 1,
    pageSize: 10,
    selected: 'PENDING',
    displayList: [],
  };

  onChange = async (page) => {
    this.setState({
      currentPage: page,
    });
  };

  onShowSizeChange = (current, size) => {
    this.setState({ pageSize: size });
  };

  onClickAll = () => {
    const { dashboardData } = this.props;
    this.setState({ selected: 'ALL', displayList: dashboardData.bookingList, currentPage: 1 });
  };

  onClickPending = () => {
    const { dashboardData } = this.props;
    this.setState({ selected: 'PENDING', displayList: dashboardData.pendingList, currentPage: 1 });
  };

  onClickAccepted = () => {
    const { dashboardData } = this.props;
    this.setState({ selected: 'ACCEPTED', displayList: dashboardData.acceptedList, currentPage: 1 });
  };

  onClickCompleted = () => {
    const { dashboardData } = this.props;
    this.setState({ selected: 'COMPLETED', displayList: dashboardData.completedList, currentPage: 1 });
  };

  renderText = () => {
    const { dashboardData } = this.props;
    switch (this.state.selected) {
      case 'ALL':
        return (
          <Text size='large' color='black'>
            You have{' '}
            <span className='text-weight-bold text-weight-black'>
              {dashboardData ? dashboardData.bookingList.length : '-'}
            </span>{' '}
            bookings for your attention.
          </Text>
        );
      case 'PENDING':
        return (
          <Text size='large' color='black'>
            {!_.isEmpty(this.state.displayList) ? (
              <>
                <span className='text-weight-bold text-weight-black'>{this.state.displayList.length}</span> pending
                booking{this.state.displayList.length > 1 ? 's' : ''} requiring approval.
              </>
            ) : (
              <>No pending bookings.</>
            )}
          </Text>
        );
      case 'ACCEPTED':
        return (
          <Text size='large' color='black'>
            {!_.isEmpty(this.state.displayList) ? (
              <>
                <span className='text-weight-bold text-weight-black'>{this.state.displayList.length}</span> accepted
                booking{this.state.displayList.length > 1 ? 's' : ''} without a Team Member confirmed.
              </>
            ) : (
              <>No bookings waiting to be confirmed</>
            )}
          </Text>
        );
      case 'COMPLETED':
        return (
          <Text size='large' color='black'>
            {!_.isEmpty(this.state.displayList) ? (
              <>
                <span className='text-weight-bold text-weight-black'>{this.state.displayList.length}</span> completed
                booking{this.state.displayList.length > 1 ? 's' : ''} not yet billed.
              </>
            ) : (
              <>No bookings waiting to be billed.</>
            )}
          </Text>
        );
      default:
        return <div />;
    }
  };

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    const { dashboardData } = this.props;
    if (!_.isEmpty(dashboardData)) {
      if (prevProps.dashboardData !== dashboardData) {
        this.setState({ displayList: dashboardData.pendingList });
      }
    }
  }

  render() {
    const { dashboardData, portalUser } = this.props;

    return (
      <div>
        <div className='mb-medium ph-large'>
          <Text size='large' color='black'>
            Filter your Bookings by status.
          </Text>
        </div>

        <div className='mb-medium ph-large'>
          {this.state.selected === 'PENDING' ? (
            <PrimaryButton className='mr-medium' onClick={this.onClickPending}>
              Pending ({dashboardData ? dashboardData.pendingList.length : '-'})
            </PrimaryButton>
          ) : (
            <GhostButton className='mr-medium' onClick={this.onClickPending}>
              Pending ({dashboardData ? dashboardData.pendingList.length : '-'})
            </GhostButton>
          )}

          {this.state.selected === 'ACCEPTED' ? (
            <PrimaryButton className='mr-medium' onClick={this.onClickAccepted}>
              Accepted ({dashboardData ? dashboardData.acceptedList.length : '-'})
            </PrimaryButton>
          ) : (
            <GhostButton className='mr-medium' onClick={this.onClickAccepted}>
              Accepted ({dashboardData ? dashboardData.acceptedList.length : '-'})
            </GhostButton>
          )}

          {this.state.selected === 'COMPLETED' ? (
            <PrimaryButton className='mr-medium' onClick={this.onClickCompleted}>
              Completed ({dashboardData ? dashboardData.completedList.length : '-'})
            </PrimaryButton>
          ) : (
            <GhostButton className='mr-medium' onClick={this.onClickCompleted}>
              Completed ({dashboardData ? dashboardData.completedList.length : '-'})
            </GhostButton>
          )}
        </div>

        <div className='mb-medium ph-large'>{this.renderText()}</div>

        {/* Booking Items */}
        {_.chain(this.state.displayList)
          .slice((this.state.currentPage - 1) * this.state.pageSize, this.state.currentPage * this.state.pageSize)
          .map((booking) => {
            return (
              <div onClick={() => this.props.history.push(`/bookings/details/${booking.bookingId}`)}>
                <BookingRowItem
                  bookingDate={moment(booking.startDateTime).format('dddd, d MMM YYYY')}
                  bookingTime={
                    moment(booking.startDateTime).format('h.mm A') +
                    ' - ' +
                    moment(booking.endDateTime).format('h.mm A')
                  }
                  bookerName={booking.firstName + ' ' + booking.lastName}
                  profileImage={booking.attachmentUrl}
                  serviceName={booking.serviceName}
                  workerName={booking.workerFirstName ? booking.workerFirstName + ' ' + booking.lastName : ''}
                  status={booking.status}
                  serviceType={booking.serviceType}
                />
              </div>
            );
          })
          .value()}

        <div className='width-full pt-large bordered-top ph-large'>
          <div className='flex-row justify-between'>
            <div>
              {/* Left Content*/}
              {!_.isEmpty(dashboardData) && (
                <Pagination
                  total={this.state.displayList.length}
                  defaultPageSize={this.state.pageSize}
                  pageSizeOptions={['5', '6', '8', '10']}
                  onChange={this.onChange}
                />
              )}
            </div>
            <div>
              <HyperlinkButton onClick={() => this.props.history.push('/bookings')}>View all bookings</HyperlinkButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CompanyItemRow extends Component<{ companyName: any; customers: any; services: any; rating: any; revenue: any }> {
  render() {
    const { companyName, customers, services, rating, revenue } = this.props;

    return (
      <Link to='/account/company-details' className='underline-none'>
        <Row className='bordered-top pv-medium hover-bg-tertiary cursor-pointer' align='middle' type='flex'>
          <Col span={5} className='pl-medium'>
            <HyperlinkButton fontSize='x2-large' weight='bold'>
              {companyName}
            </HyperlinkButton>
          </Col>
          <Col span={5}>
            <Text size='x-large' weight='bold'>
              {customers}
            </Text>
          </Col>
          <Col span={5} className='pr-medium'>
            <Text size='x-large' weight='bold'>
              {services}
            </Text>
          </Col>
          <Col span={5}>
            <Text size='x-large' weight='bold'>
              {rating}
            </Text>
          </Col>
          <Col span={4} className='text-align-right pr-x3-large'>
            <Text size='x-large'>{revenue}</Text>
          </Col>
        </Row>
      </Link>
    );
  }
}

class CompaniesOverviewSection extends Component {
  render() {
    return (
      <div>
        <div className='mb-x-large ph-medium flex-row justify-between align-center'>
          <Text>
            Here are the latest statistics for the week ending on{' '}
            <span className='text-weight-black text-color-rose'>25 October 2019</span>
          </Text>

          <DatePicker placeholder='Select overview period' />
        </div>

        <div>
          <Row className='pb-small'>
            <Col span={5} className='pl-medium'>
              <Text weight='bold'>Company Name</Text>
            </Col>
            <Col span={5}>
              <Text weight='bold'>Active Customers</Text>
            </Col>
            <Col span={5}>
              <Text weight='bold'>Active Services</Text>
            </Col>
            <Col span={5}>
              <Text weight='bold'>Satisfaction Rating</Text>
            </Col>
            <Col span={4} className='pr-x2-large text-align-right'>
              <Text weight='bold'>MTD Revenue ($)</Text>
            </Col>
          </Row>

          <CompanyItemRow
            companyName={'BUPA Insurance'}
            customers={
              <span>
                309 <Icon type='caret-up' className='ml-small text-color-forest' /> <br />
                <span className='text-size-small text-color-secondary'>(Up from 307)</span>
              </span>
            }
            services={68}
            rating={
              <span>
                86% <br />
                <span className='text-size-small text-color-secondary'>(no change)</span>
              </span>
            }
            revenue={'$1,200,000'}
          />

          <CompanyItemRow
            companyName={'AIA Services'}
            customers={
              <span>
                430 <br />
                <span className='text-size-small text-color-secondary'>(No change)</span>
              </span>
            }
            services={77}
            rating={
              <span>
                78% <Icon type='caret-down' className='ml-small text-color-red' /> <br />
                <span className='text-size-small text-color-secondary'>(Down from 79%)</span>
              </span>
            }
            revenue={'$900,000'}
          />

          <CompanyItemRow
            companyName={'Greenwood Care'}
            customers={
              <span>
                64 <Icon type='caret-up' className='ml-small text-color-forest' /> <br />
                <span className='text-size-small text-color-secondary'>(Up from 50)</span>
              </span>
            }
            services={533}
            rating={
              <span>
                95% <Icon type='caret-up' className='ml-small text-color-forest' /> <br />
                <span className='text-size-small text-color-secondary'>(Up from 80%)</span>
              </span>
            }
            revenue={'$64,000'}
          />

          <CompanyItemRow
            companyName={'Medibank'}
            customers={
              <span>
                55,609 <br />
                <span className='text-size-small text-color-secondary'>(No change)</span>
              </span>
            }
            services={533}
            rating={
              <span>
                77% <Icon type='caret-down' className='ml-small text-color-red' /> <br />
                <span className='text-size-small text-color-secondary'>(Down from 80%)</span>
              </span>
            }
            revenue={'$13,679,000'}
          />

          <CompanyItemRow
            companyName={'Allied Health'}
            customers={
              <span>
                2,110 <Icon type='caret-up' className='ml-small text-color-forest' /> <br />
                <span className='text-size-small text-color-secondary'>(Up from 2,100)</span>
              </span>
            }
            services={533}
            rating={
              <span>
                77.67% <br />
                <span className='text-size-small text-color-secondary'>(no change)</span>
              </span>
            }
            revenue={'$6,200,000'}
          />
        </div>

        <div className='bordered-top ph-medium flex-row justify-between pt-medium'>
          <Text color='tertiary'>
            Inactive companies are hidden from the list above. Click on <b>View All Companies</b> to see them.
          </Text>
          <HyperlinkButton>View All Companies</HyperlinkButton>
        </div>
      </div>
    );
  }
}

class TestDashboardView extends Component<any, any> {
  async componentDidMount() {
    this.props.setSelectedSideNavMenuKeys(['/dashboard']);
    const { doFetchDashboardData, doFetchCustomersAtAGlance, doFetchCompanyLite } = this.props;
    await doFetchCompanyLite([]);
    await doFetchDashboardData();
    await doFetchCustomersAtAGlance();
  }

  onSetBookingFilter = (key) => {
    const { setBookingFilter, history } = this.props;
    if (!_.isEmpty(key)) {
      const filter = [FilterUtils.bookingStatusToFilter('status', [key])];
      setBookingFilter(filter);
    }
    history.push('/bookings');
  };

  private _goToCustomer = async (
    userId: string,
    selectedTab: CustomerDetailsTabKey = CustomerDetailsTabKey.GENERAL_INFO,
  ) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`, { refresh: true, selectedTab });
  };

  render() {
    const { portalUser, dashboardData } = this.props;

    return (
      portalUser && (
        <>
          {/* Welcome Panel */}
          <Row>
            <Col className='bg-white pb-medium'>
              <Row type={'flex'} align={'bottom'}>
                <div className='' style={{ height: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                    <div>
                      <Title level={3} className='mt-none mb-x-small line-height-150' weight='light' font='lato'>
                        Hello,
                        <span className='text-weight-bold'>&nbsp;{portalUser.firstName}!</span>
                      </Title>

                      <Text size='large' className='line-height-150'>
                        You are logged in to
                        <Text weight='bold'>&nbsp;{portalUser.serviceProviderName}</Text>
                      </Text>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>

          {/* Panel Widgets */}
          <PanelContainer>
            {/* Left Panels */}
            <LeftPanel>
              <Row gutter={8}>
                <Col span={24}>
                  {PermissionUtils.validatePermission('ViewBookingListing', portalUser.permissions.permissionRoles) && (
                    <>
                      <div className='mt-x-large mb-small'>
                        <SubTitle>SUMMARY FOR THIS WEEK</SubTitle>
                      </div>

                      <Card withBorder shadow='md' px='md' py='lg'>
                        <Row type={'flex'} style={{ justifyContent: 'space-around', flexWrap: 'nowrap', gap: '16px' }}>
                          <div>
                            <div
                              className='text-align-center'
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.onSetBookingFilter('PENDING')}
                            >
                              <Text className='text-color-secondary letter-spacing-wide' size='regular'>
                                PENDING BOOKINGS
                              </Text>
                              <br />
                              <Text size='x6-large' weight='black' className='line-height-135'>
                                {!_.isEmpty(dashboardData) ? dashboardData.pendingNumber : '--'}
                              </Text>
                            </div>
                          </div>

                          <div>
                            <div
                              className='text-align-center'
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.onSetBookingFilter('CONFIRMED')}
                            >
                              <Text className='text-color-secondary letter-spacing-wide' size='regular'>
                                ACTIVE BOOKINGS
                              </Text>
                              <br />
                              <Text size='x6-large' weight='black' className='line-height-135'>
                                {!_.isEmpty(dashboardData) ? dashboardData.confirmedNumber : '--'}
                              </Text>
                            </div>
                          </div>

                          <div>
                            <div
                              className='text-align-center'
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.onSetBookingFilter('COMPLETED')}
                            >
                              <Text className='text-color-secondary letter-spacing-wide' size='regular'>
                                COMPLETED BOOKINGS
                              </Text>
                              <br />
                              <Text size='x6-large' weight='black' className='line-height-135'>
                                {!_.isEmpty(dashboardData) ? dashboardData.completeNumber : '--'}
                              </Text>
                            </div>
                          </div>
                        </Row>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>

              {PermissionUtils.validatePermission('ViewCustomerListing', portalUser.permissions.permissionRoles) && (
                <>
                  <div className='mt-x-large mb-small'>
                    <SubTitle>CUSTOMERS AT A GLANCE</SubTitle>
                  </div>

                  <Card withBorder shadow='md' px='md' py='lg'>
                    <CustomersGlanceSection
                      customersAtAGlance={this.props.customersAtAGlance}
                      goToCustomer={this._goToCustomer}
                      serviceProviderTimezone={this.props.companyDataLite?.timezone}
                    />
                    {/*<CustomersGlanceDemoSection />*/}
                  </Card>
                </>
              )}
            </LeftPanel>

            {/* Right Panels */}
            <RightPanel>
              <div className='mt-x-large mb-small'>
                <SubTitle>Notifications at a glance</SubTitle>
              </div>
              <Card withBorder shadow='md' px='md' py='lg'>
                <NotificationListingSection />
              </Card>
              <br />
            </RightPanel>
          </PanelContainer>
        </>
      )
    );
  }
}

const mapState = (state: IRootState) => ({
  state: state,
  currentUser: state.authStore.currentUser,
  portalUser: state.authStore.portalUser,
  dashboardData: state.bookingsStore.dashboardData,
  customersAtAGlance: state.customersStore.customersAtAGlance,
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchDashboardData: dispatch.bookingsStore.doFetchDashboardData,
  doFetchCustomersAtAGlance: dispatch.customersStore.doFetchCustomersAtAGlance,
  setBookingFilter: dispatch.bookingsStore.setBookingsFilter,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
});

export default connect(mapState, mapDispatch)(TestDashboardView);
