import { Checkbox, Col, Empty, Row, Skeleton } from 'antd';
import React, { Component } from 'react';
import _ from 'lodash';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { FieldLabel, SubTitle } from 'common-components/typography';
import SessionListItem from './SessionListItem';
import { Text } from 'common-components/typography';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import * as H from 'history';
import { GridHeader, GridRow } from 'common-components/grids';
import CancelSessionModal from '../../session-details/components/CancelSessionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IGroupServiceListItemSectionProps {
  checkAllSessions: boolean;
  isIndeterminate: boolean;
  fetchMoreServices: () => void;
  groupServiceSessions?: typeof state.servicesStore.groupServiceSessions;
  isLoading: boolean;
  page: number;
  pageSize: number;
  setCheckAllSessions: () => void;
  timezone: string;
  history: H.History;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  setSelectedSession: typeof dispatch.groupServiceStore.setSelectedSession;
  OnCheckSession: (session) => void;
}

interface IGroupServiceListItemSectionState {
  isCancelSessionOpen: boolean;
}

const SessionEmptyState = () => (
  <div className="flex-1 mv-x2-large align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <div className="text-align-center" style={{ width: '223px' }}>
      <Text size="x2-large" color="secondary" weight="bold">
        No sessions found.
      </Text>
    </div>
  </div>
);

class GroupServiceSessionListItemSection extends Component<
  IGroupServiceListItemSectionProps,
  IGroupServiceListItemSectionState
> {
  state = {
    isCancelSessionOpen: false,
  };

  private _openCancelSession = (session: IGroupServiceSession) => {
    this.props.setSelectedSession(session);

    this.setState({ isCancelSessionOpen: true });
  };

  private _closeCancelSession = () => {
    this.setState({ isCancelSessionOpen: false });
  };

  render() {
    const { groupServiceSessions, history } = this.props;

    return (
      <div
        className="height-full bg-white"
        style={{ overflowX: 'hidden', position: 'relative' }}
        id="session-item-container"
      >
        <CancelSessionModal isOpen={this.state.isCancelSessionOpen} closeModal={this._closeCancelSession} />
        <GridHeader bordered containerClassName="border-width-small border-secondary ph-none">
          {/*           
          <Col span={1} className=" bg-white">
            <Checkbox defaultChecked={this.props.checkAllSessions} onChange={this.props.setCheckAllSessions} />
          </Col>
          */}

          <Col span={1} className="bg-white">
            <Checkbox
              onClick={this.props.setCheckAllSessions}
              checked={this.props.checkAllSessions}
              indeterminate={this.props.isIndeterminate}
            />
          </Col>
          <Col span={23}>
            <div className="ml-small">
              <Row>
                <Col span={5} className=" bg-white">
                  <FieldLabel text="SESSION DATE" />
                </Col>
                <Col span={4} className=" bg-white pl-medium">
                  <FieldLabel text="STATUS" />
                </Col>
                <Col span={4} className=" bg-white pl-medium">
                  <FieldLabel text="BOOKINGS" />
                </Col>
                <Col span={4} className=" bg-white pl-medium">
                  <FieldLabel text="TEAM MEMBERS" />
                </Col>
                <Col span={5} className=" bg-white pl-medium">
                  <FieldLabel text="SCHEDULE" />
                </Col>
                <Col span={1} className=" bg-white">
                  <FieldLabel text=" " />
                </Col>
              </Row>
            </div>
          </Col>
        </GridHeader>
        <InfiniteScrollLoading
          hasMore={groupServiceSessions.length >= this.props.page * this.props.pageSize}
          loadingElementId={'content-container'}
          loadMore={this.props.fetchMoreServices}
          loaderColSpan={7}
          loadingOffSet={60}
        >
          {!_.isEmpty(groupServiceSessions) &&
            _.map(groupServiceSessions, (session) => (
              <SessionListItem
                sessionItem={session}
                // checkAllSessions={this.props.checkAllSessions}
                timezone={this.props.timezone}
                history={history}
                openCancelSessionModal={this._openCancelSession}
                OnCheckSession={this.props.OnCheckSession}
                key={session.serviceDateTimeId}
              />
            ))}
        </InfiniteScrollLoading>

        {this.props.isLoading && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        )}
        {!this.props.isLoading && _.isEmpty(groupServiceSessions) && <SessionEmptyState />}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  selectedSession: state.groupServiceStore.selectedSession,
  groupServiceSessions: state.servicesStore.groupServiceSessions,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedSession: dispatch.groupServiceStore.setSelectedSession,
});

export default connect(mapState, mapDispatch)(GroupServiceSessionListItemSection);
