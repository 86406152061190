import React from 'react';
import { timeZone } from 'interfaces/timezone-type';
import { Avatar, Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import { DocumentExpiryStatus, CustomerDetailsTabKey } from 'utilities/enum-utils';
import { HyperlinkButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

type CustomerDocument = {
  attachmentUrl: string;
  documentName: string;
  expiryDate: string;
  expiryStatus: DocumentExpiryStatus;
  firstName: string;
  lastName: string;
  locality: string;
  userId: string;
};

type CustomersAtAGlance = {
  expiredDocuments: CustomerDocument[];
  expiringSoonDocuments: CustomerDocument[];
};

interface CustomerRowItemProps {
  documentData: CustomerDocument;
  goToCustomer: (userId: string, selectedTab?: CustomerDetailsTabKey) => void;
  serviceProviderTimezone: timeZone;
}

function CustomerRowItem({ documentData, goToCustomer, serviceProviderTimezone }: CustomerRowItemProps) {
  const rowTitle = `${documentData.firstName} ${documentData.lastName}`;

  return (
    <div className="ph-small bordered-top pv-medium">
      <Row type={'flex'} align="middle" gutter={8}>
        <Col span={8}>
          <div className="align-center flex-row" title={rowTitle}>
            {/* Removed src from avatar to prevent loading for now */}
            <Avatar icon="user" className="mr-medium" />

            <div>
              <Text weight={'bold'}>{rowTitle}</Text>
              <br />
              <Text color={'secondary'}>{documentData.locality}</Text>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="text-align-left" title={documentData.documentName}>
            <Text color={'secondary'}>
              {documentData.expiryStatus === DocumentExpiryStatus.EXPIRED
                ? 'Document expired:'
                : 'Document expiring soon:'}
            </Text>
            <br />
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <Text>{documentData.documentName}</Text>
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div className="text-align-left">
            {documentData.expiryStatus === DocumentExpiryStatus.EXPIRED ? 'Expired ' : 'Expiring '}{' '}
            {moment.tz(documentData.expiryDate, serviceProviderTimezone).format('DD/MM/YYYY')}
          </div>
        </Col>
        <Col span={4}>
          <div className="align-center flex-row justify-end">
            <HyperlinkButton onClick={() => goToCustomer(documentData.userId, CustomerDetailsTabKey.FILES)}>
              View profile
            </HyperlinkButton>
          </div>
        </Col>
      </Row>
    </div>
  );
}

interface CustomersGlanceSectionProps {
  customersAtAGlance?: CustomersAtAGlance;
  goToCustomer: (userId: string, selectedTab?: CustomerDetailsTabKey) => void;
  serviceProviderTimezone?: timeZone;
}

function CustomersGlanceSection({
  customersAtAGlance,
  goToCustomer,
  serviceProviderTimezone,
}: CustomersGlanceSectionProps) {
  const hasExpiredDocuments = customersAtAGlance?.expiredDocuments?.length > 0;
  const hasExpiringSoonDocuments = customersAtAGlance?.expiringSoonDocuments?.length > 0;
  const shouldRenderDocuments = hasExpiredDocuments || hasExpiringSoonDocuments;

  const history = useHistory();

  return (
    <div>
      <div className="mb-medium ph-small">
        <Text>Outstanding items for customers.</Text>
      </div>

      <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '500px' }}>
        {shouldRenderDocuments ? (
          <>
            {customersAtAGlance?.expiredDocuments.map((expiredDocument, index) => (
              <CustomerRowItem
                key={index}
                documentData={expiredDocument}
                goToCustomer={goToCustomer}
                serviceProviderTimezone={serviceProviderTimezone}
              />
            ))}
            <>
              {customersAtAGlance?.expiringSoonDocuments.map((expiringSoonDocument, index) => (
                <CustomerRowItem
                  key={index}
                  documentData={expiringSoonDocument}
                  goToCustomer={goToCustomer}
                  serviceProviderTimezone={serviceProviderTimezone}
                />
              ))}
            </>
          </>
        ) : (
          // TODO: See with product team for the correct sentence and design
          <div className={'pv-medium pl-large'}>No customers with outstanding items.</div>
        )}
      </div>

      <div>
        <Text size="regular" color="secondary">
          For more alerts, visit{' '}
          <HyperlinkButton fontSize="regular" onClick={() => history.push('/customers')}>
            Customers listings
          </HyperlinkButton>
          .
        </Text>
      </div>
    </div>
  );
}

export default CustomersGlanceSection;
