import { events } from 'integrations/appcues';
import {
  ExportStaffAdditionalClaimsData,
  IBrand,
  IDefaultExportFileState,
  IFinanceStyleState,
  INewMemberData,
  IPermissions,
  ITimeSheetStyleState,
} from 'interfaces/account-interfaces';
import { IAlertConfiguration } from 'interfaces/service-provider-interfaces';
import _ from 'lodash';
import Utils from 'utilities/Utils';
import apiClient from 'utilities/api-client';
import { ArchiveDebtorFrom, FilterType, UserPermissionRole } from 'utilities/enum-utils';
import { LogoShape } from 'views/account-management/utils/constants';

// Temporary permissions
function createPermission(permissionRole, serviceDepartmentId, serviceId) {
  return { permissionRole, serviceDepartmentId, serviceId };
}

// Permissions shortcut
const customerViewOnlyPerm = createPermission(UserPermissionRole.CustomerAccessViewOnly, null, null);
const workflowBasicPerm = createPermission(UserPermissionRole.WorkflowBasic, null, null);
const teamNoAccessPerm = createPermission(UserPermissionRole.TeamNoAccess, null, null);

// End Temporary permissions

const defaultPermissions: IPermissions = {
  hasPortalAccess: true,
  hasWorkerAppAccess: false,
  permissionRoles: [customerViewOnlyPerm, teamNoAccessPerm, workflowBasicPerm],
};

const initialNewMemberData: INewMemberData = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  createMode: null,
  permissions: { ...defaultPermissions },
};

const initialAlertConfiguration: IAlertConfiguration = {
  alertConfiguration: {
    documentExpiry: 0,
    serviceAgreementExpiry: 0,
  },
};

const initialFinanceStyleState: IFinanceStyleState = {
  configTypes: [],
  isShowFinanceExportSetupModal: false,
  useDefaultStyle: false,
};

const initialTimeSheetStyleState: ITimeSheetStyleState = {
  configTypes: [],
  isShowTimeSheetSetupModal: false,
  useDefaultStyle: false,
};

const initialDefaultExportFileState: IDefaultExportFileState = {
  accountSystem: {
    name: '',
    key: '',
  },
  payrollSystem: {
    name: '',
    key: '',
  },
};

const editPermissionsData = { permissions: { ...defaultPermissions } };

const formatDebtorCustomerListingFilter = (payload) => {
  let body = { ...payload };

  if (body && body.filters) {
    // Transform the filters array into objects and remove the empty filters.
    const filters = _.chain(_.filter(body.filters, (filter) => !Utils.isEmpty(filter.values)))
      .keyBy('filter')
      .mapValues('values')
      .value();
    // Merge back the filters into the payload
    body = { ...body, ...filters };
    // Delete the 'filters' props to have a lighter payload.
    delete body.filters;
    delete body.debtorId;

    if (body[FilterType.CUSTOMER]) {
      body[FilterType.CUSTOMER] = _.map(body[FilterType.CUSTOMER], (item) => item.value);
    }

    if (body[FilterType.PREFERRED_SUPPORT_WORKER]) {
      body[FilterType.PREFERRED_SUPPORT_WORKER] = _.map(
        body[FilterType.PREFERRED_SUPPORT_WORKER],
        (item) => item.value,
      );
    }

    if (body[FilterType.BLOCKED_SUPPORT_WORKER]) {
      body[FilterType.BLOCKED_SUPPORT_WORKER] = _.map(body[FilterType.BLOCKED_SUPPORT_WORKER], (item) => item.value);
    }

    if (body[FilterType.PINNED_ALERTS]) {
      const isPinnedAlertTrue = !!_.find(body[FilterType.PINNED_ALERTS], (filter) => filter === 'YES');
      const isPinnedAlertFalse = !!_.find(body[FilterType.PINNED_ALERTS], (filter) => filter === 'NO');
      // If both yes and no are selected, remove the filter to get the full list
      if (isPinnedAlertTrue && isPinnedAlertFalse) {
        delete body[FilterType.PINNED_ALERTS];
      } else {
        body[FilterType.PINNED_ALERTS] = isPinnedAlertTrue;
      }
    }

    if (body[FilterType.CUSTOMER_MANAGED_BY]) {
      const isDependent = !!_.find(body[FilterType.CUSTOMER_MANAGED_BY], (filter) => filter === 'YES');
      const isIndependent = !!_.find(body[FilterType.CUSTOMER_MANAGED_BY], (filter) => filter === 'NO');
      // If both yes and no are selected, remove the filter to get the full list
      if (isDependent && isIndependent) {
        delete body[FilterType.CUSTOMER_MANAGED_BY];
      } else {
        body[FilterType.CUSTOMER_MANAGED_BY] = isDependent;
      }
    }
  }
  return body;
};

const accountStore = {
  // State
  state: {
    newMemberData: { ...initialNewMemberData },
    editPermissionsData: { ...editPermissionsData },
    serviceAgreementTemplates: [],
    debtor: null,
    debtors: [],
    debtorCustomers: [],
    debtorInvoices: [],
    timeBasedAlertConfigure: { ...initialAlertConfiguration },
    brandInfo: null,
    financeStyleState: initialFinanceStyleState,
    timeSheetStyleState: initialTimeSheetStyleState,
    defaultExportFileState: initialDefaultExportFileState,
  },

  // Reducers
  reducers: {
    setNewMemberData: (state, newMemberData) => ({ ...state, newMemberData }),
    setEditPermissionsData: (state, editPermissionsData) => ({ ...state, editPermissionsData }),
    setServiceAgreementTemplates: (state, serviceAgreementTemplates) => ({ ...state, serviceAgreementTemplates }),
    editServiceAgreementTemplates: (state, editedServiceAgreementTemplates) => {
      const newServiceAgreementTemplates = _.map(state.serviceAgreementTemplates, (serviceAgreementTemplate) => {
        if (
          serviceAgreementTemplate.termsConditionsTemplateId ===
          editedServiceAgreementTemplates.termsConditionsTemplateId
        ) {
          return { ...serviceAgreementTemplate, ...editedServiceAgreementTemplates };
        }
        return { ...serviceAgreementTemplate };
      });
      return { ...state, serviceAgreementTemplates: newServiceAgreementTemplates };
    },
    deleteServiceAgreementTemplates: (state, deletedServiceAgreementTemplates) => {
      const newServiceAgreementTemplates = _.filter(state.serviceAgreementTemplates, (serviceAgreementTemplate) => {
        return serviceAgreementTemplate.termsConditionsTemplateId !== deletedServiceAgreementTemplates.templateId;
      });
      return { ...state, serviceAgreementTemplates: newServiceAgreementTemplates };
    },
    createServiceAgreementTemplates: (state, addedServiceAgreementTemplates) => {
      const newServiceAgreementTemplates = _.clone(state.serviceAgreementTemplates);
      newServiceAgreementTemplates.push(addedServiceAgreementTemplates);
      return { ...state, serviceAgreementTemplates: newServiceAgreementTemplates };
    },
    setDebtor: (state, debtor) => ({ ...state, debtor }),
    setDebtors: (state, debtors) => ({ ...state, debtors }),
    setDebtorCustomers: (state, debtorCustomers) => ({ ...state, debtorCustomers }),
    setDebtorInvoices: (state, debtorInvoices) => ({ ...state, debtorInvoices }),
    setTimeBasedAlertConfigure: (state, timeBasedAlertConfigure) => ({ ...state, timeBasedAlertConfigure }),
    setBrandInfo: (state, brandInfo: IBrand) => ({ ...state, brandInfo }),
    setFinanceStyleState: (state, payload: IFinanceStyleState) => ({ ...state, financeStyleState: payload }),
    setTimeSheetStyleState: (state, payload: ITimeSheetStyleState) => ({ ...state, timeSheetStyleState: payload }),
    setDefaultStyleState: (state, payload: IDefaultExportFileState) => ({ ...state, defaultExportFileState: payload }),
  },

  // Effects
  effects: (dispatch) => ({
    resetNewMemberData(payload, rootState) {
      dispatch.accountStore.setNewMemberData({ ...initialNewMemberData });
    },

    resetEditMemberData(payload, rootState) {
      dispatch.accountStore.setEditPermissionsData({ ...editPermissionsData });
    },

    async doFetchUserPermissions(payload, rootState) {
      try {
        const result = await apiClient.get(`/api/portal/account/workers/${payload.supportWorkerId}/permissions`);
        this.setEditPermissionsData(result.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchActiveTeamMemberCount(payload, rootState) {
      try {
        const result = await apiClient.get(`/api/portal/account/workers/billable-count`);
        return result.data.count;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchExportCustomerData(payload) {
      const fullUrl = 'api/portal/account/export-customer-data';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchExportBookingData(payload) {
      const fullUrl = 'api/portal/account/export-booking-data';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchExportTeamMemberGeneralInfoData(payload) {
      const fullUrl = 'api/portal/account/export-team-member-general-info-data';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchExportTeamMemberTags(payload) {
      const url = 'api/portal/account/export-team-member-tags';

      try {
        const response = await apiClient.post(url, payload ? payload : {});

        if (response.status === 200) {
          return response.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchExportTeamMemberAvailabilityData(payload) {
      const fullUrl = 'api/portal/account/export-team-member-availability-data';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchExportTeamMemberDocumentData(payload) {
      const fullUrl = 'api/portal/account/export-team-member-document';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchExportStaffAdditionalClaimsData(payload: {
      startDate: string | Date;
      endDate: string | Date;
    }): Promise<ExportStaffAdditionalClaimsData[]> {
      const fullUrl = 'api/portal/account/export-staff-additional-claims-data';

      const resp = await apiClient.post(fullUrl, payload);

      if (resp.status === 200) {
        return resp.data;
      }
    },
    async doFetchExportIncidentData(payload: { startDate: string | Date; endDate: string | Date }) {
      const fullUrl = 'api/portal/account/export-incidents';

      const resp = await apiClient.post(fullUrl, payload);

      if (resp.status === 200) {
        return resp.data;
      }
    },

    async doFetchExportPinnedAlertsData(payload: { startDate: string | Date; endDate: string | Date }) {
      const fullUrl = 'api/portal/account/export-pinned-alerts';

      const resp = await apiClient.post(fullUrl, payload);

      if (resp.status === 200) {
        return resp.data;
      }
    },

    async doGetServiceAgreementTemplates(payload, rootState) {
      try {
        const result = await apiClient.get(`api/portal/service-provider/service-agreement-templates`);
        this.setServiceAgreementTemplates(result.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUpdateServiceAgreementTemplates(payload, rootState) {
      try {
        const result = await apiClient.put(
          `api/portal/service-provider/service-agreement-templates/${payload.templateId}`,
          payload,
        );
        this.editServiceAgreementTemplates(result.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDeleteServiceAgreementTemplates(payload, rootState) {
      try {
        await apiClient.delete(`api/portal/service-provider/service-agreement-templates/${payload.templateId}`);
        this.deleteServiceAgreementTemplates(payload);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCreateServiceAgreementTemplates(payload, rootState) {
      try {
        const result = await apiClient.post(`api/portal/service-provider/service-agreement-templates`, payload);
        this.createServiceAgreementTemplates(result.data);

        events.trackCreateTCTemplate({
          templateName: payload.name,
          paymentSourceType: payload.paymentSourceType,
          data: payload.data,
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doFetchDebtorList(payload, rootState) {
      try {
        const result = await apiClient.post(`api/portal/account/debtors/list`, payload);
        if (payload.page && payload.page > 1) {
          const newDebtorsList = rootState.accountStore.debtors.concat(result.data.managers);
          this.setDebtors(newDebtorsList);
          return newDebtorsList;
        } else {
          this.setDebtors(result.data.managers);
          return result.data.managers;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doAddDebtor(payload, rootState) {
      try {
        const result = await apiClient.post(`api/portal/account/debtors`, payload);
        return result.data.debtorId;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doViewDebtorGeneralInfo(payload, rootState) {
      try {
        const result = await apiClient.get(`api/portal/account/debtors/${payload.debtorId}`);
        this.setDebtor(result.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doEditDebtorGeneralInfo(payload, rootState) {
      try {
        const requestBody = {
          debtorName: payload.debtorName,
          organisationName: payload.organisationName,
          debtorNumber: payload.debtorNumber,
          email: payload.email,
          contactNumber: payload.contactNumber,
          contactNumberCountryCode: payload.contactNumberCountryCode,
          address: payload.address,
          inactive: payload.inactive,
        };
        const result = await apiClient.put(`api/portal/account/debtors/${payload.debtorId}`, requestBody);
        return result;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doViewDebtorCustomerList(payload, rootState) {
      try {
        const requestBody = formatDebtorCustomerListingFilter(payload);
        const result = await apiClient.post(`api/portal/account/debtors/${payload.debtorId}/customers`, requestBody);
        if (payload.page && payload.page > 1) {
          const newCustomersList = rootState.accountStore.debtorCustomers.concat(result.data);
          this.setDebtorCustomers(newCustomersList);
        } else {
          this.setDebtorCustomers(result.data);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doViewDebtorInvoiceList(payload, rootState) {
      try {
        const requestBody = {
          ...payload,
        };
        delete requestBody.debtorId;
        const result = await apiClient.post(`api/portal/account/debtors/${payload.debtorId}/invoices`, requestBody);
        if (payload.page && payload.page > 1) {
          const newCustomersList = rootState.accountStore.debtorCustomers.concat(result.data);
          this.setDebtorInvoices(newCustomersList);
        } else {
          this.setDebtorInvoices(result.data);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doArchiveDebtor(payload, rootState) {
      try {
        const result = await apiClient.delete(`api/portal/account/debtors/${payload.debtorId}`);
        if (result.data) {
          if (payload.archiveDebtorFrom === ArchiveDebtorFrom.DETAILS_PAGE) {
            await this.doViewDebtorGeneralInfo({ debtorId: payload.debtorId });
          } else if (payload.archiveDebtorFrom === ArchiveDebtorFrom.LISTING) {
            const debtors = [...rootState.accountStore.debtors];
            const index = _.findIndex(debtors, (debtor) => debtor.debtorId === payload.debtorId);
            if (index !== -1) {
              debtors[index].isArchived = true;
              this.setDebtors(debtors);
            }
          }
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUnarchiveDebtor(payload, rootState) {
      try {
        const result = await apiClient.put(`api/portal/account/debtors/${payload.debtorId}/unarchive`);
        if (result.data) {
          if (payload.unarchiveDebtorFrom === ArchiveDebtorFrom.DETAILS_PAGE) {
            await this.doViewDebtorGeneralInfo({ debtorId: payload.debtorId });
          }
          return result.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doGetNDIADebtorId() {
      const fullUrl = '/api/portal/account/ndia-debtor-info';

      try {
        const resp = await apiClient.get(fullUrl);

        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doUpdateNDIADebtorId(payload) {
      const fullUrl = '/api/portal/account/ndia-debtor-info';

      try {
        const resp = await apiClient.put(fullUrl, payload);

        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async doFetchBookingFormPdf(payload, rootState) {
      try {
        const endpoint = `/viewpdf/portal/form/view`;
        const result = await apiClient.post(endpoint, {
          attendanceFormId: payload.attendanceFormId,
          serviceDateTimeFormId: payload.serviceDateTimeFormId,
          userFormId: payload.userFormId,
          workflowFormId: payload.workflowFormId,
        });
        return result.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchTimeBasedAlertConfiguration() {
      try {
        const endpoint = 'api/portal/service-provider/alert-configurations';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          await this.setTimeBasedAlertConfigure(result.data);
          return result.data;
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateAlertConfiguration(payload) {
      const fullUrl = 'api/portal/service-provider/alert-configurations';
      try {
        const resp = await apiClient.put(fullUrl, payload);
        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doGetFinanceExportDefaultConfigs(): Promise<void> {
      try {
        const resp = await apiClient.get(`/api/portal/service-provider/finance-configurations`);
        if (resp.data) {
          dispatch.accountStore.setDefaultStyleState(resp.data);
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doGetAccountingSystemList(): Promise<void> {
      try {
        const resp = await apiClient.get(`/api/portal/service-provider/finance-configurations/account-system`);
        if (resp.data) {
          dispatch.accountStore.setFinanceStyleState(resp.data);
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doGetPayrollSystemList(): Promise<void> {
      try {
        const resp = await apiClient.get(`/api/portal/service-provider/finance-configurations/payroll-system`);
        if (resp.data) {
          dispatch.accountStore.setTimeSheetStyleState(resp.data);
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doUpdateDefaultPayrollSystem(
      payload: {
        styleKey: string;
        isShowConfirmDefaultFinance?: boolean;
      },
      rootState,
    ): Promise<void> {
      try {
        await apiClient.put('/api/portal/service-provider/finance-configurations/payroll-system', payload);

        events.trackSetPayrollFileDefault({
          styleKey: payload.styleKey,
        });
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doUpdateDefaultAccountingSystem(
      payload: {
        styleKey: string;
        isShowConfirmDefaultFinance?: boolean;
      },
      rootState,
    ): Promise<void> {
      try {
        await apiClient.put('/api/portal/service-provider/finance-configurations/account-system', payload);

        events.trackSetFinanceFileDefault({
          styleKey: payload.styleKey,
        });
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doGetBrandInfo(): Promise<IBrand> {
      try {
        const response: { data: IBrand } = await apiClient.get(`/api/portal/account/brands`);

        dispatch.accountStore.setBrandInfo(response.data);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    },

    async doUploadCompanyLogo(payload: { formData: FormData; shape: LogoShape }, rootState): Promise<boolean> {
      try {
        const { formData, shape } = payload;
        const endPoint =
          shape === LogoShape.FULL_LOGO ? '/api/portal/account/brands' : '/api/portal/account/brands/square-icons';
        const response = await apiClient.put(endPoint, formData);
        // Appcues
        if (response.data) {
          const brand = await this.doGetBrandInfo();

          events.trackUploadLogo({
            fileName: shape === LogoShape.FULL_LOGO ? brand.originFileName : brand.squareIcon.originFileName,
            attachmentUrl: shape === LogoShape.FULL_LOGO ? brand.attachmentUrl : brand.squareIcon.attachmentUrl,
          });
        }

        return response.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    },

    async doFetchPreviewBrandInvoiceUploadedPdf(): Promise<string> {
      try {
        const response: { data: string } = await apiClient.get(`/viewpdf/portal/brands/preview-pdf-uploaded`);

        return response.data;
      } catch (e) {
        console.log(e);
        return '';
      }
    },

    async doFetchPreviewBrandInvoicePdf(payload: FormData): Promise<string> {
      try {
        const response: { data: string } = await apiClient.post(`/viewpdf/portal/brands/preview-pdf`, payload);

        return response.data;
      } catch (e) {
        console.log(e);
        return '';
      }
    },
  }),
};

export default accountStore;
