import React from 'react';

import { Container, ContainerProps } from '@good/ui/core';

type PageContainerProps = ContainerProps & {
  children: React.ReactNode;
};

export const PageContainer = ({ children, ...containerProps }: PageContainerProps) => {
  return (
    <Container
      px={{ base: 'md', sm: 'lg', md: 'xl' }}
      py={{ base: 'md', md: 'xl' }}
      h='100%'
      w='100%'
      maw={1200}
      id='content-container'
      data-testid='content-container'
      {...containerProps}
    >
      {children}
    </Container>
  );
};
