import React, { useEffect, useState } from 'react';
import { Stack } from '@goodhuman-me/components';
import { notification } from 'antd';
import { Table, TableColumn } from 'design-components';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { initialState, INVITE_MEMBER_ROLE_TYPE } from '../utils/constants';
import { ERROR_TYPES, Member, PermissionRole } from '../utils/form-utils';
import AdvancedPermissionModal from './advanced-permission/AdvancedPermissionModal';
import SelectedCustomerSection from './components/SelectedCustomerSection';
import { addTeamMembersColumns } from './columns/AddTeamMembersColumns';
import { AddTeamMembersFooter } from './components/AddTeamMembersFooter';

const STATE = 'teamMemberList';
const MODE = 'onChange';

const emptyMemberList: Member[] = [];
export const AddTeamMemberFormTable: React.FC = () => {
  const methods = useForm({
    defaultValues: {
      teamMemberList: emptyMemberList,
    },
    mode: MODE,
  });
  const {
    control,
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    trigger,
    formState: { errors, isValid, isValidating, isSubmitting, isDirty },
  } = methods;

  const dispatch = useDispatch<IRootDispatch>();
  const { isCheckingEmail } = useSelector((state: IRootState) => state.teamV2Store);
  const history = useHistory();
  const { append, remove, update } = useFieldArray({ control, name: STATE });
  const teamMemberList = watch(STATE);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectedKeys = teamMemberList.filter((member) => member.isChecked).map((member) => member.memberId);

  const handleAddNewMember = async () => {
    const emailErrors: { row: number; type: typeof ERROR_TYPES; message: string }[] = [];
    (errors.teamMemberList ?? []).forEach((memberErrors, index) => {
      if (
        [ERROR_TYPES.DUPLICATE_EMAILS, ERROR_TYPES.EMAIL_EXISTS].includes(
          memberErrors['email']?.type as unknown as string,
        )
      ) {
        emailErrors.push({
          row: index,
          type: memberErrors['email']?.type as unknown as typeof ERROR_TYPES,
          message: memberErrors['email']?.message as unknown as string,
        });
      }
    });

    await trigger();
    emailErrors.forEach((error) => {
      setError(`teamMemberList.${error.row}.email`, {
        type: error.type as unknown as string,
        message: error.message,
      });
    });
    if (!errors.teamMemberList && !isCheckingEmail) addNewMember();
  };

  const addNewMember = () => {
    const memberId = new Date().getTime().toString();
    append({ ...initialState, memberId });
  };

  const handleRemoveMember = (indexes: number | number[]) => remove(indexes);

  const handleSelectChange = (memberIds: string[]) => {
    teamMemberList.forEach((member, index) => {
      if (!memberIds.length) {
        setValue(`teamMemberList.${index}.isChecked`, false);
      } else {
        const isSelected = memberIds.includes(member.memberId);
        setValue(`teamMemberList.${index}.isChecked`, isSelected);
      }
    });
  };

  const handleUpdateTeamMemberPermissions = (
    roles: string[],
    permissions: PermissionRole[],
    advancedPermissions?: { [key: string]: number },
    serviceDepartmentPermissions?: PermissionRole[],
  ) => {
    teamMemberList.forEach((member, index) => {
      if (member.isChecked) {
        const isSelectNoAccess = roles.includes(INVITE_MEMBER_ROLE_TYPE.NO_ACCESS);
        update(index, { ...member, roles, permissions, advancedPermissions, serviceDepartmentPermissions });
        if (isSelectNoAccess) {
          setValue(`teamMemberList.${index}.hasWorkerAppAccess`, true);
        }
      }
    });
  };

  const handleCheckRowError = () => {
    errors?.teamMemberList?.forEach((errorField, index) => {
      if (errorField) {
        const totalMessage = Object.values(errorField).map((error) => error.message).length;
        const isError = totalMessage > 2;
        setValue(`teamMemberList.${index}.isError`, isError);
      }
    });
  };

  const handleUpdateStatusInviteToApp = (status: boolean) => {
    teamMemberList.forEach((member, index) => {
      if (member.isChecked) {
        const isNoAccess = member.roles.includes(INVITE_MEMBER_ROLE_TYPE.NO_ACCESS);
        if (!isNoAccess) {
          setValue(`teamMemberList.${index}.hasWorkerAppAccess`, status);
        }
      }
    });
  };

  const handleInviteTeamMember = async (sendWithInvitation: boolean): Promise<void> => {
    setIsLoading(true);
    const pluralise = teamMemberList.length > 1 ? 's' : '';

    try {
      if (sendWithInvitation) {
        await dispatch.teamV2Store.doInviteTeamMember(teamMemberList);
      } else {
        await dispatch.teamV2Store.doSkipInviteTeamMember(teamMemberList);
      }

      dispatch.teamV2Store.setPermissionRolesDepartmentRedux([]);
      history.push('/team');

      const notifDescription = sendWithInvitation
        ? {
            message: `Team member${pluralise} successfully added`,
          }
        : {
            message: `Team member${pluralise} successfully added`,
            description: `Invite${pluralise} ${teamMemberList.length > 1 ? 'have' : 'has'} not been sent out to ${
              teamMemberList.length > 1 ? 'these' : 'this'
            } user${pluralise}. Invite ${teamMemberList.length > 1 ? 'them' : 'user'} to GoodHuman in Team Management`,
          };

      notification.success(notifDescription);
    } catch (error) {
      notification.error({
        message: `Unable to add team member${pluralise} `,
        description: error.data,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns: TableColumn<Member>[] = addTeamMembersColumns(control, register, trigger, methods, teamMemberList);

  useEffect(() => {
    if (!teamMemberList.length) {
      addNewMember();
    }
  }, [teamMemberList]);

  useEffect(() => {
    handleCheckRowError();
  }, [isValidating, isSubmitting]);

  const isButtonDisabled: boolean = !!errors.teamMemberList || !isDirty || !isValid || isCheckingEmail;

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises -- special case */}
      <form onSubmit={handleSubmit(() => handleInviteTeamMember(true))}>
        <Stack gap="$space300" marginTop="$space300">
          <SelectedCustomerSection
            teamMemberList={teamMemberList}
            removeSelected={() => handleSelectChange([])}
            deleteMember={handleRemoveMember}
            updateStatusInviteToApp={handleUpdateStatusInviteToApp}
            updateTeamMemberPermissions={handleUpdateTeamMemberPermissions}
          />
          <div className={`${isLoading ? 'disabled' : ''} overflow-auto`}>
            <Table
              enableSelect
              columns={columns}
              selectedKeys={selectedKeys}
              selectStyles={{ width: 52, padding: '0 15px' }}
              dataSources={teamMemberList.map((member, index) => ({ ...member, key: member.memberId, index }))}
              onSelectionChange={handleSelectChange}
            />
          </div>
          <AddTeamMembersFooter
            handleAddNewMember={handleAddNewMember}
            isButtonDisabled={isButtonDisabled}
            isLoading={isLoading}
            teamMembersCount={teamMemberList.length}
            onSubmit={handleInviteTeamMember}
          />
        </Stack>
      </form>
      <AdvancedPermissionModal
        update={update}
        teamMemberList={teamMemberList}
        updateTeamMemberPermissions={handleUpdateTeamMemberPermissions}
      />
    </>
  );
};
