import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { ServiceType } from 'utilities/enum-utils';
import ServiceStatusTag from 'common-components/tags/ServiceStatusTag';
import { BookingTypeIcon } from 'common-components/tags';

export type ServiceListItemModel = {
  serviceId: string;
  serviceName: string;
  status: string;
  serviceDepartmentName: string;
  serviceType: ServiceType;
  attachmentUrl: string;
  serviceDescription: any;
};

export type ServiceListItemProps = {
  data: ServiceListItemModel;
  handleItemClick: (id: string, serviceType: ServiceType) => void;
};

export default class ServiceListItem extends Component<ServiceListItemProps> {
  private _getServiceTypeText = (serviceType: ServiceType) => {
    switch (serviceType) {
      case ServiceType.GROUP:
        return 'Group service';
      case ServiceType.INDIVIDUAL:
        return 'Support service';
      case ServiceType.COORDINATION:
        return 'Support coordination';
      case ServiceType.INDEPENDENT_LIVING:
        return ' Supported independent living';
    }
  };

  private _getDefaultServiceImage = (serviceType: ServiceType) => {
    switch (serviceType) {
      case ServiceType.GROUP:
        return '/assets/services/group-service.png';
      case ServiceType.INDIVIDUAL:
        return '/assets/services/support-service.png';
      case ServiceType.COORDINATION:
        return '/assets/services/support-coordination-service.png';
      case ServiceType.INDEPENDENT_LIVING:
        return '/assets/services/SIL.png';
    }
  };

  override render() {
    const { handleItemClick, data } = this.props;
    const { serviceId, status, serviceName, serviceDepartmentName, serviceType, serviceDescription } = data;

    return (
      <tr onClick={() => handleItemClick(serviceId, serviceType)} className={`anim-fade-in evenodd bg-white`}>
        <td className='width-1/2'>
          <div className={'align-center flex-row'}>
            <div className={'mr-medium'}>
              <img
                className='rounded object-cover '
                width={52}
                height={52}
                style={{ width: 52, minWidth: 52, height: 52 }}
                alt='Service image'
                src={this.props.data?.attachmentUrl ?? this._getDefaultServiceImage(serviceType)}
              />
            </div>
            <div className='overflow-line-clamp-3 mr-medium line-height-135'>
              <Text size={'x2-large'} lineHeight={135}>
                {serviceName}
              </Text>
              <br />
              <Text color={'secondary'} size={'regular'} lineHeight={135}>
                {serviceDescription}
              </Text>
            </div>
          </div>
        </td>
        <td className={'width-1/6'}>
          <Text size='large' style={{ whiteSpace: 'nowrap' }}>
            <ServiceStatusTag status={status} size='small' />
          </Text>
        </td>
        <td className={'width-1/6'}>
          <div className={'align-center flex-row'}>
            <BookingTypeIcon type={serviceType} className={'mr-small mv-x-small'} />
            <div>
              <Text size='large' style={{ whiteSpace: 'nowrap' }}>
                {this._getServiceTypeText(serviceType)}
              </Text>
            </div>
          </div>
        </td>
        <td className={'width-1/6'}>
          <Text size='large' style={{ whiteSpace: 'nowrap' }}>
            {serviceDepartmentName}
          </Text>
        </td>
      </tr>
    );
  }
}
