import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Heading, Text } from '@good/components';
import { BreadcrumbItem, Breadcrumbs } from '@goodhuman-me/components';

const PayPeriodConfigurationView = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'accountManagement.payPeriodsPage' });

  return (
    <div className='flex-column row-gap-x-large'>
      <div
        className='flex-column pl-x-large pr-x-large pt-small pb-large relative flex'
        id='work-leave-types-configuration-view'
        style={{ gap: 24 }}
      >
        <Breadcrumbs>
          <BreadcrumbItem>
            <Link to='/account/landing'>Account management</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/account/features'>Feature configuration</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to='/account/features/finance-file-export-configuration'>Finance file export</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span>{t('heading')}</span>
          </BreadcrumbItem>
        </Breadcrumbs>
        <div className='flex-column mb-small flex' style={{ gap: 4 }}>
          <Heading size='xl'>{t('heading')}</Heading>
          <Text className='text-weak' size='sm'>
            {t('description')}
          </Text>
        </div>
      </div>
    </div>
  );
};
// eslint-disable-next-line import/no-default-export -- The authorised routes currently need a default set
export default PayPeriodConfigurationView;
