import { Divider, Icon, List, notification, Popover, Skeleton } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Title } from 'common-components/typography';
import * as H from 'history';
import React, { useCallback, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { IBillingAccessMember } from 'src/interfaces/subscription-interfaces';
import { IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import AddBillingAccessModal from '../components/AddBillingAccessModal';
import BillingContactItem from '../components/BillingContactItem';
import RemoveBillingAccessModal from '../components/RemoveBillingAccessModal';
interface IBillingAccessSectionProps {
  history: H.History;
}

const BillingAccessSection: React.FC<IBillingAccessSectionProps> = ({ history }) => {
  const dispatch = useDispatch<IRootDispatch>();
  const { primaryOwnerInfo, billingAccesslist, portalUser } = useSelector((state: IRootState) => ({
    primaryOwnerInfo: state.subscriptionStore.subscriptionOverview?.primaryOwnerInfo,
    billingAccesslist: state.subscriptionStore.accessBillingContactTeamMembers,
    portalUser: state.authStore.portalUser,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAddBillingAccessModal, setIsOpenAddBillingAccessModal] = useState(false);
  const [isOpenRemoveBillingAccessModal, setIsOpenRemoveBillingAccessModal] = useState(false);
  const [removingAccess, setRemovingAccess] = useState<IBillingAccessMember>(null);

  const [primaryOwner, memberBillingAccessList] = billingAccesslist.reduce(
    ([primaryOwner, memberBillingAccessList], member) => {
      member.userId === primaryOwnerInfo?.userId ? primaryOwner.push(member) : memberBillingAccessList.push(member);
      return [primaryOwner, memberBillingAccessList];
    },
    [[], []],
  );

  const isAllowAddBillingAccess = PermissionUtils.validatePermission(
    'AddBillingAccess',
    portalUser.permissions.permissionRoles,
  );

  const isAllowRemoveBillingAccess = PermissionUtils.validatePermission(
    'RemoveBillingAccess',
    portalUser.permissions.permissionRoles,
  );

  const _openAddBillingAccessModal = () => {
    setIsOpenAddBillingAccessModal(true);
  };

  const _handleOpenRemoveBillingAccess = (contact: IBillingAccessMember) => {
    setIsOpenRemoveBillingAccessModal(true);
    setRemovingAccess(contact);
  };

  const _getBillingAccessList = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch.subscriptionStore.doGetBillingAccessList();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
    }
    setIsLoading(false);
  }, [dispatch]);

  const _renderAccessList = (billingAccessList: IBillingAccessMember[], isRenderPrimaryOwner?: boolean) => {
    if (!billingAccessList.length) {
      return null;
    }

    return (
      <div>
        <SubTitle size="small" weight="bolder" containerClassName="mt-small">
          {isRenderPrimaryOwner ? 'Primary billing contact' : 'Billing Access'}
          {isRenderPrimaryOwner && (
            <Popover
              placement="topRight"
              content={
                <div style={{ maxWidth: 400 }} className="m-none">
                  <Paragraph>Primary Owners are the default primary billing contacts in GoodHuman.</Paragraph>
                  <Paragraph>
                    They receive communication about billing issues as they arise and are able to manage access to the
                    billing and subscription area of GoodHuman.
                  </Paragraph>
                </div>
              }
            >
              <Icon type="question-circle" className="font-size-small ml-small" />
            </Popover>
          )}
        </SubTitle>

        <List
          dataSource={billingAccessList}
          renderItem={(contact: IBillingAccessMember) => {
            return (
              <>
                <BillingContactItem
                  contact={contact}
                  hasRemovePermission={isAllowRemoveBillingAccess}
                  isPrimaryOwner={isRenderPrimaryOwner}
                  onOpenRemoveBillingAccess={() => _handleOpenRemoveBillingAccess(contact)}
                />
              </>
            );
          }}
        />
      </div>
    );
  };

  return (
    <div className="pv-large ph-x-large rounded bordered">
      {isOpenAddBillingAccessModal && (
        <AddBillingAccessModal
          history={history}
          isOpen={isOpenAddBillingAccessModal}
          onClose={() => setIsOpenAddBillingAccessModal(false)}
          onSuccess={_getBillingAccessList}
        />
      )}
      <RemoveBillingAccessModal
        isOpen={isOpenRemoveBillingAccessModal}
        onClose={() => setIsOpenRemoveBillingAccessModal(false)}
        removingAccess={removingAccess}
        onSuccess={_getBillingAccessList}
      />
      <div className="flex justify-between align-baseline pb-small">
        <Title level={4}>Billing access</Title>
        {isAllowAddBillingAccess && (
          <HyperlinkButton onClick={_openAddBillingAccessModal}>
            <Icon type="plus" /> Grant access
          </HyperlinkButton>
        )}
      </div>
      <ScrollContainer hideScrollbars={false} style={{ maxHeight: 350 }}>
        {isLoading ? (
          <>
            <Skeleton title={false} active />
            <Skeleton title={false} active />
            <Skeleton title={false} active />
          </>
        ) : (
          <>
            {_renderAccessList(primaryOwner, true)}
            {!!memberBillingAccessList.length && <Divider className="mt-x-small mb-none" />}
            {_renderAccessList(memberBillingAccessList)}
          </>
        )}
      </ScrollContainer>
    </div>
  );
};

export default BillingAccessSection;
