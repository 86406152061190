import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';

/**
 * Custom hook that updates the page title based on the current location and attaches a suffix.
 * @returns The updated page title without the suffix
 */
export function useUpdatePageTitle(suffix?: string): string {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState<string>('');

  const updatePageTitle = useCallback(() => {
    const title = location.pathname.replace('/', '').replace('-', ' ');
    const capitalizedTitle = capitalize(title);
    document.title = [capitalizedTitle, suffix].filter(Boolean).join(' | ');
    setPageTitle(capitalizedTitle);
  }, [location, suffix]);

  useEffect(() => {
    updatePageTitle();
  }, [updatePageTitle, location]);

  return pageTitle;
}
