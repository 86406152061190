import React, { useEffect } from 'react';
import { useScroll, useThrottleFn } from 'react-use';

/**
  This component is used to fetch the next set of results when the user scrolls to the bottom of the given component,
  particularly useful for infinite scrolling with ReactQuery/tRPC's `useInfiniteQuery`.
*/
export const InfiniteScrollHandler = ({
  scrollRef,
  fetchFn,
  isFetching,
}: { scrollRef: React.RefObject<HTMLDivElement>; fetchFn: () => void; isFetching?: boolean }) => {
  const { y } = useScroll(scrollRef);

  const isAtBottom = (currentY: number) => {
    if (y === 0 || !scrollRef.current || isFetching) return false;
    return Math.ceil(currentY + scrollRef.current.clientHeight) + 1 >= scrollRef.current.scrollHeight;
  };
  const throttledIsAtBottom = useThrottleFn(isAtBottom, 100, [y]);

  useEffect(() => {
    if (throttledIsAtBottom) fetchFn();
  }, [fetchFn, throttledIsAtBottom]);

  return null;
};
