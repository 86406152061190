import { ProgressBar } from '@blueprintjs/core';
import { Col, Icon, Input, Skeleton } from 'antd';
import { FilterSection } from 'common-components/filter';
import { GridHeader, GridRow } from 'common-components/grids';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import ActiveWorkflowTemplateStatusCountTag from 'common-components/tags/ActiveWorkflowTemplateStatusCountTag';
import ActiveWorkflowStatusTag from 'common-components/tags/ActiveWorkflowTemplateStatusTag';
import { SubTitle, Text } from 'common-components/typography';
import { IWorkflowTemplate } from 'interfaces/workflow-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { withRouter, WithRouterProps } from 'utilities/with-router';
import WorkflowListingEmptyState from 'views/workflows/listing/components/WorkflowListingEmptyState';
import {
  availableWorkflowListingFilters,
  defaultWorkflowListingFilterParams,
  defaultWorkflowListingFilters,
} from 'views/workflows/utils/constants';
import { formatWorkflowListingFilters } from 'views/workflows/utils/workflow-utils';

const { Search } = Input;

interface IWorkflowTemplateDetailActivePanelProps extends WithRouterProps {
  selectedWorkflowTemplate: IWorkflowTemplate;
  portalUser: typeof state.authStore.portalUser;
  activeWorkflowList: typeof state.workflowStore.activeWorkflowList;
  activeStatusWorkflowTotal: typeof state.workflowStore.activeStatusWorkflowTotal;
  doGetActiveStatusWorkflowTotal: typeof dispatch.workflowStore.doGetActiveStatusWorkflowTotal;
  doGetActiveWorkflowList: typeof dispatch.workflowStore.doGetActiveWorkflowList;
}
interface IWorkflowTemplateDetailActivePanelState {
  isSearching: boolean;
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  activeWorkflowFilters: any;
}

class WorkflowTemplateDetailActivePanel extends PureComponent<
  IWorkflowTemplateDetailActivePanelProps,
  IWorkflowTemplateDetailActivePanelState
> {
  state = {
    isSearching: false,
    isLoading: false,
    isLoadingInfiniteScrolling: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    activeWorkflowFilters: defaultWorkflowListingFilters,
  };

  private _searchText = async (value) => {
    const { activeWorkflowFilters } = this.state;
    const { selectedWorkflowTemplate } = this.props;
    const { workflowTemplateId, workflowTemplateUniqueId } = selectedWorkflowTemplate;

    const filters = formatWorkflowListingFilters(activeWorkflowFilters);

    this.setState({ isSearching: true, isLoading: true, searchString: value });

    const payload = {
      searchString: value,
      ...defaultWorkflowListingFilterParams,
      ...filters,
      workflowTemplateId,
      workflowTemplateUniqueId,
    };

    console.log(payload);
    await this.props.doGetActiveWorkflowList(payload);
    this.props.doGetActiveStatusWorkflowTotal(payload);

    this.setState({ isSearching: false, page: 1, isLoading: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _onChangeFilter = async (filters: Array<any>) => {
    this.setState({ activeWorkflowFilters: filters });
  };

  private _fetchMoreActiveWorkflows = async () => {
    this.setState({ isLoadingInfiniteScrolling: true });

    const { selectedWorkflowTemplate, doGetActiveWorkflowList } = this.props;
    const { page, pageSize, searchString, activeWorkflowFilters } = this.state;
    const { workflowTemplateId, workflowTemplateUniqueId } = selectedWorkflowTemplate;
    const nextPage = page + 1;
    const formattedFilters = formatWorkflowListingFilters(activeWorkflowFilters);

    await doGetActiveWorkflowList({
      page: nextPage,
      pageSize,
      searchString,
      workflowTemplateId,
      workflowTemplateUniqueId,
      ...formattedFilters,
    });

    this.setState({ page: nextPage, isLoadingInfiniteScrolling: false });
  };

  private _onClickRow = (workflowId) => {
    const { history } = this.props;
    history.push(`/workflows/details/${workflowId}`);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { searchString, activeWorkflowFilters } = this.state;
    const { doGetActiveWorkflowList, doGetActiveStatusWorkflowTotal, selectedWorkflowTemplate } = this.props;
    const { workflowTemplateId, workflowTemplateUniqueId } = selectedWorkflowTemplate;

    if (!_.isEqual(prevState.activeWorkflowFilters, activeWorkflowFilters)) {
      this.setState({ isLoading: true, page: 1 });

      const formattedFilters = formatWorkflowListingFilters(activeWorkflowFilters);
      const payload = {
        ...defaultWorkflowListingFilterParams,
        ...formattedFilters,
        searchString,
        workflowTemplateId,
        workflowTemplateUniqueId,
      };

      await doGetActiveWorkflowList(payload);
      doGetActiveStatusWorkflowTotal(payload);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    const { selectedWorkflowTemplate, doGetActiveWorkflowList, doGetActiveStatusWorkflowTotal } = this.props;
    const { workflowTemplateId, workflowTemplateUniqueId } = selectedWorkflowTemplate;
    const defaultFilters = formatWorkflowListingFilters(defaultWorkflowListingFilters);
    const payload = {
      ...defaultWorkflowListingFilterParams,
      ...defaultFilters,
      workflowTemplateId,
      workflowTemplateUniqueId,
    };

    await doGetActiveWorkflowList(payload);
    doGetActiveStatusWorkflowTotal(payload);
    this.setState({ isLoading: false });
  };

  render() {
    const { portalUser, activeWorkflowList, activeStatusWorkflowTotal } = this.props;
    const { isSearching, isLoading, isLoadingInfiniteScrolling, activeWorkflowFilters, page, pageSize } = this.state;

    return (
      <div className="workflow-detail-active-tab">
        <Text size="x2-large" weight="bolder" className="mb-small inline-block">
          Active workflows
        </Text>
        <div className="flex-row align-baseline">
          <Search
            placeholder="Search for a workflow"
            onChange={this._onSearchText}
            loading={isSearching}
            style={{ width: '320px', height: '32px' }}
            allowClear={true}
            className="mr-medium"
          />

          <FilterSection
            availableFilters={availableWorkflowListingFilters}
            filters={activeWorkflowFilters}
            onChangeFilter={this._onChangeFilter}
            displayTimezone={portalUser.timezone || null}
            containerClassName="pv-none"
            displayMoreFilter={true}
          />
        </div>
        <div className="flex mv-large">
          {activeStatusWorkflowTotal &&
            activeStatusWorkflowTotal.map((status) => (
              <ActiveWorkflowTemplateStatusCountTag
                key={status.status}
                total={status.numberOfWorkflows}
                type={status.status}
                className="mr-medium"
              />
            ))}
        </div>

        <div className="bg-white">
          <GridHeader bordered containerClassName="border-secondary">
            <Col span={7}>
              <SubTitle containerClassName="line-height-135">Date</SubTitle>
            </Col>
            <Col span={6}>
              <SubTitle containerClassName="line-height-135">Unique ID</SubTitle>
            </Col>
            <Col span={7}>
              <SubTitle containerClassName="line-height-135">STATUS</SubTitle>
            </Col>
          </GridHeader>
          {isLoading ? (
            <div>
              <div className="pv-large">
                <ProgressBar />
              </div>
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
              <Skeleton active title={true} paragraph={{ rows: 1 }} />
            </div>
          ) : !_.isEmpty(activeWorkflowList) ? (
            <InfiniteScrollLoading
              hasMore={activeWorkflowList.length >= page * pageSize}
              loadingElementId={'content-container'}
              loadMore={this._fetchMoreActiveWorkflows}
              loaderColSpan={4}
              loadingOffSet={60}
            >
              {activeWorkflowList.map((workflow, index) => {
                return (
                  <GridRow
                    containerClassName={`${workflow.isFlagged ? 'bg-red-lightest' : 'bg-white'} hover-bg-quaternary`}
                    key={index}
                    onClick={() => this._onClickRow(workflow.workflowId)}
                  >
                    <Col span={7}>
                      <div className="text-weight-bolder">
                        {moment.tz(workflow.createdOn, portalUser.timezone).fromNow()}
                      </div>
                      <div className="text-weight-regular">
                        {moment.tz(workflow.createdOn, portalUser.timezone).format('hh:mm A, D MMMM YYYY')}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="text-color-info-blue">
                        {_.toUpper(workflow.referenceId)} (V{workflow.version})
                      </div>
                    </Col>
                    <Col span={7}>
                      <div className="flex align-center">
                        <ActiveWorkflowStatusTag status={workflow.status} />
                        {workflow.isFlagged && (
                          <>
                            <Icon type="flag" className="mr-12 text-color-red" theme="filled" />
                            <Text className="text-weight-regular" size="x-small">
                              Issue flagged
                            </Text>
                          </>
                        )}
                      </div>
                    </Col>
                  </GridRow>
                );
              })}
            </InfiniteScrollLoading>
          ) : (
            <div className="pb-large">
              <WorkflowListingEmptyState />
            </div>
          )}
        </div>
        {isLoadingInfiniteScrolling && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  activeWorkflowList: state.workflowStore.activeWorkflowList,
  activeStatusWorkflowTotal: state.workflowStore.activeStatusWorkflowTotal,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetActiveWorkflowList: dispatch.workflowStore.doGetActiveWorkflowList,
  doGetActiveStatusWorkflowTotal: dispatch.workflowStore.doGetActiveStatusWorkflowTotal,
});

export default connect(mapState, mapDispatch)(withRouter(WorkflowTemplateDetailActivePanel));
