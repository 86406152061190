import { Tabs } from 'antd';
import { Title } from 'common-components/typography';
import * as H from 'history';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import AccessDeniedView from 'views/exceptions/AccessDeniedView';
import PaymentFailedBanner from './components/PaymentFailedBanner';
import BillingHistoryPanel from './components/tabs-panel/billing-history/BillingHistoryPanel';
import OverviewPanel from './components/tabs-panel/overview/OverviewPanel';
import PaymentMethodPanel from './components/tabs-panel/payment-method/PaymentMethodPanel';
import { SUBSCRIPTION_MANAGEMENT_TABS, SUBSCRIPTION_MANAGEMENT_TAB_KEY } from './utils/constants';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

interface ISubscriptionManagementViewProps {
  history: H.History;
}

const SubscriptionManagementView: React.FC<ISubscriptionManagementViewProps> = ({ history }) => {
  const [selectedTab, setSelectedTab] = useState<string>(SUBSCRIPTION_MANAGEMENT_TAB_KEY.OVERVIEW);

  const { portalUser, companyDataLite } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    companyDataLite: state.companyStore.companyDataLite,
  }));

  const hasSubscriptionPermission = companyDataLite?.hasSubscription;

  const _onChangeTab = (tab: string) => {
    setSelectedTab(tab);
  };

  const _renderTab = () => {
    switch (selectedTab) {
      case SUBSCRIPTION_MANAGEMENT_TAB_KEY.OVERVIEW:
        return <OverviewPanel history={history} />;
      case SUBSCRIPTION_MANAGEMENT_TAB_KEY.PAYMENT_METHOD:
        return <PaymentMethodPanel history={history} />;
      case SUBSCRIPTION_MANAGEMENT_TAB_KEY.BILLING_HISTORY:
        return <BillingHistoryPanel />;
    }
  };

  const subscriptionTitle = `${portalUser?.serviceProviderName}'${
    _.endsWith(portalUser?.serviceProviderName, 's') ? '' : 's'
  } GoodHuman subscription`;

  if (companyDataLite && !hasSubscriptionPermission) {
    return <AccessDeniedView history={history} />;
  }

  return (
    <>
      <Title level={3} weight='regular' className='mv-none mb-x2-large'>
        <Link to='landing'>
          <span className={'cursor-pointer text-color-blue-action'}>Account &gt;</span>
        </Link>
        &nbsp;
        {subscriptionTitle}
      </Title>

      <div className='width-fit-content mb-small'>
        <Tabs activeKey={selectedTab} animated onChange={_onChangeTab}>
          {SUBSCRIPTION_MANAGEMENT_TABS.map((tab) => (
            <TabPane tab={tab.title} key={tab.key} />
          ))}
        </Tabs>
      </div>
      <PaymentFailedBanner />
      {_renderTab()}
    </>
  );
};

export default SubscriptionManagementView;
