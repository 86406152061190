import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Col, Divider, Row } from 'antd';
import { CompatRoute as Route } from 'react-router-dom-v5-compat';
import { connect } from 'react-redux';

import NotificationUtils from 'utilities/notification-utils';
import { HyperlinkButton, SecondaryButton } from 'common-components/buttons';
import { INotification } from 'interfaces/notification-interfaces';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Text } from 'common-components/typography';

class NotificationItemRow extends Component<{
  notification: INotification;
  onDimissNotification: (notificationId: string) => {};
}> {
  private _getCreatedOnDisplayFormat = (createdOn) => {
    let now = moment();
    let createdOnDate = moment(createdOn);

    let duration = now.diff(createdOnDate, 'days');

    if (duration > 0) {
      return `${duration} day(s) ago`;
    } else {
      duration = now.diff(createdOnDate, 'hours');

      if (duration > 0) {
        return `${duration} hour(s) ago`;
      } else {
        duration = now.diff(createdOnDate, 'minutes');
        if (duration > 0) {
          return `${duration} minute(s) ago`;
        } else {
          return `${now.diff(createdOnDate, 'seconds')} second(s) ago`;
        }
      }
    }
  };

  render() {
    const { notification, onDimissNotification } = this.props;

    return (
      <Route
        render={({ history }) => (
          <>
            <Row
              gutter={24}
              className='text-size-regular cursor-pointer'
              onClick={() => {
                onDimissNotification(notification.notificationId);
                NotificationUtils.Navigate(history, notification, false);
              }}
            >
              <Col>
                <Row gutter={12} type='flex' align='top'>
                  {/* <Col span={2} className="text-align-right">
                    <Avatar icon="user" className="" size="large" />
                  </Col> */}
                  <Col span={24}>
                    <Row className='mb-small' type='flex' align='middle'>
                      <Col span={19} className='text-weight-bold'>
                        {notification.title}
                      </Col>
                      <Col span={5} className='text-align-right'>
                        <SecondaryButton
                          icon='eye-invisible'
                          iconPosition='right'
                          size='small'
                          color='secondary'
                          onClick={(e) => {
                            e.stopPropagation();
                            onDimissNotification(notification.notificationId);
                          }}
                        >
                          Dismiss
                        </SecondaryButton>
                      </Col>
                    </Row>
                    <Row className='mb-medium'>
                      <Col>{notification.body}</Col>
                    </Row>
                    <Row type='flex' align='middle'>
                      <Col span={24} className='text-align-right'>
                        <Text size='regular' color='secondary'>
                          {this._getCreatedOnDisplayFormat(notification.createdOn)}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <div style={{ backgroundColor: 'red' }}> */}
            <Divider className='divider-medium' />
            {/* </div> */}
          </>
        )}
      />
    );
  }
}

class NotificationListingSection extends Component<any, any> {
  state = { page: 1, startDate: moment().toISOString() };

  private _dimissedNotification = async (notificationId) => {
    const { dashboardNotificationItems, doDimissNotification } = this.props;

    await doDimissNotification({
      notificationId,
    });
    //check number of dashboard notification items
    if (dashboardNotificationItems.length === 5) {
      // retrieve more notifications
      this.props.doFetchDashboardNotificationItems({
        page: this.state.page,
        startDate: this.state.startDate,
      });
    }
  };

  private _renderNotifications = (notifications: INotification[]) => {
    const topNotificationList = _.orderBy(notifications, 'createdOn', 'desc').slice(0, 4);
    return _.map(topNotificationList, (notification, index) => {
      return (
        <NotificationItemRow
          key={index}
          notification={notification}
          onDimissNotification={this._dimissedNotification}
        />
      );
    });
  };

  // React lifecycle
  componentDidMount = async () => {
    // load dashboard notification listing
    this.props.doFetchDashboardNotificationItems({
      page: this.state.page,
      startDate: this.state.startDate,
    });
  };

  render() {
    const { dashboardNotificationItems } = this.props;

    if (!dashboardNotificationItems || dashboardNotificationItems.length === 0) {
      return <Text>No notifications.</Text>;
    }

    return (
      <>
        <Divider className='divider-medium' />
        {this._renderNotifications(dashboardNotificationItems)}
        <div style={{ alignSelf: 'end', width: '100%', textAlign: 'end', paddingTop: '8px' }}>
          <Route
            render={({ history }) => (
              <HyperlinkButton
                onClick={() => {
                  history.push('/notifications');
                }}
              >
                View all notifications
              </HyperlinkButton>
            )}
          />
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  dashboardNotificationItems: state.notificationsStore.dashboardNotificationItems,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchDashboardNotificationItems: dispatch.notificationsStore.doFetchDashboardNotificationItems,
  doDimissNotification: dispatch.notificationsStore.doDimissNotification,
});

export default connect(mapState, mapDispatch)(NotificationListingSection);
